const EVENTS = {
    CREATE_ALERT: 'CREATE_ALERT',
    SHOW_EXCELRTD: 'SHOW_EXCELRTD',
    IMPORT_COMPLETE: 'IMPORT_COMPLETE',
    SHOW_IMPORTFILE: 'SHOW_IMPORTFILE',
    SHOW_WATCHLISTMANGER: 'SHOW_WATCHLISTMANGER',
    TOKEN_EXPIRED: 'TOKEN_EXPIRED',
    SHOW_CONTACT_SUPPORT: 'SHOW_CONTACT_SUPPORT',
    SHOW_ALERTMANAGER: 'SHOW_ALERTMANAGER',
    BEFORE_PRINT: 'BEFORE_PRINT'
};
class EventBus {
    constructor(Vue) {
        this.bus = new Vue();
    }
    $emit(eventName, ...param) {
        if (eventName in EVENTS) {
            this.bus.$emit(eventName, ...param);
        }
    }
    $on(eventName, handler) {
        if (eventName in EVENTS) {
            this.bus.$on(eventName, handler);
        }
    }
    $once(eventName, handler) {
        if (eventName in EVENTS) {
            this.bus.$once(eventName, handler);
        }
    }

    $off(eventName, handler) {
        this.bus.$off(eventName, handler);
    }
}
const eventBus = {};
eventBus.install = Vue => {
    Vue.prototype.$eventBus = new EventBus(Vue);
    Vue.prototype.$EVENTS = EVENTS;
    Vue.mixin({
        beforeDestroy() {
            this.$off();
            this.$eventBus.$off();
        }
    });
};

export default eventBus;
