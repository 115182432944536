class RequestManager {
    constructor() {
        this.isPending = false;
        this.queue = [];
        // [
        //     [
        //         {
        //             actionName: 'saveProfile',
        //             action: function() {},
        //             type: 'save'
        //             isPending: false
        //         },
        //         {
        //             actionName: 'saveWatchlist',
        //             action: function() {},
        //             isPending: false
        //         }
        //     ],
        //     [
        //         {
        //             actionName: 'deleteWatchlists',
        //             action: function() {}
        //         }

        //     ]
        // ];
        this.types = {
            save: 'save',
            delete: 'delete'
        };
    }
    add(action, actionName) {
        const actionType = this.getActionType(actionName);
        const _latest = this.queue[this.queue.length - 1];
        const _action = {
            actionName,
            action: action,
            type: actionType
        };
        if (this.latestActionType() === actionType && !_latest[0].isPending) {
            _latest.push(_action);
        } else {
            this.queue.push([_action]);
        }
        if (!this.isPending) {
            this.isPending = true;
            this.execute();
        }
    }
    latestActionType() {
        const len = this.queue.length;
        if (len) {
            return this.queue[len - 1][0].type;
        }
        return null;
    }
    getActionType(name) {
        if (name && name.indexOf('save') > -1) {
            return this.types.save;
        } else if (name && name.indexOf('delete') > -1) {
            return this.types.delete;
        }
        return null;
    }
    async execute() {
        for (let i = 0; i < this.queue.length; i++) {
            const actions = this.queue[i];
            if (
                this.getActionType(actions[0].actionName) === this.types.delete
            ) {
                const _actions = [];
                actions.forEach(item => {
                    if (!item.isPending) {
                        item.isPending = true;
                        _actions.push(item.action().catch(() => {}));
                    }
                });
                await Promise.all(_actions);
            } else if (
                this.getActionType(actions[0].actionName) === this.types.save
            ) {
                for (let j = 0; j < actions.length; j++) {
                    if (!actions[j].isPending) {
                        actions[j].isPending = true;
                        await actions[j]
                            .action(actions[j].params)
                            .catch(() => {});
                    }
                }
            }
            this.queue.splice(i, 1);
            i--;
        }
        if (this.queue.length) {
            this.execute();
        } else {
            this.isPending = false;
        }
    }
}

const requestManager = {};
requestManager.install = function(Vue, options) {
    Vue.prototype.$requestManager = new RequestManager(options);
};
export default requestManager;
