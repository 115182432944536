<template>
    <Dowjones v-if="activeDowjones" />
    <section
        v-else
        class="mds-layout-grid"
        :class="[namespaceClass('worksheet'), inEditModeStyle]"
        ref="worksheet"
    >
        <div
            class="mds-layout-grid__row"
            :class="namespaceClass('worksheet__row')"
        >
            <div
                class="mds-layout-grid__col mds-layout-grid__col--10"
                :class="[
                    activeEditMode || isEmptyWorksheet
                        ? 'mds-layout-grid__col--10'
                        : 'mds-layout-grid__col--12',
                    namespaceClass('worksheet__layout-wrapper')
                ]"
                ref="worksheetLayoutWrapper"
            >
                <h5
                    v-show="isEmptyWorksheet"
                    :class="namespaceClass('worksheet__add-component-prompt')"
                >
                    Drag and drop components to add to this worksheet.
                </h5>
            </div>
            <div
                v-show="activeEditMode || isEmptyWorksheet"
                class="mds-layout-grid__col mds-layout-grid__col--2"
                :class="namespaceClass('worksheet__edit-mode-wrapper')"
            >
                <ComponentLibrary />
            </div>
        </div>
        <markets-ui-dialog
            :cancel="dialog.cancel"
            :resolve="dialog.resolve"
            :visible="dialog.visible"
            :message="dialog.message"
            :skin="skin"
            action-required
            @click="clickDialog"
        >
            <form
                class="mds-form mds-form--medium-layout"
                onsubmit="return false;"
                slot="supplemental-content"
                v-if="isRename"
            >
                <div
                    class="mds-form__field-group"
                    v-for="item in dialog.inputs"
                    :key="item.id"
                >
                    <label class="mds-form__label" :for="item.id">{{
                        item.label
                    }}</label>
                    <input
                        class="mds-form__input"
                        type="text"
                        v-model="item.value"
                        @focus="onFocus"
                        ref="input"
                    />
                    <span
                        class="mds-form__field-error"
                        id="first-name-error"
                        role="alert"
                        v-if="!!dialog.errorMsg"
                    >
                        <span
                            class="mds-form__field-error-text"
                            :class="namespaceClass('error-message')"
                        >
                            {{ dialog.errorMsg }}
                        </span>
                    </span>
                </div>
            </form>
        </markets-ui-dialog>
        <markets-ui-dialog
            :title="warning.title"
            :resolve="warning.resolve"
            :visible="warning.visible"
            :message="warning.message"
            :skin="skin"
            action-required
            @click="clickWarn"
        >
        </markets-ui-dialog>
        <markets-ui-settings
            :triggered-by="popover.triggeredId"
            :width="popover.width"
            :visible="popover.visible"
            :skin="skin"
            :title="popover.title"
            @hide="hidePopover"
        >
            <markets-ui-list
                :skin="skin"
                :dataModel="popoverWatchList"
                @click="dropdownItem"
                size="medium"
                slot="content"
            >
            </markets-ui-list>
        </markets-ui-settings>
    </section>
</template>

<script>
import $ from 'jquery';
import 'golden-layout/src/css/goldenlayout-base.css';
import 'golden-layout/src/css/goldenlayout-light-theme.css';
import GoldenLayout from 'golden-layout';
import ComponentLibrary from './ComponentLibrary';
import { guid, every, sortBy, trim, getWorksheetSecurityId } from '../../util/';
import {
    generateName,
    validateWatchlistName,
    validateWatchlistsLength,
    WATCHLIST_TYPES,
    getEmptyWatchlistConfig
} from '../../common/';
import FUNCTIONS from '../../data/functions';
import {
    mapGetters as rootMapGetters,
    mapActions as rootMapActions,
    mapState as rootMapState,
    mapMutations as rootMapMutations,
    createNamespacedHelpers
} from 'vuex';
import BaseComponent from '../../componentsManager/base-component.js';
const {
    mapGetters,
    mapState,
    mapMutations,
    mapActions
} = createNamespacedHelpers('worksheet');

import utils from '../../util/';
import { getAdvancedChartEventsTrackingCallbacks } from '../../componentsManager/tracking';
import { Components } from '../../componentsManager/base-component';
import { importComponentByName } from '../../componentsManager/component-loader';
import Dowjones from './Dowjones';
export default {
    name: 'Worksheet',
    components: {
        ComponentLibrary,
        Dowjones
    },
    data() {
        return {
            dialog: {
                visible: false,
                errorMsg: '',
                message: '',
                inputs: [],
                reject: {},
                actionInfo: {},
                cancel: {
                    label: 'Cancel'
                },
                resolve: {
                    label: 'Save'
                }
            },
            warning: {
                title: 'Warning!',
                visible: false,
                message: '',
                resolve: {
                    label: 'OK'
                }
            },
            popover: {
                width: 200,
                triggeredId: null,
                visible: false,
                title: 'Edit Watchlist',
                list: [
                    {
                        id: 'rename',
                        name: 'Rename'
                    },
                    {
                        id: 'close',
                        name: 'Close'
                    },
                    {
                        id: 'delete',
                        name: 'Delete'
                    },
                    {
                        id: 'watchlist',
                        name: 'Watchlist',
                        children: []
                    }
                ]
            }
        };
    },
    computed: {
        ...mapGetters([
            'activeWorksheet',
            'activeLayoutConfig',
            'isEmptyWorksheet',
            'inactiveWorksheets',
            'findRelatedWatchlists',
            'activeWorksheetComponents',
            'activeWorksheetSecurity',
            'componentDragable',
            'activeEditMode'
        ]),
        ...mapState([
            'worksheets',
            'activeWorksheetId',
            'availableComponents',
            'goldenLayout',
            'lastActiveWorksheetId',
            'worksheetSecurity',
            'fromToViewStatus',
            'dragEventComponentStatus',
            'activeDowjones'
        ]),
        ...rootMapGetters('account', ['token']),
        ...rootMapGetters('settings', ['skin', 'isFeatureEnable']),
        ...rootMapState('settings', ['theme', 'dataType']),
        ...rootMapState('application', [
            'paths',
            'globalConfigLoaded',
            'frequency'
        ]),
        ...rootMapState('watchlist', ['watchlists']),
        isRename() {
            return this.dialog.actionInfo.action === 'rename';
        },
        isDelete() {
            return this.dialog.actionInfo.action === 'delete';
        },
        isClose() {
            return this.dialog.actionInfo.action === 'close';
        },
        /**
         *  This only work for worksheet in the edit mode.
         *  We want to hide lm_maximise icon when worksheet in the edit mode.
         *  You can find more details in https://msjira.morningstar.com/browse/RTCOMP-14521.
         */
        inEditModeStyle() {
            return this.activeEditMode === true
                ? 'mc-app-worksheet--in-edit-mode'
                : null;
        },
        initWatchList() {
            const initList = utils.extend(true, [], this.watchlists);
            const readonlyList = [];
            const defaultList = [];
            initList.forEach(item => {
                item.click = true;
                item.type = 'watchlist';

                if (item.id.indexOf('readonly') > -1) {
                    readonlyList.push(item);
                } else {
                    defaultList.push(item);
                }
            });
            defaultList.sort(sortBy('name', true));
            return readonlyList.concat(defaultList);
        },
        popoverWatchList() {
            const list = [];
            if (this.popover.visible) {
                const configSetting = this.instanceMap.get(
                    this.dropContentItem.componentId
                ).instance.config.settings;
                this.popover.list.forEach(item => {
                    item.click = true;
                    item.disabled = false;
                    if (item.id === 'watchlist') {
                        item.click = false;
                        item.children = this.initWatchList;
                    }
                    if (
                        configSetting.holdingsId &&
                        configSetting.holdingsId.indexOf('readonly') >= 0
                    ) {
                        if (item.id === 'rename' || item.id === 'delete') {
                            item.click = 'disabled';
                            item.disabled = true;
                        }
                    }
                    list.push(item);
                });
            }
            return list;
        }
    },
    watch: {
        watchlists: {
            handler(data) {
                this.$watchlistManager.setWatchlistData(data);
            },
            deep: true
        },
        activeEditMode() {
            this.$nextTick(() => {
                if (this.goldenLayout) {
                    this.goldenLayout.updateSize();
                }
            });
        },
        worksheetSecurity(value = {}) {
            if (value.id && this.goldenLayout) {
                this._setSecurityToOthers(
                    Array.from(this.instanceMap.values()),
                    value
                );
            }
        },
        isEmptyWorksheet() {
            this.$nextTick(() => {
                if (this.goldenLayout) {
                    this.goldenLayout.updateSize();
                }
            });
        },
        lastActiveWorksheetId(value) {
            if (
                this.activeWorksheet &&
                (value === this.activeWorksheet.id ||
                    value === this.activeWorksheet.originalId)
            ) {
                return;
            }
            if (this.goldenLayout) {
                this.goldenLayout.off('stateChanged');
                this.goldenLayout.destroy();
            }
            this.setGoldenLayout(null);
        },
        activeWorksheetId(value) {
            // -1 means active dowjones so don't need to create goldenlayout
            if (
                value &&
                value !== '-1' &&
                !this.goldenLayout &&
                this.availableComponents.length
            ) {
                this.$nextTick(() => {
                    this.globalConfigLoadedPromise.then(() => {
                        this.createGoldenLayout(
                            $(this.$refs.worksheetLayoutWrapper),
                            this.activeLayoutConfig,
                            this.availableComponents
                        );
                    });
                });
            }
        },
        'goldenLayout._maximisedItem': function(value, oldValue) {
            let $targetElement, componentId;
            const userAgent = navigator.userAgent;
            if (value) {
                value.header.controlsContainer
                    .find('.lm_maximise')
                    .html(this.getMDSIconHtml('collapse'));
                $targetElement = value.contentItems[0].element;
                componentId = $targetElement.attr('data-item-id');
                if (
                    /Edge/gi.test(userAgent) ||
                    /(msie\s|Trident.*rv:)([\w.]+)/g.test(userAgent)
                ) {
                    value.element.siblings().hide();
                }
            }
            if (oldValue) {
                oldValue.header.controlsContainer
                    .find('.lm_maximise')
                    .html(this.getMDSIconHtml('expand--s'));
                $targetElement = oldValue.contentItems[0].element;
                componentId = $targetElement.attr('data-item-id');
                if (
                    /Edge/gi.test(userAgent) ||
                    /(msie\s|Trident.*rv:)([\w.]+)/g.test(userAgent)
                ) {
                    oldValue.element
                        .parents('.lm_root')
                        .children()
                        .show();
                }
            }
            if (componentId) {
                const instanceMap = this.instanceMap.get(componentId);
                if (instanceMap && instanceMap.instance) {
                    instanceMap.instance.setProperty(
                        'targetElement',
                        $targetElement[0]
                    );
                }
            }
        },
        theme(value) {
            this.instanceMap.forEach(item => {
                const instance = item.instance;
                if (instance && typeof instance.setProperty === 'function') {
                    instance.setProperty('theme', value);
                }
            });
        },
        dataType(value) {
            this.instanceMap.forEach(item => {
                const instance = item.instance;
                if (instance && typeof instance.setProperty === 'function') {
                    instance.setProperty('dataType', value);
                }
            });
        },
        activeLayoutConfig: {
            handler(value, oldValue) {
                if (
                    oldValue &&
                    oldValue.length &&
                    this.activeWorksheetId &&
                    JSON.stringify(value) !== JSON.stringify(oldValue)
                ) {
                    this.setWorksheetDirty({
                        worksheetId: this.activeWorksheetId,
                        status: true
                    });
                }
            },
            deep: true
        },
        'dialog.visible'() {
            this.dialog.errorMsg = '';
        },
        'warning.visible'() {
            this.warning.errorMsg = '';
        }
    },
    created() {
        this.instanceMap = new Map();
        this.$watchlistManager.on(
            'watchlistsNameChanged',
            this.changeWatchlistName
        );
        this.$watchlistManager.on(
            'watchlistsTickersChanged',
            this.changeWatchlistTickers
        );
        this.$watchlistManager.on(
            'watchlistsDeleted',
            this.changeDeletedWatchlists
        );
        this.globalConfigLoadedWatch = this.$watch('globalConfigLoaded', () => {
            if (this.globalConfigLoaded) {
                this.globalConfigPromiseResolver();
            }
            this.globalConfigLoadedWatch();
        });
        this.globalConfigLoadedPromise = new Promise((resolve, reject) => {
            this.globalConfigPromiseResolver = resolve;
        });
        this.$watchlistManager.on('openWatchlists', this._openWatchlists);
        this._dragComponentId = null; //save drag from library componentId
    },
    mounted() {
        this.$worksheetContainer = $(this.$refs.worksheet);
        $(window).on('resize', () => {
            if (this.goldenLayout) {
                this.goldenLayout.updateSize();
            }
        });
        const $doc = $(document);
        $doc.unbind('click.headerIcon');
        $doc.bind('click.headerIcon', this._handleClickEvent);
    },
    beforeDestroy() {
        this.setGoldenLayout(null);
    },
    methods: {
        ...mapMutations([
            'setGoldenLayout',
            'openWatchlists',
            'setActiveWorksheetId',
            'setWorksheetViewSecurity',
            'setLayoutConfig',
            'setWorksheetDirty',
            'setActiveWorksheetSecurity',
            'toggleEditMode',
            'setFromToViewStatus',
            'setDragEventComponentStatus'
        ]),
        ...rootMapMutations('application', ['setNotification']),
        ...mapActions(['storeComponentSettings']),
        ...rootMapActions('application', ['saveProfile']),
        ...rootMapActions('watchlist', ['updateWatchlist']),
        ...rootMapActions('account', ['signout']),
        ...rootMapActions('watchlist', ['deleteWatchlists', 'updateWatchlist']),
        ...rootMapMutations('workbook', ['setDirty']),
        ...rootMapMutations('settings', ['setSettings']),
        createGoldenLayout(container, layoutConfig, availableComponents) {
            this.instanceMap = new Map();
            this.activeComponents = new Map();
            const goldenLayout = new GoldenLayout(
                {
                    dimensions: {
                        headerHeight: 28
                    },
                    settings: {
                        reorderOnTabMenuClick: false,
                        reorderEnabled: this.isGoldenLayoutFeatureEnable(
                            'reorderEnabled'
                        ),
                        showCloseIcon: this.isGoldenLayoutFeatureEnable(
                            'showCloseIcon'
                        )
                    },
                    labels: {
                        maximise: 'Maximise',
                        minimise: 'Minimise',
                        close: 'Close',
                        popout: 'Open In New Window',
                        popin: 'Pop In',
                        tabDropdown: 'Additional Tabs'
                    },
                    content: layoutConfig
                },
                container
            );
            this.initialiseComponent = null;
            this.isFreshLayout = layoutConfig.length > 0;
            this.setGoldenLayout(goldenLayout);
            BaseComponent.setToken(this.token);
            BaseComponent.setPaths(this.paths);
            BaseComponent.setFrequency(this.frequency);
            // because register component callback only triggered with layout existing components length.
            let __registerCount__ = 0;
            availableComponents.map(component => {
                goldenLayout.registerComponent(
                    component.componentName,
                    (_container, state) => {
                        this._registerComponent(
                            _container,
                            state,
                            component
                        ).then(() => {
                            __registerCount__++;
                            if (
                                __registerCount__ ===
                                this.activeWorksheetComponents.length
                            ) {
                                this.initialiseComponent = true;
                                if (this.fromToViewStatus) {
                                    const securityId = this
                                        .activeWorksheetSecurity.id;
                                    this.setWorksheetViewSecurity(securityId);
                                    this.setFromToViewStatus(false);
                                }
                            }
                        });
                    }
                );
            });
            // because register component callback only triggered with layout existing components length.
            goldenLayout.on('stackCreated', stack => {
                // add setting icon
                const $popupElement = stack.header.controlsContainer.find(
                    '.lm_popout'
                );
                $popupElement.before(
                    `<li class="lm_gear" title="Component Settings">${this.getMDSIconHtml(
                        'gear--s'
                    )}</li>`
                );
                //add search autocompelete
                const $gearElement = stack.header.controlsContainer.find(
                    '.lm_gear'
                );
                $gearElement.after(
                    `<li class="lm_search mc-app-search-icon" title="Security Lookup">${this.getMDSIconHtml(
                        'search-padless',
                        this.namespaceClass('search-icon')
                    )}</li>`
                );
                $gearElement.after(
                    `<li class="lm_export mc-app-export-icon" title="Export Excel">${this.getMDSIconHtml(
                        'share'
                    )}</li>`
                );
                $gearElement.after(
                    `<li class="lm_import" title="Import Excel">${this.getMDSIconHtml(
                        'import',
                        this.namespaceClass('import-icon')
                    )}</li>`
                );
                $gearElement.after(
                    `<li class="lm_print mc-app-print-icon" title="Print PDF">${this.getMDSIconHtml(
                        'print',
                        this.namespaceClass('print-icon')
                    )}</li>`
                );
                // change icon to use MDS
                $popupElement.html(this.getMDSIconHtml('open-new--s'));
                stack.header.controlsContainer
                    .find('.lm_maximise')
                    .html(this.getMDSIconHtml('expand--s'));
                stack.header.controlsContainer
                    .find('.lm_close')
                    .html(this.getMDSIconHtml('remove--s'));
                if (stack.contentItems.length > 2) {
                    const $lmtab = stack.element.find('.lm_header');
                    $lmtab.addClass('mult-tabs');
                }
            });
            goldenLayout.on('itemDestroyed', contentItem => {
                contentItem.emitBubblingEvent('stateChanged');
            });
            // drag new component from library callback
            goldenLayout.on('componentCreated', contentItem => {
                if (this.initialiseComponent) {
                    this.setDragEventComponentStatus(true);
                }
            });
            goldenLayout.on('activeContentItemChanged', contentItem => {
                const componentId = $(contentItem.element).attr('data-item-id');
                this.adjustHeaderStyle(contentItem, componentId);
                contentItem.element.click(); // trigger a click event to hide popovers;
            });
            goldenLayout.on('itemCreated', contentItem => {
                if (!contentItem.isComponent) {
                    return;
                }
                if (this.isEmptyWorksheet) {
                    this.initialiseComponent = true;
                    if (!this.activeEditMode) {
                        this.toggleEditMode();
                    }
                }
                const componentId = $(contentItem.element).data('item-id');
                const state = contentItem.config.componentState || {};
                state.linkId = state.linkId || componentId;
                if (!componentId) {
                    return;
                }
                const instance = this.instanceMap.get(componentId);
                let holdingsId = state.holdingsId;
                if (
                    !holdingsId &&
                    contentItem.config.watchlistType ===
                        WATCHLIST_TYPES.READ_ONLY
                ) {
                    const baseConfig = utils.find(
                        this.availableComponents,
                        com => {
                            return (
                                com.componentName === contentItem.componentName
                            );
                        }
                    );
                    holdingsId = state.holdingsId =
                        baseConfig.componentState.holdingsId;
                }
                contentItem.config.componentState = state;
                if (holdingsId) {
                    const componentWatchlist = utils.find(
                        this.watchlists,
                        item => {
                            return (
                                item.id === holdingsId ||
                                item.originalId === holdingsId
                            );
                        }
                    );
                    if (componentWatchlist) {
                        contentItem.config.title = componentWatchlist.name;
                        contentItem.setTitle(componentWatchlist.name);
                    }
                }
                instance.contentItem = contentItem;
                instance.showSymbol = contentItem.config.showSymbol;

                if (
                    contentItem.config.watchlistType ===
                        WATCHLIST_TYPES.CUSTOM &&
                    !holdingsId
                ) {
                    this._createNewWatchlist(instance);
                }
            });
            goldenLayout.on(
                'stateChanged',
                utils.debounce(
                    () => {
                        if (
                            !(
                                this.goldenLayout.isInitialised &&
                                every(this.goldenLayout.openPopouts, popout => {
                                    return popout.isInitialised;
                                })
                            )
                        ) {
                            return;
                        }
                        if (!this.isFreshLayout) {
                            this._changeLayoutConfig();
                            if (
                                this.dragEventComponentStatus &&
                                this.activeWorksheetSecurity &&
                                this._dragComponentId
                            ) {
                                const instanceMap = this.instanceMap.get(
                                    this._dragComponentId
                                );
                                let settings = {};
                                if (
                                    instanceMap &&
                                    instanceMap.instance &&
                                    instanceMap.instance.componentType ===
                                        'QUIKR'
                                ) {
                                    settings.mainTicker = this.activeWorksheetSecurity.id;
                                } else {
                                    settings.symbol = this.activeWorksheetSecurity.id;
                                }
                                this.storeComponentSettings({
                                    linkId: this._dragComponentId,
                                    settings
                                });
                                this.setDragEventComponentStatus(false);
                                this._dragComponentId = null;
                            }
                        }
                        this.isFreshLayout = false;
                    },
                    200,
                    {
                        leading: true
                    }
                )
            );
            if (window.document.documentMode === 11) {
                // QS-13699 only for IE11
                goldenLayout.on('tabCreated', tab => {
                    if (tab._dragListener) {
                        tab._dragListener._nDelay = 1000;
                        tab._dragListener._nDistance = Number.MAX_SAFE_INTEGER;
                    }
                });
            }
            goldenLayout.on('itemDropped', contentItem => {
                const $targetElement = contentItem.container.getElement();
                const componentId = $targetElement
                    .parent()
                    .attr('data-item-id');
                const instanceMap = this.instanceMap.get(componentId);
                if (instanceMap && instanceMap.instance) {
                    instanceMap.instance.setProperty(
                        'targetElement',
                        $targetElement[0]
                    );
                }
                // if drag from library need save componentId and have activeWorksheetSecurity
                if (
                    this.activeWorksheetSecurity &&
                    contentItem.config.showSymbol &&
                    this.initialiseComponent
                ) {
                    this._dragComponentId = componentId;
                } else {
                    this.setDragEventComponentStatus(false);
                }

                // this._bindCloseEvent(contentItem, componentId);
            });
            goldenLayout.init();
        },
        _registerComponent(container, state, component) {
            const _component = utils.extend(true, {}, component);
            if (
                _component.watchlistType === WATCHLIST_TYPES.CUSTOM &&
                !state.holdingsId
            ) {
                _component.componentState.holdingsId = `default-${utils.guid()}`;
            }
            if (!state.linkId) {
                state.linkId = `${component.componentName}_${guid()}`;
            }
            const dataId = state.linkId;
            $(container._element).attr(
                'data-item-worksheetId',
                this.activeWorksheetId
            );
            $(container._element).attr('data-item-id', dataId);
            this.instanceMap.set(dataId, {});
            _component.componentState = this._getComponentState(
                _component,
                state
            );
            if (this.fromToViewStatus && this.activeWorksheetSecurity) {
                this.instanceMap.get(
                    dataId
                ).security = this.activeWorksheetSecurity.id;
            }
            if (
                _component.watchlistType === WATCHLIST_TYPES.READ_ONLY ||
                _component.watchlistType === WATCHLIST_TYPES.CUSTOM
            ) {
                this.$watchlistManager.bindHoldingsWithComponents(
                    _component.componentState.holdingsId,
                    dataId
                );
            }
            return importComponentByName(_component.componentType).then(() => {
                const item = this.instanceMap.get(dataId);
                if (!item) {
                    return;
                }
                if (
                    this.dragEventComponentStatus &&
                    this.activeWorksheetSecurity
                ) {
                    item.security = this.activeWorksheetSecurity.id;
                }
                const componentSymbol =
                    item.security ||
                    _component.componentState.mainTicker ||
                    _component.componentState.symbol;
                const normalCallback = {
                    'token-expired': () => {
                        this.$eventBus.$emit(this.$EVENTS.TOKEN_EXPIRED);
                        window.onbeforeunload = undefined;
                        this.signout();
                    },
                    'settings-changed': utils.debounce((linkId, settings) => {
                        this.storeComponentSettings({
                            linkId,
                            settings
                        });
                        this.setWorksheetDirty({
                            worksheetId: this.activeWorksheetId,
                            status: true
                        });
                    }, 200),
                    'row-double-click': (componentId, param) => {
                        this.acceptSecurity(param);
                    },
                    'hover-menu-click': (componentId, param) => {
                        this.goToView(param);
                    },
                    'export-rtd-click': () => {
                        this.$eventBus.$emit(this.$EVENTS.SHOW_EXCELRTD);
                    },
                    'ticker-accepted': (componentId, securityInfo) => {
                        const instance = this.instanceMap.get(componentId);
                        if (instance && instance.contentItem) {
                            const contentItem = instance.contentItem;
                            const componentName = contentItem.config.title;
                            //get component title.
                            const title = componentName.split(':')[0];
                            contentItem.setTitle(
                                `${title}: ${securityInfo.mstarSymbol}`
                            );
                        }
                    },
                    'before-print': (componentId, isPrinting) => {
                        this.$eventBus.$emit(
                            this.$EVENTS.BEFORE_PRINT,
                            isPrinting
                        );
                    },
                    'layout-change': (componentId, item) => {
                        const instance = this.instanceMap.get(componentId);
                        if (instance && instance.contentItem) {
                            const contentItem = instance.contentItem;
                            if (item === 'overview') {
                                contentItem.config.showExportIcon = false;
                                contentItem.config.showSettingIcon = false;
                            } else {
                                contentItem.config.showExportIcon = true;
                                contentItem.config.showSettingIcon = true;
                            }
                            if (this.activeComponents.get(componentId)) {
                                this.adjustHeaderStyle(
                                    contentItem,
                                    componentId
                                );
                            }
                        }
                    },
                    track: (componentId, data) => {
                        this.$appTracker.trackEvent(data);
                    },
                    destroyed: ({ worksheetId, componentId }) => {
                        if (this.activeWorksheetId === worksheetId) {
                            this.instanceMap.delete(componentId);
                        }
                    }
                };
                let callbacks = normalCallback;
                if (
                    component &&
                    component.componentName === Components.AdvancedChart
                ) {
                    const advancedChartCallbacks = getAdvancedChartEventsTrackingCallbacks(
                        this.$appTracker
                    );
                    callbacks = utils.extend(
                        {},
                        normalCallback,
                        advancedChartCallbacks
                    );
                }
                item.instance = new BaseComponent(
                    this.activeWorksheetId,
                    _component,
                    container,
                    this.theme,
                    componentSymbol,
                    this.dataType,
                    this.activeComponents.get(dataId),
                    callbacks
                );
            });
        },
        _changeLayoutConfig() {
            this.setLayoutConfig({
                layoutConfig: this.goldenLayout.toConfig().content,
                resetComponentSettings: false
            });
        },
        _openWatchlists(watchlists) {
            const result = this.findRelatedWatchlists(
                [this.activeWorksheet],
                watchlists
            );
            if (utils.isEmptyObject(result)) {
                this.openWatchlists(watchlists);
                return;
            }
            let componentsConfig = result.targetWatchlist
                ? result.targetWatchlist.componentsConfig
                : result.firstWatchlist.componentsConfig;
            const updateItems = [];
            watchlists.reverse().forEach(w => {
                componentsConfig.forEach(c => {
                    if (c.holdingsId === w.id) {
                        updateItems.push({
                            action: 'active',
                            linkId: c.linkId,
                            watchlist: w
                        });
                    }
                });
                if (
                    !utils.find(componentsConfig, c => {
                        return c.holdingsId === w.id;
                    })
                ) {
                    const first = result.targetWatchlist
                        ? result.targetWatchlist
                        : result.firstWatchlist;
                    updateItems.push({
                        action: 'add',
                        watchlist: w,
                        linkId: first.linkId
                    });
                }
            });
            updateItems.forEach(item => {
                if (this.instanceMap.get(item.linkId)) {
                    const contentItem = this.instanceMap.get(item.linkId)
                        .contentItem;
                    const parent = contentItem && contentItem.parent;
                    if (parent) {
                        if (item.action === 'add') {
                            parent.addChild(
                                this._generateWatchlistConfig(item.watchlist),
                                0
                            );
                        } else if (item.action === 'active') {
                            parent.setActiveContentItem(contentItem);
                        }
                    }
                }
            });
        },
        _setSecurityToWatchlists(
            wlInstanceItems = [],
            { id, mstarSymbol } = {}
        ) {
            wlInstanceItems.forEach(item => {
                const instance = item.instance;
                const overflowMaximumCount = this._isOverflowWatchlistSymbolMaximum(
                    instance
                );
                if (instance && !overflowMaximumCount) {
                    instance.setProperty('symbol', {
                        symbol: id,
                        action: 'add'
                    });
                    this.acceptSecurity({
                        instrument: id,
                        mstarSymbol: mstarSymbol
                    });
                }
            });
        },
        /**
         * Judgement the watchlist symbol count whether more than 500.
         * @param watchlist - The watchlist instance.
         * @return {boolean} - Whether more than 500.
         */
        _isOverflowWatchlistSymbolMaximum(watchlist) {
            // TODO The symbol length used in many places, maybe we can calculate it internal.
            if (watchlist.symbol.split(',').length >= 500) {
                this._openWatchlistSymbolMaximumDialog();
                return true;
            }
            return false;
        },
        _setSecurityToOthers(instanceItems = [], { id, mstarSymbol } = {}) {
            this.setActiveWorksheetSecurity({ id, mstarSymbol });
            instanceItems.forEach(item => {
                if (item.showSymbol) {
                    const instance = item.instance;
                    if (instance) {
                        if (typeof instance.setProperty === 'function') {
                            instance.setProperty('symbol', { symbol: id });
                        }
                    } else {
                        item.security = id;
                    }
                }
            });
        },
        _getComponentState(component, state) {
            const holdingsId = state.holdingsId;
            let componentState = component.componentState;
            if (holdingsId) {
                if (holdingsId.indexOf('readonly') > -1) {
                    componentState.removable = false;
                    componentState.gridHover.removable = false;
                } else {
                    componentState.removable = true;
                    componentState.gridHover.removable = true;
                }
            }
            componentState = utils.extend(true, componentState, state);
            if (component.componentType === Components.Watchlist) {
                componentState.showExcelRTD = this.isFeatureEnable(
                    FUNCTIONS.EXCEL_RTD
                );
            }
            if (
                (component.componentType === Components.Watchlist ||
                    component.componentType === Components.GLA ||
                    component.componentType === Components.Portfolio) &&
                componentState.gridHover
            ) {
                componentState.gridHover.menu = this.inactiveWorksheets.map(
                    ws => {
                        return {
                            id: ws.id,
                            name: ws.name,
                            click: true
                        };
                    }
                );
                componentState.gridHover.menu.push({
                    id: 'alert',
                    name: 'Create Alert',
                    click: true
                });
            }
            if (
                component.watchlistType === 'readonly' ||
                component.watchlistType === 'custom'
            ) {
                const targetWatchlist = utils.find(this.watchlists, w => {
                    return (
                        w.id === componentState.holdingsId ||
                        w.originalId === componentState.holdingsId
                    );
                });
                if (targetWatchlist) {
                    componentState.symbol = targetWatchlist.tickers;
                }
            }
            return componentState;
        },
        _initAutocomplete(id) {
            const Autocomplete = document.querySelector(
                Components.Autocomplete
            );
            Autocomplete.register(id, res => {
                const item = this.instanceMap.get(id);
                if (item) {
                    const instance = item.instance || {};
                    const arg = {
                        id: getWorksheetSecurityId(res),
                        mstarSymbol: res.symbol
                    };
                    if (instance.componentName === Components.Watchlist) {
                        this._setSecurityToWatchlists([item], arg);
                    } else {
                        this._setSecurityToOthers([item], arg);
                    }
                    $(document)
                        .find(
                            '.' +
                                this.namespaceClass(
                                    'autocomplete-input__active'
                                )
                        )
                        .removeClass(
                            this.namespaceClass('autocomplete-input__active')
                        )
                        .val('');
                }
            });
        },
        _handleClickEvent(event) {
            const $doc = $(document);
            const $target = $(event.target);
            if (
                !$target.hasClass(
                    this.namespaceClass('autocomplete-input__active')
                )
            ) {
                $doc.find(
                    '.' + this.namespaceClass('autocomplete-input__active')
                )
                    .removeClass(
                        this.namespaceClass('autocomplete-input__active')
                    )
                    .val('');
            }
            const $componentInput = $target
                .closest('.lm_header')
                .find('.' + this.namespaceClass('autocomplete_input'));
            if (!$componentInput.length) {
                return;
            }
            const componentId = $componentInput[0].id;
            if (
                $target.hasClass(this.namespaceClass('import-icon')) ||
                $target.parent().hasClass(this.namespaceClass('import-icon'))
            ) {
                const instanceItem = this.instanceMap.get(componentId);
                const holdingsId =
                    instanceItem.contentItem.config.componentState.holdingsId;
                this.$eventBus.$emit(this.$EVENTS.SHOW_IMPORTFILE, holdingsId);
            }
            if (
                $target.hasClass(this.namespaceClass('search-icon')) ||
                $target.parent().hasClass(this.namespaceClass('search-icon'))
            ) {
                $componentInput.addClass(
                    this.namespaceClass('autocomplete-input__active')
                );
                this._initAutocomplete(componentId);
            }
        },
        _dropdownEvent(e, contentItem, componentId) {
            this.popover.visible = !this.popover.visible;
            this.popover.triggeredId = utils.computeTriggerId(e.currentTarget);
            if (this.popover.visible) {
                this.dropContentItem = {
                    contentItem,
                    componentId
                };
            } else {
                this.dropContentItem = null;
            }
        },
        _addWatchlistEvent(e, stack) {
            const message = validateWatchlistsLength(this.watchlists);
            if (message) {
                this.warning.visible = true;
                this.warning.message = message;
                return;
            }
            stack.addChild(this._generateWatchlistConfig());
        },
        _generateWatchlistConfig(watchlist) {
            const config = getEmptyWatchlistConfig();
            utils.extend(
                true,
                config,
                utils.find(
                    this.availableComponents,
                    com => com.componentName === Components.Watchlist
                )
            );
            if (watchlist) {
                config.componentState.holdingsId = watchlist.id;
                config.componentSettings.holdingsId = watchlist.id;
                config.title = watchlist.name;
            } else {
                config.title = generateName('Watchlist', this.watchlists);
            }
            return config;
        },
        _createNewWatchlist(instance) {
            const instanceContent = instance.contentItem;
            const linkId = instanceContent.config.componentState.linkId;
            const name = generateName('Watchlist', this.watchlists);
            let holdingsId = null;
            Object.keys(this.$watchlistManager.componentsMap).forEach(key => {
                const hasComponentsIdx = this.$watchlistManager.componentsMap[
                    key
                ].indexOf(linkId);
                if (hasComponentsIdx > -1) {
                    holdingsId = key;
                }
            });

            if (holdingsId) {
                const config = utils.extend(true, {}, instanceContent.config, {
                    title: name,
                    componentState: { holdingsId },
                    componentSettings: { holdingsId }
                });
                instance.contentItem.config = config;
                this.updateWatchlist({
                    id: holdingsId,
                    name: name,
                    tickers: ''
                });
            }
        },
        changeWatchlistSymbol(item) {
            const holdingsId = item.id;
            const linkId = this.dropContentItem.componentId;
            const instanceMap = this.instanceMap.get(linkId);
            const hasHoldingsIdComponents = this.$watchlistManager
                .componentsMap[holdingsId];
            const stack = instanceMap.contentItem.parent;
            if (hasHoldingsIdComponents && hasHoldingsIdComponents.length) {
                const target = utils.find(stack.contentItems, cItem => {
                    if (
                        hasHoldingsIdComponents.indexOf(
                            cItem.config.componentState.linkId
                        ) > -1
                    ) {
                        return cItem;
                    }
                });
                if (target) {
                    stack.setActiveContentItem(target);
                    return;
                }
            }

            instanceMap.instance.setProperty('symbol', {
                symbol: item.tickers || ''
            });
            instanceMap.contentItem.setTitle(item.name);
            if (holdingsId.indexOf('readonly') > -1) {
                instanceMap.instance.setProperty('removable', false);
                instanceMap.contentItem.config.watchlistType =
                    WATCHLIST_TYPES.READ_ONLY;
            } else {
                instanceMap.instance.setProperty('removable', true);
                instanceMap.contentItem.config.watchlistType =
                    WATCHLIST_TYPES.CUSTOM;
            }
            instanceMap.instance.config.settings.holdingsId = holdingsId;
            instanceMap.contentItem.config.componentState.holdingsId = holdingsId;
            stack.setActiveContentItem(instanceMap.contentItem);
            this.storeComponentSettings({
                linkId,
                settings: {
                    holdingsId: holdingsId,
                    symbol: item.tickers
                }
            });
            this.$watchlistManager.bindHoldingsWithComponents(
                holdingsId,
                linkId
            );
            this._changeLayoutConfig();
        },
        hidePopover() {
            this.popover.visible = false;
        },
        onFocus(e) {
            e.target.select();
        },
        dropdownItem(item) {
            this.popover.visible = false;
            if (item.id === 'close') {
                this._handleWatchlist('close');

                return;
            }
            if (item.type === 'watchlist') {
                this.changeWatchlistSymbol(item);
                return;
            }
            this.dialog.visible = true;
            const target = {
                name: this.dropContentItem.contentItem.config.title,
                id: this.dropContentItem.componentId
            };
            this.dialog.actionInfo = {
                action: item.id,
                target: target
            };
            if (item.id === 'rename') {
                this.dialog.inputs = [
                    {
                        id: 'rename',
                        label: 'Watchlist Name',
                        value: target.name
                    }
                ];
                this.dialog.resolve = {
                    label: 'Save'
                };
                this.dialog.message = 'Please enter a name for the watchlist';
                this.$nextTick(() => {
                    this.$refs['input'][0].focus();
                });
            } else if (item.id === 'delete') {
                this.dialog.message = `Do you want to delete "${target.name}" in your saved watchlist?`;
                this.dialog.resolve = {
                    label: 'Delete'
                };
            }
        },
        /**
         * This warning dialog for watchlist symbol count overflow.
         */
        _openWatchlistSymbolMaximumDialog() {
            this.warning.visible = true;
            // TODO This warning message used in many places, maybe we can define a const for it.
            this.warning.message = `Sorry, you've reached the maximum 500 securities limit.
            You need to delete securities from your watchlist before adding a new one.`;
        },
        clickWarn(type) {
            if (type === 'resolve') {
                this.warning.visible = false;
            }
        },
        clickDialog(type) {
            if (type === 'resolve') {
                if (this.isDelete) {
                    this._handleWatchlist('delete');
                } else if (this.isRename) {
                    const name = trim(
                        utils.find(this.dialog.inputs, item => {
                            return item.id === 'rename';
                        }).value
                    );
                    const errorMsg = validateWatchlistName(
                        name,
                        this.watchlists
                    );
                    this.dialog.errorMsg = errorMsg;
                    if (!errorMsg) {
                        this._handleWatchlist('rename');
                    }
                } else if (this.isClose) {
                    this._closeComponent(
                        this.dialog.actionInfo.target.targetEl,
                        this.dialog.actionInfo.target.id
                    );
                    this.dialog.visible = false;
                }
            } else if (type === 'cancel') {
                this.dialog.visible = false;
            }
        },
        _handleWatchlist(item) {
            const linkId = this.dropContentItem.componentId;
            const instance = this.instanceMap.get(linkId);
            const holdingsId = instance.instance.config.settings.holdingsId;
            const target = utils.find(this.watchlists, w => {
                return w.id === holdingsId || w.originalId === holdingsId;
            });

            if (item === 'rename' && target) {
                this.updateWatchlist({
                    id: target.id,
                    name: this.dialog.inputs[0].value,
                    tickers: target.tickers
                });
                this.dialog.visible = false;
            } else if (item === 'delete') {
                this.dropContentItem.contentItem.parent.removeChild(
                    instance.contentItem
                );
                this.$requestManager.add(
                    () =>
                        this.deleteWatchlists([
                            (target && target.id) || holdingsId
                        ]),
                    'deleteWatchlists'
                );
            } else if (item === 'close') {
                this.dropContentItem.contentItem.parent.removeChild(
                    instance.contentItem
                );
            }
            this.dialog.visible = false;
        },
        _closeEvent(event) {
            if (event.currentTarget.tagName !== 'BUTTON') {
                return;
            }
            const $target = $(event.currentTarget);
            let id = '';
            if ($target.parents('.lm_close').length) {
                const $closeItemContent = $target
                    .closest('.lm_item')
                    .find('.lm_items .lm_item_container');
                if ($closeItemContent && $closeItemContent.length > 1) {
                    $closeItemContent.each((item, ele) => {
                        id += ele.getAttribute('data-item-id') + ',';
                    });
                } else {
                    id = $closeItemContent.data('item-id');
                }
            } else if ($target.parents('.lm_close_tab').length) {
                id = $target.parents('.lm_close_tab').attr('data-item-id');
            }
            const linkId = id.split(',');
            const target = {
                id,
                targetEl: $target
            };
            if (linkId.length > 1) {
                this.dialog.actionInfo = {
                    action: 'close',
                    target: target
                };
                this.dialog.visible = true;
                this.dialog.message =
                    'You have multiple components opened, do you want to close them all?';
                this.dialog.resolve = {
                    label: 'Close'
                };
            } else {
                this._closeComponent($target, id);
            }
            event.stopPropagation();
        },
        _closeComponent($target, id) {
            $target.parent().click();
        },
        acceptSecurity(security) {
            this._setSecurityToOthers(Array.from(this.instanceMap.values()), {
                id: getWorksheetSecurityId(security),
                mstarSymbol: security.mstarSymbol
            });
        },
        goToView(param = {}) {
            const { security, view } = param;
            if (view.id === 'alert') {
                this.$eventBus.$emit(this.$EVENTS.CREATE_ALERT, security);
            } else {
                this.setActiveWorksheetId(view.id);
                this.setFromToViewStatus(true);
                if (security) {
                    this.$nextTick(() => {
                        this.setActiveWorksheetSecurity({
                            id: getWorksheetSecurityId(security),
                            mstarSymbol: security.mstarSymbol
                        });
                    });
                }
            }
        },
        getMDSIconHtml(icon, customClass = '') {
            return `<button class="mds-button mds-button--icon-only mds-button--small ${customClass}" type="button">
            <svg class="mds-icon mds-button__icon ${customClass}" aria-hidden="true">
              <use xlink:href="${this.mdsIcon(icon)}"> </use>
            </svg>
          </button>`;
        },
        _bindCloseEvent(contentItem, componentId) {
            $('.lm_close_tab>button, .lm_close>button')
                .unbind('.close')
                .bind('click.close', this._closeEvent);
            const tab = contentItem.tab;
            if (tab) {
                tab.header.element.find('.lm_close_tab').empty();
                tab.closeElement
                    .html(this.getMDSIconHtml('remove--s'))
                    .attr('data-item-id', componentId);
                tab.closeElement
                    .find('button')
                    .unbind('click.close')
                    .bind('click.close', this._closeEvent);
            }
        },

        adjustHeaderStyle(contentItem, componentId) {
            //if only one tab, hide tab as text
            const stack = contentItem.parent;
            const searchElement = stack.header.controlsContainer.find(
                '.lm_search'
            );
            this.adjustWatchlistStyle(contentItem, componentId);
            if (searchElement) {
                $(searchElement).attr('data-id', componentId);
            }
            if (stack && stack.isStack) {
                const $settings = stack.header.controlsContainer.find(
                    '.lm_gear'
                );
                const $export = stack.header.controlsContainer.find(
                    '.lm_export'
                );
                const $print = stack.header.controlsContainer.find('.lm_print');
                const $import = stack.header.controlsContainer.find(
                    '.lm_import'
                );
                const $popout = stack.header.controlsContainer.find(
                    '.lm_popout'
                );
                const contentItemConfig = contentItem.config;
                // This logic means showSettingIcon default is true.
                // Only when we explicit set false it will be closed.
                // @TODO: reconsider the impl way.
                contentItemConfig.showSettingIcon =
                    contentItemConfig.showSettingIcon !== false;
                contentItemConfig.showSettingIcon
                    ? $settings.show()
                    : $settings.hide();
                contentItemConfig.showExportIcon
                    ? $export.show()
                    : $export.hide();
                contentItemConfig.showPopoutIcon
                    ? $popout.show()
                    : $popout.hide();
                contentItemConfig.showPrintIcon ? $print.show() : $print.hide();
                if (
                    contentItemConfig.watchlistType === WATCHLIST_TYPES.CUSTOM
                ) {
                    $import.show();
                } else {
                    $import.hide();
                }
                if (contentItemConfig.showAutocomplete) {
                    searchElement.show();
                    const $eleHeader = stack.element.find('.lm_header');
                    const $autoCompleteInput = $eleHeader.find(
                        '.' + this.namespaceClass('autocomplete_input')
                    );
                    if ($autoCompleteInput.length < 1) {
                        const cls = this.namespaceClass([
                            'autocomplete_input',
                            `autocomplete_input_${contentItemConfig.componentName}`
                        ]);
                        $eleHeader.append(
                            `<input type="text" class="mds-form__input ${cls}" id="${componentId}" placeholder="Search for Security">`
                        );
                    }
                    // FIXME: does this working?
                    $autoCompleteInput.attr('id', componentId);
                } else {
                    searchElement.hide();
                }
                stack.header.element.removeClass(
                    this.namespaceClass('worksheet-component-header__text')
                );
                if (stack.contentItems.length <= 1) {
                    stack.header.element.addClass(
                        this.namespaceClass('worksheet-component-header__text')
                    );
                }
            }
            if (contentItem.config.isClosable !== false) {
                this._bindCloseEvent(contentItem, componentId);
            }
            if (
                this.instanceMap.get(componentId) &&
                this.instanceMap.get(componentId).instance
            ) {
                this.instanceMap.get(componentId).instance.bindContainerEvent();
            }
            this.activeComponents.set(componentId, true);
        },
        adjustWatchlistStyle(contentItem, componentId) {
            const stack = contentItem.parent;
            const stackHeader = stack.header;
            const $lmtabsEle = stackHeader.tabsContainer;
            const $lmtabDropdown = stackHeader.tabDropdownContainer;
            const $tabElement = $lmtabsEle.find('.lm_tab');
            $tabElement.find('.lm_down').remove();
            if (
                contentItem.config.watchlistType ===
                    WATCHLIST_TYPES.READ_ONLY ||
                contentItem.config.watchlistType === WATCHLIST_TYPES.CUSTOM
            ) {
                const $tabActiveElement = $lmtabsEle.find('.lm_active');
                $tabActiveElement.find('.lm_close_tab').remove();
                $tabActiveElement.append(
                    `<li class="lm_down" title="Dropdown Menu">${this.getMDSIconHtml(
                        'caret-down--s'
                    )}</li>`
                );
                // FIXME: the code seems have a syntax error.
                $tabActiveElement
                    .find('.lm_down')
                    .unbind('click.dropdown')
                    .bind('click.dropdown', (contentItem, componentId), e => {
                        this._dropdownEvent(e, contentItem, componentId);
                    });
            }
            const _lastVisibleTabIndex = stackHeader._lastVisibleTabIndex;
            const componentContent = stack.config.content;
            let watchlistType = null;
            if (
                _lastVisibleTabIndex &&
                componentContent[_lastVisibleTabIndex]
            ) {
                watchlistType =
                    componentContent[_lastVisibleTabIndex].watchlistType;
            }

            // If cannot drag components to container, cannot add a component either
            if (!this.isGoldenLayoutFeatureEnable('componentDragable')) {
                return;
            }

            // ToFix: find another way to conclude if need add plus icon in the end
            const isWatchlistLast = $lmtabsEle
                .find('.lm_tab:last')
                .find('.lm_close_tab').length;
            $lmtabDropdown.find('.lm_down').remove();
            $lmtabsEle.removeClass(this.namespaceClass('add-icon'));
            if (
                !isWatchlistLast ||
                watchlistType === WATCHLIST_TYPES.READ_ONLY ||
                watchlistType === WATCHLIST_TYPES.CUSTOM
            ) {
                $lmtabsEle.addClass(this.namespaceClass('add-icon'));
                stackHeader.element
                    .find('.' + this.namespaceClass('add-icon'))
                    .unbind('click.addWatchlist')
                    .bind('click.addWatchlist', stack, e => {
                        const target = e.target || e.srcElement;
                        if (
                            target.className &&
                            target.className.indexOf &&
                            target.className.indexOf(
                                this.namespaceClass('add-icon')
                            ) > -1
                        ) {
                            e.stopPropagation();
                            this._addWatchlistEvent(e, stack);
                            // Tracking: number of usage on the 'Add' function next to the tab.
                            this.$appTracker.trackEvent({
                                name: 'watchlist-tab-add'
                            });
                        }
                    });
            }
        },
        changeWatchlistName(watchlists) {
            watchlists.forEach(w => {
                if (w.components) {
                    w.components.forEach(com => {
                        const item = this.instanceMap.get(com);
                        if (item && item.instance) {
                            item.contentItem.setTitle(w.name);
                        }
                    });
                }
            });
            this._changeLayoutConfig();
        },
        changeDeletedWatchlists(watchlists) {
            watchlists.forEach(w => {
                if (w.components) {
                    w.components.forEach(com => {
                        const item = this.instanceMap.get(com);
                        if (item && item.contentItem) {
                            item.contentItem.parent.removeChild(
                                item.contentItem
                            );
                        }
                    });
                }
            });
            this._changeLayoutConfig();
        },
        changeWatchlistTickers(watchlists) {
            watchlists.forEach(w => {
                if (w.components) {
                    w.components.forEach(com => {
                        const item = this.instanceMap.get(com);
                        if (item && item.instance) {
                            item.instance.setProperty('symbol', {
                                symbol: w.tickers
                            });
                        }
                    });
                }
            });
            // FIXME: why we keep this commentated code, maybe we should remove it?
            /**
             *QS-14714 Fixed open watchlist bug
             */
            // if (this.goldenLayout) {
            //     this.setLayoutConfig(this.goldenLayout.toConfig().content);
            // }
        },
        isGoldenLayoutFeatureEnable(feature) {
            if (this.activeWorksheet && this.activeWorksheet.profile) {
                return this.activeWorksheet.profile[feature] === false
                    ? false
                    : true;
            }
            return true;
        }
    }
};
</script>
<style lang="scss">
$namespace: 'mc-app-worksheet';
.#{$namespace} {
    &--in-edit-mode {
        .lm_maximise {
            display: none;
        }
    }
}
</style>
