import mwcMarketsCore from 'mwc-markets-core';
import { getComponentsFromLayout } from '../store/modules/worksheet';

const { utils } = mwcMarketsCore;
import { LOGIN_TIME_KEY } from '../common';
let appTracker = null;

/**
 * Init app tracker with config.
 * @returns {*}
 */
export function initAppTracker() {
    const dataLayer = window.dataLayer;
    appTracker = {
        setIdentify({ userId, instId }) {
            dataLayer.push({
                instId,
                userId
            });
        },
        trackEvent({ name, value, componentType = 'market-monitoring' }) {
            if (!name) {
                return;
            }
            const args = {
                event: name,
                event_scope: componentType
            };
            if (typeof value !== 'undefined') {
                args.event_value = value;
            }
            dataLayer.push(args);
        }
    };
    return appTracker;
}

/**
 * Get app tracker single instance.
 * Warning: modify this value carefully, it is best not to modify this value.
 * @returns {null}
 */
export function getAppTrackerSingleInstance() {
    if (appTracker) {
        return appTracker;
    } else {
        throw new Error('The tracker is not initialized');
    }
}

/**
 * Tracker plugin.
 * @param tracker
 * @returns {{}}
 */
export function getAppTrackingPlugin(tracker) {
    const trackingManager = {};
    trackingManager.install = Vue => {
        Vue.prototype.$appTracker = tracker;
    };
    return trackingManager;
}

const trackingEvents = (param, tracker) => {
    switch (param.name) {
        // Tracking: number of usage on each fundamentals.
        case 'fundamentals': {
            if (param.value && param.active) {
                tracker.trackEvent({
                    name: 'chart-fundamentals-select',
                    value: param.value
                });
            }
            break;
        }
        // Tracking: number of usage on each drawings.
        case 'drawings': {
            if (param.value) {
                tracker.trackEvent({
                    name: 'chart-drawings-select',
                    value: param.value
                });
            }
            break;
        }
        // Tracking: number of usage on each display types.
        case 'display': {
            if (param.value) {
                tracker.trackEvent({
                    name: 'chart-display-select',
                    value: param.value
                });
            }
            break;
        }
        // Tracking: number of usage on each frequency.
        case 'frequency': {
            if (param.value) {
                tracker.trackEvent({
                    name: 'chart-frequency-select',
                    value: param.value
                });
            }
            break;
        }
        // Tracking: number of usage on each indicators.
        case 'eventsIndicators': {
            // We need ignore some events because it is not a indicator.
            const needIgnoreEvents = ['dividend', 'split', 'earnings'];
            if (
                param.value &&
                utils.inArray(needIgnoreEvents, param.value) === -1 &&
                param.active
            ) {
                tracker.trackEvent({
                    name: 'chart-indicator-select',
                    value: param.value
                });
            }
            break;
        }
        // Tracking: number of usage on each date range.
        case 'dateRange': {
            if (param.value) {
                tracker.trackEvent({
                    name: 'chart-date-range-select',
                    value: param.value
                });
            }
            break;
        }
    }
};

/**
 * Set chart tracking by callback.
 * @param tracker
 * @returns {{}|null}
 */
export function getAdvancedChartEventsTrackingCallbacks(tracker) {
    const callbacks = {};
    // Tracking: number of usage on the Add Comparison function.
    callbacks['addCompareTickers'] = (componentId, param) => {
        if (param && utils.isArray(param) && param.length > 0) {
            const addedCompareTickerIndex = 0;
            tracker.trackEvent({
                name: 'chart-compare-tickers-add',
                value:
                    param[addedCompareTickerIndex] &&
                    param[addedCompareTickerIndex].symbol
            });
        }
    };
    callbacks['click'] = (componentId, param) => {
        if (!param) {
            return;
        }
        trackingEvents(param, tracker);
    };
    return callbacks;
}

export function trackWorksheetComponentList(workbooks = []) {
    const tracker = getAppTrackerSingleInstance();
    workbooks.forEach(wb => {
        if (
            wb.worksheets &&
            utils.isArray(wb.worksheets) &&
            wb.worksheets.length > 0
        ) {
            // Tracking: number of worksheets per user.
            tracker.trackEvent({
                name: 'worksheet-count',
                value: wb.worksheets.length
            });
            wb.worksheets.forEach(worksheet => {
                if (typeof worksheet.profile !== 'string') {
                    return; // the profile is string means it's the default profile, then it doesn't need tracking
                }
                tracker.trackEvent({
                    name: 'worksheet-list',
                    value: worksheet.name
                });
                const worksheetProfile = JSON.parse(worksheet.profile);
                if (worksheetProfile && worksheetProfile.layoutConfig) {
                    const components = getComponentsFromLayout(
                        worksheetProfile.layoutConfig
                    );
                    if (utils.isArray(components) && components.length > 0) {
                        const componentNames = {};
                        components.forEach(component => {
                            if (componentNames[component.componentName]) {
                                componentNames[component.componentName].count++;
                            } else {
                                componentNames[component.componentName] = {
                                    name: component.componentName,
                                    count: 1
                                };
                            }
                        });
                        let values = '';
                        Object.values(componentNames).forEach(com => {
                            if (values) {
                                values += ',';
                            }
                            values = `${values}${com.name}:${com.count}`;
                        });
                        // Tracking: list of component added to each worksheet for each user by INSTID.
                        tracker.trackEvent({
                            name: 'worksheet-comp-list',
                            value: values
                        });
                    }
                }
            });
        }
    });
}

function getActiveTime() {
    const loginTime = +window.sessionStorage.getItem(LOGIN_TIME_KEY);
    if (loginTime) {
        const activeTime = new Date().getTime() - loginTime;
        const activeTimeInMinutes = Math.ceil(activeTime / (1000 * 60), 10);
        return {
            activeTime,
            activeTimeInMinutes
        };
    }
    return null;
}

export function trackActiveTime() {
    const tracker = getAppTrackerSingleInstance();
    const { activeTimeInMinutes } = getActiveTime() || {};
    tracker.trackEvent({
        name: 'sign-out',
        value: activeTimeInMinutes
    });
}
