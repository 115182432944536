import api from '../../data/api';
import { extendAuthParams } from '../../util';
const state = {
    environment: null,
    paths: null,
    uimSessionManager: {},
    loading: false,
    excelRTD: {},
    globalConfigLoaded: false,
    frequency: 10000,
    notification: {
        visible: false
    }
};

const getters = {
    url: state => path => {
        if (state.paths) {
            if (state.paths[path]) {
                return state.paths[path];
            } else {
                const array = path.split('/');
                const domain = array[0];
                const context =
                    array.length > 1 ? array.slice(1).join('/') : '';
                if (state.paths[domain]) {
                    return `${state.paths[domain]}${context}`;
                } else {
                    return null;
                }
            }
        } else {
            return null;
        }
    }
};

const actions = {
    getConfig({ commit }) {
        return api.getAppConfig().then(d => {
            commit('setConfig', d);
            return d;
        });
    },
    getExcelRTDConfig({ commit }) {
        return api.getExcelRTDConfig().then(d => {
            commit('setExcelRTDConfig', d);
            return d;
        });
    },
    saveProfile({ dispatch, commit, rootState }, needNotification) {
        const watchlists = rootState.watchlist.watchlists.filter(w => {
            return /^default.+/.test(w.id);
        });
        let promise;
        if (watchlists.length > 0) {
            promise = dispatch('watchlist/saveWatchlists', null, {
                root: true
            }).then(() => {
                return Promise.all([
                    dispatch('workbook/saveWorkbooks', needNotification, {
                        root: true
                    }),
                    dispatch('settings/saveSettings', null, { root: true })
                ]);
            });
        } else {
            promise = Promise.all([
                dispatch('workbook/saveWorkbooks', needNotification, {
                    root: true
                }),
                dispatch('watchlist/saveWatchlists', null, { root: true }),
                dispatch('settings/saveSettings', null, { root: true })
            ]);
        }
        return promise
            .then(() => {
                if (needNotification) {
                    commit('setNotification', {
                        visible: true,
                        type: 'success',
                        message: 'The worksheets have been saved successfully.',
                        dismissible: true
                    });
                }
            })
            .catch(e => {
                if (needNotification) {
                    commit('setNotification', {
                        visible: true,
                        type: 'error',
                        message: 'There was an error while saving.',
                        dismissible: true
                    });
                }
            });
    },
    async submitSupportContactForm({ rootGetters, getters }, formData) {
        const url = getters.url('@web/ra/webToCase');
        const token = rootGetters['account/token'];
        return api.submitWebToCaseForm(url, extendAuthParams(formData, token));
    }
};

const mutations = {
    setConfig(state, { paths, environment, uimSessionManager }) {
        state.environment = environment;
        state.paths = paths;
        state.uimSessionManager = uimSessionManager;
    },
    setExcelRTDConfig(state, excelRTD = {}) {
        state.excelRTD = excelRTD;
    },
    setNotification(state, prompt) {
        state.notification = prompt;
        if (prompt.visible) {
            state.notification.visibleTime = new Date().getTime();
        }
    },
    setLoading(state, loading) {
        state.loading = loading;
    },
    setGlobalConfigLoaded(state, status) {
        state.globalConfigLoaded = !!status;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
