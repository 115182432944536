import utils from '../util';
class WatchlistManager {
    constructor() {
        this.watchlists = [];
        this.componentsMap = {};
        this.events = {
            watchlistsDeleted: [],
            watchlistsNameChanged: [],
            watchlistsTickersChanged: [],
            openWatchlists: []
        };
    }
    setWatchlistData(watchlistData) {
        const nameChanged = [],
            tickersChanged = [],
            deleted = [];
        this.watchlists.forEach(w => {
            const target = utils.find(watchlistData, item => {
                return item.id === w.id || item.originalId === w.id;
            });
            if (target) {
                if (target.name !== w.name) {
                    nameChanged.push(target);
                }
                if (target.tickers !== w.tickers) {
                    tickersChanged.push(target);
                }
            } else {
                deleted.push(w);
            }
        });

        if (deleted.length > 0) {
            this._trigger('watchlistsDeleted', deleted);
        }
        let componentMap = {};
        watchlistData.forEach(item => {
            const target = utils.find(
                Object.keys(this.componentsMap),
                componentId => {
                    return (
                        item.id === componentId ||
                        item.originalId === componentId
                    );
                }
            );
            componentMap[item.id] = !this.componentsMap[target]
                ? []
                : this.componentsMap[target];
        });
        this.componentsMap = componentMap;
        if (nameChanged.length > 0) {
            this._trigger('watchlistsNameChanged', nameChanged);
        }
        if (tickersChanged.length > 0) {
            this._trigger('watchlistsTickersChanged', tickersChanged);
        }

        this.watchlists = utils.extend(true, [], watchlistData);
    }
    bindHoldingsWithComponents(holdingsId, componentsId) {
        Object.keys(this.componentsMap).forEach(key => {
            const hasComponentsIdx = this.componentsMap[key].indexOf(
                componentsId
            );
            if (hasComponentsIdx > -1) {
                this.componentsMap[key].splice(hasComponentsIdx, 1);
            }
        });
        if (!this.componentsMap[holdingsId]) {
            this.componentsMap[holdingsId] = [];
        }
        this.componentsMap[holdingsId].push(componentsId);
    }
    trigger(eventName, param) {
        this._trigger(eventName, param);
    }

    _trigger(eventName, param) {
        const callbacks = this.events[eventName] && this.events[eventName];
        if (callbacks && callbacks.length > 0) {
            callbacks.forEach(callback => {
                callback(this._extendRelatedComponents(param));
            });
        }
    }
    _extendRelatedComponents(watchlists) {
        return watchlists.map(w => {
            const _w = utils.extend(true, {}, w);
            let components = this.componentsMap[w.id];
            if (!components) {
                components = this.componentsMap[w.originalId];
            }
            _w.components = components;
            return _w;
        });
    }
    on(eventName, callbackFn) {
        if (this.events[eventName] && typeof callbackFn === 'function') {
            this.events[eventName].push(callbackFn);
        }
    }
    off(eventName, callbackFn) {
        if (this.events[eventName]) {
            if (typeof callbackFn === 'function') {
                this.events[eventName] = this.events[eventName].filter(
                    callback => {
                        return callback !== callbackFn;
                    }
                );
            } else if (callbackFn === 'ALL') {
                this.events[eventName] = [];
            }
        }
    }
}
const watchlistManager = {};
watchlistManager.install = function(Vue) {
    Vue.prototype.$watchlistManager = new WatchlistManager();
};
export default watchlistManager;
