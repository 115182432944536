<template>
    <div
        class="mds-modal mds-modal--width-900px"
        :class="[
            namespaceClass('global-settings'),
            isEditing ? 'mds-modal--open mds-modal--active' : ''
        ]"
    >
        <div class="mds-overlay" v-show="isEditing"></div>
        <div class="mds-modal__wrapper">
            <section
                role="dialog"
                aria-labelledby="global-setting-modal"
                class="mds-modal__container"
                aria-hidden="true"
            >
                <div class="mds-modal__inner">
                    <header class="mds-modal__header">
                        <div class="mds-modal__actions">
                            <button
                                class="mds-button mds-button--secondary"
                                type="button"
                                data-mds-modal-close
                                @click="setEditStatus(false)"
                            >
                                Cancel
                            </button>
                            <button
                                class="mds-button mds-button--primary"
                                type="button"
                                @click="changeSettings"
                            >
                                Save
                            </button>
                        </div>
                        <h1 class="mds-modal__title" id="global-setting-modal">
                            Global Settings
                        </h1>
                    </header>
                    <div class="mds-modal__content">
                        <div class="mds-layout-grid">
                            <div class="mds-layout-grid__row">
                                <div
                                    class="mds-layout-grid__col mds-layout-grid__col--2"
                                    :class="
                                        namespaceClass('global-settings__nav')
                                    "
                                >
                                    <ul class="mds-list-group">
                                        <li class="mds-list-group__item">
                                            <a
                                                href="#"
                                                class="mds-list-group__link"
                                            >
                                                <span
                                                    class="mds-list-group__item-text"
                                                >
                                                    General
                                                </span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div
                                    class="mds-layout-grid__col mds-layout-grid__col--10"
                                >
                                    <div class="mds-layout-grid__row">
                                        <div
                                            class="mds-layout-grid__col mds-layout-grid__col--12"
                                        >
                                            <form
                                                class="mds-form mds-form--medium-layout"
                                                onsubmit="return false;"
                                            >
                                                <div
                                                    class="mds-form__field-group mds-form__field-group--horizontal"
                                                >
                                                    <div
                                                        v-if="
                                                            isEnableMultiThemes
                                                        "
                                                        class="mds-form__field-group"
                                                        :class="
                                                            namespaceClass(
                                                                'flex-grow-1'
                                                            )
                                                        "
                                                    >
                                                        <markets-ui-form>
                                                            <markets-ui-radio-button-group
                                                                title="Choose theme"
                                                                :skin="skin"
                                                                size="medium"
                                                                isHorizontal
                                                                :dataModel="
                                                                    themeList
                                                                "
                                                                @change="
                                                                    changeTheme
                                                                "
                                                            />
                                                        </markets-ui-form>
                                                    </div>

                                                    <div
                                                        class="mds-form__field-group"
                                                        :class="
                                                            namespaceClass(
                                                                'flex-grow-1'
                                                            )
                                                        "
                                                    >
                                                        <markets-ui-form>
                                                            <markets-ui-radio-button-group
                                                                title="Choose data type"
                                                                :skin="skin"
                                                                size="medium"
                                                                isHorizontal
                                                                :dataModel="
                                                                    dataTypeList
                                                                "
                                                                @change="
                                                                    changeDataType
                                                                "
                                                            />
                                                        </markets-ui-form>
                                                    </div>
                                                </div>
                                                <div
                                                    class="mds-form__field-group mds-form__field-group--horizontal"
                                                >
                                                    <div
                                                        class="mds-form__field-group"
                                                        :class="
                                                            namespaceClass(
                                                                'flex-grow-1'
                                                            )
                                                        "
                                                    >
                                                        <label
                                                            class="mds-form__checkbox"
                                                            :class="
                                                                namespaceClass(
                                                                    'auto-save-label'
                                                                )
                                                            "
                                                        >
                                                            <input
                                                                type="checkbox"
                                                                class="mds-form__checkbox-input"
                                                                v-model="
                                                                    settings.autoSave
                                                                "
                                                            />
                                                            <span
                                                                class="mds-form__checkbox-visible-wrap"
                                                            >
                                                                <span
                                                                    class="mds-form__checkbox-visual"
                                                                >
                                                                    <svg
                                                                        class="mds-icon mds-form__checkbox-checked-icon"
                                                                        aria-hidden="true"
                                                                    >
                                                                        <use
                                                                            :xlink:href="
                                                                                mdsIcon(
                                                                                    'check--s'
                                                                                )
                                                                            "
                                                                        ></use>
                                                                    </svg>
                                                                    <svg
                                                                        class="mds-icon mds-form__checkbox-indeterminate-icon"
                                                                        aria-hidden="true"
                                                                    >
                                                                        <use
                                                                            :xlink:href="
                                                                                mdsIcon(
                                                                                    'minus--s'
                                                                                )
                                                                            "
                                                                        ></use>
                                                                    </svg>
                                                                </span>
                                                                <span
                                                                    class="mds-form__checkbox-text"
                                                                    >Auto-save</span
                                                                >
                                                            </span>
                                                        </label>
                                                        <span
                                                            :class="
                                                                namespaceClass(
                                                                    'auto-save-details'
                                                                )
                                                            "
                                                            v-if="
                                                                settings.autoSave
                                                            "
                                                        >
                                                            <span>Every </span
                                                            ><input
                                                                v-model="
                                                                    settings.autoSaveInterval
                                                                "
                                                                class="mds-form__input"
                                                                :class="
                                                                    namespaceClass(
                                                                        'auto-save-input'
                                                                    )
                                                                "
                                                                min="1"
                                                                max="999"
                                                                type="number"
                                                            /><span>
                                                                minutes</span
                                                            >
                                                        </span>
                                                        <span
                                                            class="mds-form__field-error"
                                                            role="alert"
                                                            v-if="errorMessage"
                                                        >
                                                            <span
                                                                class="mds-form__field-error-text"
                                                                >{{
                                                                    errorMessage
                                                                }}</span
                                                            >
                                                        </span>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import {
    mapActions as rootMapActions,
    mapState as rootMapState,
    createNamespacedHelpers
} from 'vuex';
const { mapState, mapGetters, mapMutations } = createNamespacedHelpers(
    'settings'
);
import FUNCTIONS from '../data/functions';
export default {
    name: 'GlobalSettings',
    computed: {
        ...mapState([
            'isEditing',
            'theme',
            'dataType',
            'duplicateWarning',
            'autoSave',
            'autoSaveInterval'
        ]),
        ...mapGetters(['skin', 'isFeatureEnable']),
        ...rootMapState('workbook', ['isDirty']),
        isEnableMultiThemes() {
            return this.isFeatureEnable(FUNCTIONS.MULTI_THEMES);
        },
        themeList() {
            return [
                {
                    name: 'White',
                    id: 'default',
                    selected: this.settings.theme === 'default'
                },
                {
                    name: 'Black',
                    id: 'black',
                    selected: this.settings.theme === 'black'
                }
            ];
        },
        dataTypeList() {
            return [
                {
                    name: 'Update every 10 seconds',
                    id: 'pull',
                    selected: this.settings.dataType === 'pull'
                },
                {
                    name: 'Streaming data',
                    id: 'stream',
                    selected: this.settings.dataType === 'stream'
                }
            ];
        }
    },
    data() {
        return {
            settings: {},
            errorMessage: ''
        };
    },
    watch: {
        isEditing(value) {
            if (value) {
                this._resetSettings();
            }
        },
        autoSaveInterval: {
            handler(value) {
                if (value && !isNaN(value) && this.autoSave && +value > 0) {
                    const self = this;
                    window.clearInterval(this.SAVE_INTERVAL);
                    this.SAVE_INTERVAL = window.setInterval(() => {
                        if (self.isDirty) {
                            self.$requestManager.add(
                                () => self.saveProfile(false),
                                'saveProfile'
                            );
                        }
                    }, value * 60 * 1000);
                } else {
                    window.clearInterval(this.SAVE_INTERVAL);
                }
            },
            immediate: true
        },
        autoSave(value) {
            if (!value) {
                window.clearInterval(this.SAVE_INTERVAL);
            }
        },
        'settings.autoSave'(value) {
            if (!value) {
                this.errorMessage = '';
            }
            this.setDirty(true);
        },
        'settings.autoSaveInterval'() {
            this.setDirty(true);
        }
    },
    created() {
        this.SAVE_INTERVAL = null;
    },
    beforeDestroy() {
        window.clearInterval(this.SAVE_INTERVAL);
    },
    methods: {
        ...mapMutations(['setEditStatus', 'setDirty']),
        ...rootMapActions('settings', ['saveSettings']),
        ...rootMapActions('application', ['saveProfile']),
        changeSettings() {
            if (
                this.settings.autoSave &&
                (typeof +this.settings.autoSaveInterval !== 'number' ||
                    this.settings.autoSaveInterval < 1)
            ) {
                this.errorMessage =
                    'Auto-save interval should be number and >=1';
                return;
            }
            this.errorMessage = '';
            this._saveSettings();
            this.setEditStatus(false);
        },
        changeTheme(item) {
            this.settings.theme = item.id;
            this.setDirty(true);
        },
        changeDataType(item) {
            this.settings.dataType = item.id;
            this.setDirty(true);
        },
        _saveSettings() {
            this.$requestManager.add(
                () => this.saveSettings(this.settings),
                'saveSettings'
            );
        },
        _resetSettings() {
            this.settings = {
                theme: this.theme,
                dataType: this.dataType,
                autoSave: this.autoSave,
                autoSaveInterval: this.autoSaveInterval
            };
        }
    }
};
</script>
