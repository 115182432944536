import router from '../router';
import axios from 'axios';
import Qs from 'qs';
import utils from '../util';
import componentsType from './components-type.js';
import {
    getComponentsByInstid,
    getWorkbooksByInstid,
    getGlobalSettingsByInstid
} from './local';
import { WILM_WORKSHEET_TEMPLATE } from './local/WILM/common.js';
const axiosInstance = axios.create();
axiosInstance.defaults.headers.post['Content-Type'] =
    'application/x-www-form-urlencoded';

axiosInstance.interceptors.response.use(
    res => {
        if (res.data && res.data.status && res.data.status.errorCode === '-1') {
            if (res.data.status.subErrorCode === '10000') {
                window.onbeforeunload = undefined;
                router.push('/signin');
            } else {
                return Promise.reject(res.data);
            }
        } else if (
            res.data &&
            res.data.status &&
            res.data.status.errorCode !== '0'
        ) {
            return Promise.reject(res.data);
        }
        return res.data;
    },
    error => {
        return Promise.reject(error);
    }
);

async function workbookList(url, params) {
    return axiosInstance({
        method: 'get',
        url,
        params
    }).then(res => {
        if (hasValidData(res)) {
            return res.data;
        } else {
            return getWorkbooksByInstid(params.instid);
        }
    });
}

function saveWorkbooks(url, params) {
    return axiosInstance({
        method: 'post',
        url: getPostURL(url, params),
        data: Qs.stringify(params)
    }).then(res => {
        return res.data;
    });
}

function deleteWorksheet(url, params) {
    return axiosInstance({
        method: 'get',
        url,
        params
    }).then(res => {
        return res.data;
    });
}

async function listAvailableComponents(instid) {
    const components = await getComponentsByInstid(instid);
    const availableComponents = components.map(com => {
        const baseCom = utils.find(componentsType, item => {
            return item.componentType === com.componentType;
        });
        return utils.extend(true, {}, baseCom, com);
    });
    return availableComponents.filter(component => !component.disabled);
}

function checkSignin(url, params = {}) {
    params._t = new Date().getTime();
    return axiosInstance({
        method: 'get',
        url,
        params,
        withCredentials: true,
        headers: {
            'x-requested-with-credentials': true
        }
    }).then(d => handleAccountInfo(d));
}

function signout(url, params = {}) {
    return axiosInstance({
        method: 'get',
        url,
        params
    });
}

function heartbeat(url, params = {}) {
    params._t = new Date().getTime();
    return axiosInstance({
        method: 'get',
        url,
        params
    });
}

async function getTemplateWorksheets(instid) {
    const defaultWorkbooks = utils.extend(
        true,
        [],
        await getWorkbooksByInstid(instid)
    );
    if (instid === 'WILM') {
        defaultWorkbooks[0].worksheets = defaultWorkbooks[0].worksheets.concat(
            WILM_WORKSHEET_TEMPLATE
        );
    }
    return defaultWorkbooks[0].worksheets.filter(ws => {
        return ws.isTemplate;
    });
}

async function getGlobalSettings(url, params) {
    const promiseList = [
        getGlobalSettingsByInstid(params.instid),
        axiosInstance({
            method: 'get',
            url,
            params
        })
    ];
    return Promise.all(promiseList)
        .then(res => {
            let defaultSettings,
                savedSettings = {},
                response;
            [defaultSettings, response] = res;

            if (response.data.profile) {
                savedSettings = JSON.parse(response.data.profile);
            }
            if (response.data.functions) {
                savedSettings.functions = response.data.functions;
            }
            if (response.data.holdings) {
                savedSettings.holdings = response.data.holdings;
            }
            if (response.data.accountId) {
                savedSettings.accountId = response.data.accountId;
            }
            return utils.extend(true, defaultSettings, savedSettings);
        })
        .catch(() => {
            return getGlobalSettingsByInstid(params.instid);
        });
}
function saveGlobalSettings(url, params) {
    return axiosInstance({
        method: 'post',
        url: getPostURL(url, params),
        data: Qs.stringify(params)
    });
}

function checkHoldings(url, params) {
    return axiosInstance({
        method: 'get',
        url,
        params
    }).then(d => {
        return d.data;
    });
}

async function getWatchlists(url, params) {
    return axiosInstance({
        method: 'get',
        url: `${url}/list`,
        params
    })
        .then(res => {
            return res.data;
        })
        .catch(() => {
            return [];
        });
}
async function getInitialList(url, params) {
    return axiosInstance({
        method: 'get',
        url: `${url}/initialList`,
        params
    })
        .then(res => {
            return res.data;
        })
        .catch(() => {
            return [];
        });
}

function addWatchlist(url, params) {
    return axiosInstance({
        method: 'get',
        url,
        params
    }).then(res => {
        return res.data[0];
    });
}
function saveWatchlists(url, params) {
    return axiosInstance({
        method: 'post',
        url: getPostURL(url, params),
        data: Qs.stringify(params)
    }).then(res => {
        if (hasValidData(res)) {
            return res.data;
        } else {
            return Promise.reject();
        }
    });
}

function updateWatchlist(url, params) {
    return axiosInstance({
        method: 'get',
        url,
        params
    }).then(d => {
        return d.data;
    });
}
function deleteWatchlists(url, params) {
    return axiosInstance({
        method: 'get',
        url,
        params
    });
}

async function getAppConfig() {
    return axiosInstance({
        method: 'get',
        url: `config/app-config.json?v=${process.env.VUE_APP_VERSION}`
    });
}

async function getExcelRTDConfig() {
    return axiosInstance({
        method: 'get',
        url: `excelRTD/config.json?v=${process.env.VUE_APP_VERSION}`
    });
}

function hasValidData(res) {
    return (
        res.status &&
        res.status.errorCode === '0' &&
        res.data &&
        res.data.length
    );
}
function getPostURL(url, params = {}) {
    if (params.qs_wsid && params.instid) {
        url += `?qs_wsid=${params.qs_wsid}&instid=${params.instid}`;
        delete params.qs_wsid;
        delete params.instid;
    }
    return url;
}
function handleAccountInfo(d) {
    let sessionId = null;
    if (d.status.errorCode === '0') {
        sessionId = d.data;
    }
    const attachment = d.attachment || {};
    let errorCode = d.status.errorCode;
    if (d.status.subErrorCode === '10006') {
        errorCode = '-2';
    } else if (d.status.subErrorCode === '10007') {
        errorCode = '-3';
    }
    return {
        errorCode,
        instid: attachment.Instid,
        userId: attachment.UsrID,
        sessionId
    };
}

function submitWebToCaseForm(url, params) {
    return axiosInstance({
        method: 'post',
        url: getPostURL(url, params),
        data: Qs.stringify(params)
    });
}

export default {
    listAvailableComponents,
    workbookList,
    checkSignin,
    signout,
    heartbeat,
    saveWorkbooks,
    getAppConfig,
    getExcelRTDConfig,
    deleteWorksheet,
    getTemplateWorksheets,
    getGlobalSettings,
    saveGlobalSettings,
    checkHoldings,
    addWatchlist,
    updateWatchlist,
    getInitialList,
    getWatchlists,
    saveWatchlists,
    deleteWatchlists,
    submitWebToCaseForm
};
