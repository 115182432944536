<template>
    <section
        :class="namespaceClass('workbook')"
        :data-id="activeWorkbook ? activeWorkbook.id : ''"
    >
        <Header v-show="tabVisible" />
        <Bodyer />
        <markets-ui-dialog
            :class="namespaceClass('save-dialog')"
            @click="dialogResponsed"
            :cancel="saveDialog.cancel"
            :resolve="saveDialog.resolve"
            :visible="saveDialog.visible"
            :message="saveDialog.message"
            :skin="skin"
        >
        </markets-ui-dialog>
        <markets-ui-dialog
            :class="namespaceClass('session-timeout-dialog')"
            @click="timeoutDialogResponsed"
            :cancel="timeoutDialog.cancel"
            :resolve="timeoutDialog.resolve"
            :visible="timeoutDialogVisible"
            :message="sessionTimeoutMessage"
            :skin="skin"
            action-required
        >
        </markets-ui-dialog>
    </section>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import Header from './WorkbookHeader.vue';
import Bodyer from './Bodyer.vue';
import { getSaveDialog, LOGIN_TIME_KEY } from '../../common';
import utils from '../../util';
import { trackActiveTime } from '../../componentsManager/tracking';
const COUNT_DOWN_TIME = 300;
export default {
    name: 'Workbook',
    components: {
        Header,
        Bodyer
    },
    computed: {
        ...mapGetters('workbook', ['activeWorkbook']),
        ...mapGetters('settings', ['skin']),
        ...mapState('settings', ['sessionExpiration']),
        ...mapState('application', ['loading']),
        ...mapState('workbook', ['isDirty']),
        ...mapState('account', ['sessionTimeout']),
        ...mapState('application', ['globalConfigLoaded']),
        ...mapActions('account', ['signout']),
        sessionTimeoutMessage() {
            const message = `Your session will expire in ${this.sessinTimeoutTime} seconds.`;
            if (this.sessionExpiration) {
                return `${message} Please save any data you may have updated before the session timeout.`;
            } else {
                return `${message} To stay signed in, click 'Continue Session'`;
            }
        },
        timeoutDialog() {
            const dialog = {
                visible: this.timeoutDialogVisible
            };
            if (this.sessionExpiration) {
                utils.extend(true, dialog, {
                    resolve: {
                        label: 'OK'
                    }
                });
            } else {
                utils.extend(true, dialog, {
                    resolve: {
                        label: 'Continue Session'
                    },
                    cancel: {
                        label: 'Sign Out'
                    }
                });
            }
            return dialog;
        }
    },
    data() {
        return {
            tabVisible: false,
            sessinTimeoutTime: null,
            saveDialog: {
                visible: false,
                message: ''
            },
            timeoutDialogVisible: false
        };
    },
    created() {
        this.listWorkbooks();
        this.saveDialog = getSaveDialog();
        this._startHeartbeat(false);
        this.tabVisibleUnwatch = this.$watch('loading', () => {
            if (!this.loading) {
                this.tabVisible = true;
                this.tabVisibleUnwatch();
            }
        });
        this.activeWorkbookUnwatch = this.$watch('activeWorkbook', value => {
            if (value) {
                this.listWorksheets(value.id).then(() => {
                    this.setLoading(false);
                });
                this.activeWorkbookUnwatch();
            }
        });
        this.globalConfigLoadedUnwatch = this.$watch('globalConfigLoaded', () =>
            this.handleSession()
        );
        this.debouncedCountinueSession = utils.debounce(() => {
            this._continueSession();
        }, 30000);

        window.addEventListener('unload', () => {
            const loginTime = +window.sessionStorage.getItem(LOGIN_TIME_KEY);
            if (loginTime) {
                trackActiveTime();
            }
        });
    },
    methods: {
        ...mapActions('workbook', ['getWorkbook', 'listWorkbooks']),
        ...mapActions('worksheet', ['listWorksheets']),
        ...mapActions('application', ['saveProfile']),
        ...mapActions('account', ['heartbeat']),
        ...mapMutations('application', ['setNotification', 'setLoading']),
        ...mapMutations('workbook', ['setNavMenuStatus']),
        beforeLeave(event) {
            event.returnValue = 'Changes you made may not be saved.';
            return event.returnValue;
        },
        dialogResponsed(data) {
            if (data === 'resolve') {
                this.saveProfile(true);
            }
            this.saveDialog.visible = false;
        },
        timeoutDialogResponsed(data) {
            if (data === 'resolve') {
                if (!this.sessionExpiration) {
                    this._continueSession();
                }
                this.hideTimeoutDialog();
            } else {
                this._signout();
            }
        },
        hideTimeoutDialog() {
            window.clearInterval(this.sessionTimeoutInterval);
            this.sessionTimeoutInterval = null;
            this.timeoutDialogVisible = false;
            document.removeEventListener('click', this._clickCallback);
        },
        showSessionTimeout() {
            this.timeoutDialogVisible = true;
            document.addEventListener('click', this._clickCallback);
        },
        handleSession() {
            if (this.sessionExpiration) {
                // will continue the session every 5 minutes until reach the session expiration time
                this._startHeartbeat(true, 300000);
                const remainTime = this.getSessionExpirationTime();
                if (remainTime > 0) {
                    this.signoutTimer = setTimeout(() => {
                        this._signout();
                    }, remainTime * 1000);
                }
                if (remainTime > COUNT_DOWN_TIME) {
                    this.countDownTimer = setTimeout(() => {
                        this.sessinTimeoutTime = COUNT_DOWN_TIME;
                        this._countDownSessionTimeout();
                    }, (remainTime - COUNT_DOWN_TIME) * 1000);
                } else if (remainTime > 0) {
                    this.sessinTimeoutTime = remainTime;
                    this._countDownSessionTimeout();
                } else {
                    this.signout();
                }
            } else {
                // Will continue the session when user active the window
                document.addEventListener(
                    'click',
                    this.debouncedCountinueSession,
                    true
                );
            }
        },
        _clickCallback(e) {
            const isOverlay = $(e.target).hasClass('mds-overlay___markets');
            if (isOverlay) {
                if (!this.sessionExpiration) {
                    this._continueSession();
                }
                this.hideTimeoutDialog();
            }
        },
        _signout() {
            window.onbeforeunload = undefined;
            this.hideTimeoutDialog();
            this.signout();
        },
        _continueSession() {
            this.isContinueSession = true;
            this.heartbeat({
                continueSession: true
            }).then(() => {
                this.isContinueSession = false;
            });
        },
        _stopHeartbeat(isContinueSession = false) {
            if (isContinueSession) {
                if (this.continueSessionTimer !== null) {
                    clearTimeout(this.continueSessionTimer);
                    this.continueSessionTimer = null;
                }
            } else {
                if (this.heartbeatTimer !== null) {
                    clearTimeout(this.heartbeatTimer);
                    this.heartbeatTimer = null;
                }
                document.removeEventListener(
                    'click',
                    this.debouncedCountinueSession,
                    true
                );
            }
        },
        _startHeartbeat(continueSession = false, time = 30000) {
            const timer = setTimeout(() => {
                this.heartbeat({
                    continueSession
                })
                    .then(() => {
                        this._startHeartbeat(continueSession, time);
                    })
                    .catch(() => {
                        this._startHeartbeat(continueSession, time);
                    });
            }, time);

            if (continueSession) {
                this.continueSessionTimer = timer;
            } else {
                this.heartbeatTimer = timer;
            }
        },
        getSessionExpirationTime() {
            const loginTime = +window.sessionStorage.getItem(LOGIN_TIME_KEY);
            if (loginTime) {
                return parseInt(
                    (this.sessionExpiration * 1000 -
                        (new Date().getTime() - loginTime)) /
                        1000
                );
            }
            return null;
        },
        _countDownSessionTimeout() {
            this.sessionTimeoutInterval = window.setInterval(() => {
                if (this.sessinTimeoutTime > 1) {
                    this.sessinTimeoutTime = this.sessinTimeoutTime - 1;
                } else {
                    this._signout();
                }
            }, 1000);
            if (!this.timeoutDialogVisible) {
                this.showSessionTimeout();
            }
        }
    },
    watch: {
        isDirty(value) {
            if (value) {
                window.onbeforeunload = this.beforeLeave;
            } else {
                window.onbeforeunload = undefined;
            }
        },
        sessionTimeout(value) {
            if (
                value <= COUNT_DOWN_TIME + 30 &&
                !this.sessionTimeoutInterval &&
                !this.sessionExpiration
            ) {
                // Signout 30 seconds in advance to avoid the case: The session is expired while the count is 10.
                this.sessinTimeoutTime = value > 30 ? value - 30 : value;
                if (this.isContinueSession !== true) {
                    this._countDownSessionTimeout();
                }
            }
        }
    },
    beforeDestroy() {
        this.hideTimeoutDialog();
        this._stopHeartbeat();
        if (this.countDownTimer) {
            clearTimeout(this.countDownTimer);
        }
        if (this.signoutTimer) {
            clearTimeout(this.signoutTimer);
        }
    }
};
</script>
