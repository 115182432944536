async function getCustomConfigByInstid(instid) {
    instid = instid.toUpperCase();
    let config;
    if (instid === 'KEY') {
        config = await import(
            /* webpackChunkName: "key-config" */ './KEY/'
        ).then(module => {
            return module.default;
        });
    } else if (instid === 'WILM') {
        config = await import(
            /* webpackChunkName: "wilm-config" */ './WILM/'
        ).then(module => {
            return module.default;
        });
    } else {
        config = await import(
            /* webpackChunkName: "default-config" */ './DEFAULT/'
        ).then(module => {
            return module.default;
        });
    }
    return config;
}

export async function getComponentsByInstid(instid) {
    const customConfig = await getCustomConfigByInstid(instid);
    return customConfig.components;
}

export async function getWorkbooksByInstid(instid) {
    const customConfig = await getCustomConfigByInstid(instid);
    return customConfig.workbooks;
}

export async function getGlobalSettingsByInstid(instid) {
    const customConfig = await getCustomConfigByInstid(instid);
    return customConfig.globalSettings;
}
