var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{class:_vm.namespaceClass('workbook-header')},[_c('div',{class:_vm.namespaceClass('workbook-header__navigation-wrap')},[_c('div',{ref:"navContainer",class:[
                _vm.namespaceClass('workbook-header__navigation'),
                _vm.accountId
                    ? _vm.namespaceClass('workbook-header__navigation-dj')
                    : ''
            ]},[_c('markets-ui-tab-menu',{attrs:{"data-model":_vm.tabMenu,"skin":_vm.skin,"editPanel":_vm.editPanel},on:{"active-tab-changed":_vm.changeActiveWorksheetId,"add-menu-clicked":_vm.addMenuClicked,"edit-item-clicked":_vm.editItemClicked}})],1),_c('div',{class:_vm.namespaceClass('workbook-header__button-wrapper')},[(_vm.accountId)?_c('button',{staticClass:"mds-button mds-button--secondary",class:_vm.namespaceClass('workbook-header__dow-jones-button'),on:{"click":_vm.setDowJonesActive}},[_vm._v(" Dow Jones ")]):_vm._e(),_c('button',{staticClass:"mds-button",class:[
                    _vm.namespaceClass('workbook-header__edit-mode-button'),
                    _vm.activeEditMode || _vm.isEmptyWorksheet
                        ? 'mds-button--primary'
                        : 'mds-button--secondary'
                ],attrs:{"disabled":_vm.activeDowjones || !_vm.componentDragable},on:{"click":_vm.toggleEditMode}},[_vm._v(" "+_vm._s(_vm.editButtonLabel)+" ")])])]),_c('markets-ui-dialog',{attrs:{"slot":"supplemental-content","cancel":_vm.dialog.cancel,"title":_vm.dialog.title,"resolve":_vm.dialog.resolve,"visible":_vm.dialog.visible,"message":_vm.dialog.message,"skin":_vm.skin,"action-required":""},on:{"click":_vm.clickDialog},slot:"supplemental-content"},[(_vm.isRename)?_c('form',{staticClass:"mds-form mds-form--medium-layout",attrs:{"onsubmit":"return false;"}},_vm._l((_vm.dialog.inputs),function(item){return _c('div',{key:item.id,staticClass:"mds-form__field-group"},[_c('label',{staticClass:"mds-form__label",attrs:{"for":item.id}},[_vm._v(_vm._s(item.label))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(item.value),expression:"item.value"}],ref:"input",refInFor:true,staticClass:"mds-form__input",attrs:{"type":"text"},domProps:{"value":(item.value)},on:{"focus":_vm.onFocus,"input":function($event){if($event.target.composing){ return; }_vm.$set(item, "value", $event.target.value)}}}),(!!_vm.dialog.errorMsg)?_c('span',{staticClass:"mds-form__field-error",attrs:{"role":"alert"}},[_c('span',{staticClass:"mds-form__field-error-text",class:_vm.namespaceClass('error-message')},[_vm._v(" "+_vm._s(_vm.dialog.errorMsg)+" ")])]):_vm._e()])}),0):_vm._e()]),_c('markets-ui-popover',{attrs:{"triggered-by":_vm.popover.triggeredId,"width":_vm.popover.width,"visible":_vm.popover.visible,"title":_vm.popover.title,"title-hidden":false,"skin":_vm.skin},on:{"hide":_vm.hidePopover}},[_c('markets-ui-list',{attrs:{"skin":_vm.skin,"size":"medium","dataModel":_vm.popover.list},on:{"click":_vm.addWorksheet}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }