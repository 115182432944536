export async function importComponentByName(componentName) {
    let _module;
    switch (componentName) {
        case 'mwc-markets-news': {
            _module = await import(
                /* webpackChunkName: "news" */ 'mwc-markets-news'
            );
            break;
        }
        case 'mwc-markets-quote': {
            _module = await import(
                /* webpackChunkName: "quote" */ 'mwc-markets-quote'
            );
            break;
        }
        case 'mwc-markets-watchlist': {
            _module = await import(
                /* webpackChunkName: "watchlist" */ 'mwc-markets-watchlist'
            );
            break;
        }
        case 'mwc-markets-commodity': {
            _module = await import(
                /* webpackChunkName: "commodity" */ 'mwc-markets-commodity'
            );
            break;
        }
        case 'mwc-markets-forex': {
            _module = await import(
                /* webpackChunkName: "forex" */ 'mwc-markets-forex'
            );
            break;
        }
        case 'mwc-markets-gla': {
            _module = await import(
                /* webpackChunkName: "gla" */ 'mwc-markets-gla'
            );
            break;
        }
        // case 'mwc-markets-regulatory': {
        //     _module = await import(
        //         /* webpackChunkName: "regulatory" */ 'mwc-markets-regulatory'
        //     );
        //     break;
        // }
        case 'mwc-markets-research': {
            _module = await import(
                /* webpackChunkName: "research" */ 'mwc-markets-research'
            );
            break;
        }
        case 'mwc-markets-sector-heatmap': {
            _module = await import(
                /* webpackChunkName: "sector-heatmap" */ 'mwc-markets-sector-heatmap'
            );
            break;
        }
        case 'mwc-markets-treasury': {
            _module = await import(
                /* webpackChunkName: "treasury" */ 'mwc-markets-treasury'
            );
            break;
        }
        case 'mwc-markets-gmb': {
            _module = await import(/* webpackChunkName: "gmb" */ './gmb');
            break;
        }
        case 'mwc-markets-options': {
            _module = await import(
                /* webpackChunkName: "options" */ 'mwc-markets-options'
            );
            break;
        }
        case 'mwc-markets-time-sales': {
            _module = await import(
                /* webpackChunkName: "time-sales" */ 'mwc-markets-time-sales'
            );
            break;
        }
        case 'mwc-markets-barometer': {
            _module = await import(
                /* webpackChunkName: "barometer" */ 'mwc-markets-barometer'
            );
            break;
        }
        case 'mwc-markets-cryptocurrency': {
            _module = await import(
                /* webpackChunkName: "cryptocurrency" */ 'mwc-markets-cryptocurrency'
            );
            break;
        }
        case 'mwc-markets-qualitative-research': {
            _module = await import(
                /* webpackChunkName: "qualitative-research" */ './qualitative-research'
            );
            break;
        }
        case 'mwc-markets-report': {
            _module = await import(/* webpackChunkName: "report" */ './report');
            break;
        }
        case 'markets-components-svgchart': {
            _module = await import(/* webpackChunkName: "chart" */ './chart');
            break;
        }
        case 'markets-components-minichart': {
            _module = await import(
                /* webpackChunkName: "minichart" */ './minichart'
            );
            break;
        }
        case 'mwc-markets-model-portfolio': {
            _module = await import(
                /* webpackChunkName: "portfolio" */ './portfolio'
            );
            break;
        }
    }
    return _module && _module.default;
}
