import api from '../../data/api';
import utils, { extendAuthParams, trim } from '../../util';
import { getAppTrackerSingleInstance } from '../../componentsManager/tracking';

let getWatchlistPromiseResolver;
const state = {
    watchlists: [], // [{id, name, tickers, components}]
    getWatchlistPromise: new Promise(resolve => {
        getWatchlistPromiseResolver = resolve;
    })
};
const actions = {
    getWatchlists({ rootGetters, commit }) {
        const token = rootGetters['account/token'];
        const url = rootGetters['application/url']('@web/ra/watchlist');
        const promiseList = [
            api.getInitialList(url, extendAuthParams({}, token)),
            api.getWatchlists(url, extendAuthParams({}, token))
        ];
        return Promise.all(promiseList)
            .then(res => {
                const [initialList, remoteList] = res;
                const localList = [];
                initialList.forEach(item => {
                    if (item.type === 'readonly') {
                        localList.push({
                            id: item.holdingsId,
                            createdDate: item.createDate,
                            lastModifiedDate: item.lastModifiedDate,
                            name: item.name,
                            tickers: item.holdings.map(t => t.id).join(',')
                        });
                    }
                });
                const watchlists = [...localList, ...remoteList];
                const tracker = getAppTrackerSingleInstance();
                if (utils.isArray(watchlists)) {
                    // Tracking: number of watchlist per user.
                    tracker.trackEvent({
                        name: 'watchlist-count',
                        value: watchlists.length
                    });
                    watchlists.forEach(w => {
                        w.name = trim(w.name);
                        if (w.tickers && typeof w.tickers === 'string') {
                            // Tracking: number of holdings per watchlist.
                            tracker.trackEvent({
                                name: 'watchlist-tickers-count',
                                value: w.tickers.split(',').length
                            });
                        }
                    });
                }
                commit('setWatchlists', watchlists);
                getWatchlistPromiseResolver(initialList);
            })
            .catch(() => {
                getWatchlistPromiseResolver();
            });
    },
    checkHoldings({ rootGetters }, holdings) {
        const token = rootGetters['account/token'];
        const url = rootGetters['application/url'](
            '@web/extService/checkHoldings'
        );
        return api
            .checkHoldings(url, extendAuthParams({ holdings }, token))
            .then(data => {
                return data;
            });
    },
    saveWatchlists({ rootGetters, commit, state }) {
        const token = rootGetters['account/token'];
        const url = rootGetters['application/url']('@web/ra/watchlist/save');
        const watchlists = state.watchlists.filter(w => {
            if (w.id.indexOf('readonly') < 0) {
                return w.isDirty || /^default.+/.test(w.id);
            }
        });
        if (watchlists && watchlists.length > 0) {
            const toBeSaved = watchlists.map(w => {
                const { id, name, tickers } = w;
                return {
                    id,
                    name,
                    tickers
                };
            });
            return api
                .saveWatchlists(
                    url,
                    extendAuthParams(
                        { watchlists: JSON.stringify(toBeSaved) },
                        token
                    )
                )
                .then(data => {
                    const _watchlists = state.watchlists;
                    const _changedWatchlists = [];
                    data.forEach(item => {
                        const target = utils.find(_watchlists, w => {
                            return w.id === item.originalId;
                        });
                        if (!target.originalId && /^default/.test(target.id)) {
                            target.originalId = target.id;
                            _changedWatchlists.push(target);
                        }
                        target.id = item.id;
                        target.isDirty = false;
                    });
                    commit('setWatchlists', _watchlists);
                    if (_changedWatchlists.length > 0) {
                        commit(
                            'worksheet/changeWatchlistsIds',
                            _changedWatchlists,
                            { root: true }
                        );
                    }
                })
                .catch(e => {
                    return Promise.reject(e);
                });
        } else {
            return Promise.resolve();
        }
    },
    addWatchlist({ rootGetters, commit, state }, { tickers, name }) {
        const token = rootGetters['account/token'];
        const url = rootGetters['application/url']('@web/ra/watchlist/add');
        const watchlists = state.watchlists;
        return api
            .addWatchlist(
                url,
                extendAuthParams(
                    {
                        name,
                        tickers
                    },
                    token
                )
            )
            .then(data => {
                if (data) {
                    watchlists.push(data);
                    commit('setWatchlists', watchlists);
                    return data;
                } else {
                    throw new Error('create watchlist error');
                }
            });
    },
    async updateWatchlist(
        { rootGetters, commit },
        { id, tickers, name, save = true }
    ) {
        const token = rootGetters['account/token'];
        const url = rootGetters['application/url']('@web/ra/watchlist/update');
        const params = {
            id,
            tickers,
            name
        };
        for (let key in params) {
            if (!params[key]) {
                delete params[key];
            }
        }
        // save to remote by default, set it false to force it not save to remove,

        if (save && /^[0-9]+$/.test(id)) {
            return api
                .updateWatchlist(url, extendAuthParams(params, token))
                .then(data => {
                    commit('_updateWatchlist', { id, name, tickers });
                    return {
                        id,
                        name,
                        tickers
                    };
                });
        } else {
            commit('_updateWatchlist', { id, name, tickers, isDirty: true });
            return {
                id,
                name,
                tickers,
                isDirty: true
            };
        }
    },
    async deleteWatchlists({ rootGetters, commit, dispatch }, ids) {
        const token = rootGetters['account/token'];
        const url = rootGetters['application/url']('@web/ra/watchlist/delete');
        const validIds = [],
            tempIds = [];
        ids.forEach(id => {
            if (/^[0-9]+$/.test(id)) {
                validIds.push(id);
            } else {
                tempIds.push(id);
            }
        });
        const deleteFn = () => {
            commit('worksheet/deleteWatchlistsFromWorkbook', ids, {
                root: true
            });
            commit('_deleteWatchlists', ids);
            let name = ids.length > 1 ? 'watchlists' : 'watchlist';
            let msg = `The ${name} have been deleted successfully.`;
            commit(
                'application/setNotification',
                {
                    visible: true,
                    type: 'success',
                    message: msg,
                    dismissible: true
                },
                { root: true }
            );
        };
        if (validIds.length > 0) {
            return api
                .deleteWatchlists(
                    url,
                    extendAuthParams({ ids: validIds.join(',') }, token)
                )
                .then(() => {
                    deleteFn();
                })
                .catch(() => {
                    commit(
                        'application/setNotification',
                        {
                            visible: true,
                            type: 'error',
                            message: 'There was an error while deleting.',
                            dismissible: true
                        },
                        { root: true }
                    );
                });
        } else {
            deleteFn();
        }
    }
};

const mutations = {
    setWatchlists(state, watchlists) {
        state.watchlists = watchlists;
    },
    _updateWatchlist(state, watchlist) {
        const watchlists = state.watchlists;
        const target = utils.find(watchlists, w => {
            return w.id === watchlist.id;
        });
        if (target) {
            [
                'name',
                'tickers',
                'isDirty',
                'createdDate',
                'lastModifiedDate'
            ].forEach(key => {
                if (watchlist[key] !== undefined) {
                    target[key] = watchlist[key];
                }
            });
        } else {
            watchlists.push({
                id: watchlist.id,
                name: watchlist.name,
                tickers: watchlist.tickers
            });
        }
    },
    _deleteWatchlists(state, ids) {
        const watchlists = state.watchlists;
        ids.forEach(id => {
            const target = utils.findIndex(watchlists, w => {
                return w.id === id;
            });
            if (target > -1) {
                watchlists.splice(target, 1);
            }
        });
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
};
