<template>
    <iframe v-if="src" :class="namespaceClass('worksheet-iframe')" :src="src" />
</template>
<script>
import { mapGetters } from 'vuex';
export default {
    name: 'Dowjones',
    computed: {
        ...mapGetters('account', ['token']),
        ...mapGetters('application', ['url'])
    },
    created() {
        if (this.token.instid && this.token.sessionId) {
            this.src = this.url(
                `@web/auth/dowjones/sso?instid=${this.token.instid}&qs_wsid=${this.token.sessionId}`
            );
        }
    }
};
</script>
