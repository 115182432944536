<template>
    <section :class="namespaceClass('workbook-bodyer')">
        <Worksheet />
    </section>
</template>

<script>
import Worksheet from '../worksheet/Worksheet';
export default {
    name: 'WorkbookBodyer',
    components: {
        Worksheet
    }
};
</script>
