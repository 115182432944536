<template>
    <section :class="namespaceClass('workbook-header')">
        <div :class="namespaceClass('workbook-header__navigation-wrap')">
            <div
                :class="[
                    namespaceClass('workbook-header__navigation'),
                    accountId
                        ? namespaceClass('workbook-header__navigation-dj')
                        : ''
                ]"
                ref="navContainer"
            >
                <markets-ui-tab-menu
                    :data-model="tabMenu"
                    :skin="skin"
                    :editPanel="editPanel"
                    @active-tab-changed="changeActiveWorksheetId"
                    @add-menu-clicked="addMenuClicked"
                    @edit-item-clicked="editItemClicked"
                ></markets-ui-tab-menu>
            </div>
            <div :class="namespaceClass('workbook-header__button-wrapper')">
                <button
                    v-if="accountId"
                    class="mds-button mds-button--secondary"
                    :class="namespaceClass('workbook-header__dow-jones-button')"
                    @click="setDowJonesActive"
                >
                    Dow Jones
                </button>
                <button
                    class="mds-button"
                    :disabled="activeDowjones || !componentDragable"
                    :class="[
                        namespaceClass('workbook-header__edit-mode-button'),
                        activeEditMode || isEmptyWorksheet
                            ? 'mds-button--primary'
                            : 'mds-button--secondary'
                    ]"
                    @click="toggleEditMode"
                >
                    {{ editButtonLabel }}
                </button>
            </div>
        </div>
        <markets-ui-dialog
            @click="clickDialog"
            :cancel="dialog.cancel"
            :title="dialog.title"
            :resolve="dialog.resolve"
            :visible="dialog.visible"
            :message="dialog.message"
            :skin="skin"
            slot="supplemental-content"
            action-required
        >
            <form
                class="mds-form mds-form--medium-layout"
                onsubmit="return false;"
                v-if="isRename"
            >
                <div
                    class="mds-form__field-group"
                    v-for="item in dialog.inputs"
                    :key="item.id"
                >
                    <label class="mds-form__label" :for="item.id">{{
                        item.label
                    }}</label>
                    <input
                        class="mds-form__input"
                        type="text"
                        v-model="item.value"
                        @focus="onFocus"
                        ref="input"
                    />
                    <span
                        class="mds-form__field-error"
                        role="alert"
                        v-if="!!dialog.errorMsg"
                    >
                        <span
                            class="mds-form__field-error-text"
                            :class="namespaceClass('error-message')"
                        >
                            {{ dialog.errorMsg }}
                        </span>
                    </span>
                </div>
            </form>
        </markets-ui-dialog>
        <markets-ui-popover
            :triggered-by="popover.triggeredId"
            :width="popover.width"
            :visible="popover.visible"
            :title="popover.title"
            :title-hidden="false"
            :skin="skin"
            @hide="hidePopover"
        >
            <markets-ui-list
                :skin="skin"
                size="medium"
                :dataModel="popover.list"
                @click="addWorksheet"
            >
            </markets-ui-list>
        </markets-ui-popover>
    </section>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import utils, { trim } from '../../util/';
import {
    generateName,
    validateInput,
    updateComponentState
} from '../../common/';

export default {
    name: 'WorkbookHeader',
    computed: {
        ...mapState('worksheet', [
            'worksheets',
            'activeWorksheetId',
            'activeDowjones'
        ]),
        ...mapGetters('worksheet', [
            'isEmptyWorksheet',
            'componentDragable',
            'activeEditMode'
        ]),
        ...mapGetters('settings', ['skin']),
        ...mapState('settings', ['accountId']), // account Id means user enabled dowjones news
        tabMenu() {
            return this.worksheets.map(ws => {
                return {
                    isActive:
                        ws.id === this.activeWorksheetId ||
                        ws.originalId === this.activeWorksheetId,
                    name: ws.name,
                    id: ws.id,
                    editable: ws.profile.editable === false ? false : true,
                    visible: ws.profile.visible
                };
            });
        },
        isRename() {
            return this.dialog.actionInfo.action === 'rename';
        },
        isDelete() {
            return this.dialog.actionInfo.action === 'delete';
        },
        isWarning() {
            return this.dialog.actionInfo.action === 'warning';
        },
        editButtonLabel() {
            return this.activeEditMode || this.isEmptyWorksheet
                ? 'Done'
                : 'Edit';
        }
    },
    data() {
        return {
            dialog: {
                visible: false,
                errorMsg: '',
                message: '',
                title: '',
                inputs: [],
                reject: {},
                actionInfo: {
                    action: ''
                },
                cancel: {
                    label: 'Cancel'
                },
                resolve: {
                    label: 'Save'
                }
            },
            popover: {
                triggeredId: null,
                visible: false,
                title: 'Add Worksheet',
                list: []
            },
            editPanel: {
                width: 200,
                title: 'Edit Worksheet',
                list: [
                    {
                        id: 'rename',
                        name: 'Rename',
                        click: true
                    },
                    {
                        id: 'delete',
                        name: 'Delete',
                        click: true
                    }
                ]
            }
        };
    },
    watch: {
        'dialog.visible'() {
            this.dialog.errorMsg = '';
        },
        activeWorksheetId(newWorksheetId) {
            const worksheet = utils.find(
                this.worksheets,
                s => s.id === newWorksheetId
            );
            if (worksheet) {
                // Tracking: number of usage per worksheet.
                this.$appTracker.trackEvent({
                    name: 'worksheet-select',
                    value: worksheet.name
                });
            }
        }
    },
    created() {
        this.getTemplateWorksheets().then(predefinedWorksheets => {
            this.predefinedWorksheets = predefinedWorksheets;
            const templates = predefinedWorksheets.map(ws => {
                const { id, name } = ws;
                return {
                    id,
                    name,
                    click: true
                };
            });
            this.popover.list = [
                {
                    id: 'new',
                    name: 'New',
                    click: true
                },
                ...templates
            ];
        });
        this.MAX_COUNT = 10;
    },
    methods: {
        ...mapMutations('worksheet', [
            'toggleEditMode',
            'setActiveWorksheetId',
            'setFromToViewStatus',
            'setDowJonesActive'
        ]),
        ...mapActions('worksheet', [
            'getTemplateWorksheets',
            'deleteWorksheet',
            'updateWorksheet'
        ]),
        ...mapMutations('application', ['setNotification', 'setLoading']),
        addMenuClicked(e) {
            this.popover.visible = true;
            this.popover.triggeredId = utils.computeTriggerId(e.currentTarget);
            this.popover.visible = true;
        },
        changeActiveWorksheetId(id) {
            this.setActiveWorksheetId(id);
            this.setFromToViewStatus(false);
        },
        addWorksheet(worksheet) {
            // Tracking: number of time user create template worksheet.
            this.$appTracker.trackEvent({
                name: 'new-worksheet-add',
                value: worksheet.name
            });
            this.popover.visible = false;
            if (this.worksheets.length >= this.MAX_COUNT) {
                utils.extend(true, this.dialog, {
                    visible: true,
                    title: 'Warning!',
                    resolve: {
                        label: 'OK'
                    },
                    actionInfo: {
                        action: 'warning'
                    },
                    message:
                        'Sorry, you have reached the maximum worksheet limit. You need to delete a worksheet before creating a new one.'
                });
                this.$set(this.dialog, 'cancel', {});
                return;
            }
            const profile = {
                visible: true,
                isActive: true,
                componentDragable: true
            };
            const prefix = worksheet.id === 'new' ? 'Untitled' : worksheet.name;
            const newWorksheet = {
                name: generateName(prefix, this.worksheets, true),
                id: `default-${utils.guid()}`
            };
            if (worksheet.id === 'new') {
                profile.layoutConfig = [];
            } else {
                const targetWorksheet = utils.extend(
                    true,
                    {},
                    utils.find(this.predefinedWorksheets, item => {
                        return item.id === worksheet.id;
                    })
                );
                updateComponentState(
                    'add',
                    targetWorksheet.profile.layoutConfig
                );
                utils.extend(true, profile, targetWorksheet.profile);
            }
            newWorksheet.profile = profile;
            this.updateWorksheet(newWorksheet);
            this.setActiveWorksheetId(newWorksheet.id);
        },
        editItemClicked(action, target) {
            this.dialog.visible = true;
            this.dialog.actionInfo = {
                action,
                target
            };
            if (action === 'delete') {
                utils.extend(true, this.dialog, {
                    message: `Do you want to delete "${target.name}" permanently?`,
                    resolve: {
                        label: 'Delete'
                    },
                    title: ''
                });
                this.$set(this.dialog, 'cancel', {
                    label: 'Cancel'
                });
            } else if (action === 'rename') {
                utils.extend(true, this.dialog, {
                    inputs: [
                        {
                            id: 'rename',
                            label: 'Worksheet Name',
                            value: target.name
                        }
                    ],
                    resolve: {
                        label: 'Save'
                    },
                    title: '',
                    message: 'Please enter a name for the worksheet.'
                });
                this.$set(this.dialog, 'cancel', {
                    label: 'Cancel'
                });
                this.$nextTick(() => {
                    this.$refs['input'][0].focus();
                });
            }
        },
        onFocus(e) {
            e.target.select();
        },
        clickDialog(type) {
            if (type === 'resolve') {
                if (this.isDelete) {
                    this._deleteWorksheet();
                } else if (this.isRename) {
                    this._renameWorksheet();
                } else {
                    this.dialog.visible = false;
                }
            } else if (type === 'cancel') {
                this.dialog.visible = false;
            }
        },
        hidePopover() {
            this.popover.visible = false;
        },
        _deleteWorksheet() {
            const target = this._getActionTarget();
            this.dialog.visible = false;
            this.setLoading(true);
            let name = target.name;
            const deleteFn = () => {
                return this.deleteWorksheet(target.id)
                    .then(() => {
                        this.setNotification({
                            type: 'success',
                            message: `Successfully deleted "${name}".`,
                            visible: true,
                            dismissible: true,
                            dismissAutoly: 2000
                        });
                        this.setLoading(false);
                    })
                    .catch(() => {
                        this.setNotification({
                            type: 'error',
                            message: `There was an error deleting the "${name}". Please try again or contact support.`,
                            visible: true,
                            dismissible: true,
                            dismissAutoly: 0
                        });
                        this.setLoading(false);
                    });
            };
            this.$requestManager.add(deleteFn, 'deleteWorksheet');
        },
        _renameWorksheet() {
            const target = this._getActionTarget();
            const worksheet = utils.extend(
                true,
                {},
                this.worksheets[target.index]
            );
            const name = trim(
                utils.find(this.dialog.inputs, item => {
                    return item.id === 'rename';
                }).value
            );

            if (this._validateName(name)) {
                worksheet.name = name;
                this.updateWorksheet(worksheet);
                this.dialog.visible = false;
            }
        },
        _validateName(name) {
            let errorMsg = '';
            if (name === '') {
                /* eslint-disable */

                errorMsg = "Your worksheet name can't be blank.";
                /* eslint-enable */
            } else if (name.length >= 60) {
                /* eslint-disable */

                errorMsg = "You've reached the 60-character limit.";
                /* eslint-enable */
            } else if (!validateInput(name)) {
                errorMsg =
                    'Your worksheet names can only include letters, numbers, and the following special characters: / \\ ( ) & . - + _ :`';
            } else if (this.worksheets.some(item => item.name === name)) {
                errorMsg =
                    'You already have a worksheet with this name. Please enter a different name.';
            }
            this.dialog.errorMsg = errorMsg;
            return !errorMsg;
        },
        _getActionTarget() {
            const target = utils.extend(
                true,
                {},
                this.dialog.actionInfo.target
            );
            target.index = utils.findIndex(this.worksheets, item => {
                return item.id === target.id;
            });
            return target;
        }
    }
};
</script>
