export const WILM_WORKSHEET_TEMPLATE = [
    {
        id: 'default-7',
        name: 'Wilmington Trust',
        isTemplate: true,
        profile: {
            isVisible: true,
            editable: true,
            reorderEnabled: true,
            showCloseIcon: true,
            componentDragable: true,
            layoutConfig: [
                {
                    isClosable: true,
                    showCloseIcon: false,
                    type: 'row',
                    title: '',
                    content: [
                        {
                            isClosable: true,
                            width: 50,
                            showCloseIcon: false,
                            type: 'column',
                            title: '',
                            content: [
                                {
                                    isClosable: true,
                                    activeItemIndex: 0,
                                    width: 50,
                                    showCloseIcon: false,
                                    type: 'stack',
                                    title: '',
                                    content: [
                                        {
                                            componentType:
                                                'mwc-markets-watchlist',
                                            componentSettings: {
                                                columnType: 'basic',
                                                dataPoints: '',
                                                enableGroup: false
                                            },
                                            watchlistType: 'static',
                                            componentName:
                                                'mwc-markets-watchlist-market-index',
                                            type: 'component',
                                            componentState: {}
                                        },
                                        {
                                            componentType:
                                                'mwc-markets-watchlist',
                                            componentSettings: {},
                                            watchlistType: 'static',
                                            componentName:
                                                'mwc-markets-watchlist-pre-market-futures',
                                            type: 'component',
                                            componentState: {}
                                        },
                                        {
                                            componentType: 'mwc-markets-gla',
                                            componentSettings: {},
                                            componentName: 'mwc-markets-gla',
                                            type: 'component',
                                            componentState: {}
                                        }
                                    ],
                                    height: 50
                                },
                                {
                                    isClosable: true,
                                    activeItemIndex: 0,
                                    showCloseIcon: false,
                                    type: 'stack',
                                    title: '',
                                    content: [
                                        {
                                            componentType:
                                                'mwc-markets-treasury',
                                            componentSettings: {},
                                            componentName:
                                                'mwc-markets-treasury',
                                            type: 'component',
                                            componentState: {}
                                        },
                                        {
                                            componentType:
                                                'mwc-markets-commodity',
                                            componentSettings: {},
                                            aliasComponentName:
                                                'morningstar-commodities-watchlist',
                                            componentName:
                                                'mwc-markets-commodity',
                                            type: 'component',
                                            componentState: {}
                                        },
                                        {
                                            componentType: 'mwc-markets-forex',
                                            componentSettings: {},
                                            componentName: 'mwc-markets-forex',
                                            type: 'component',
                                            componentState: {}
                                        },
                                        {
                                            componentType:
                                                'mwc-markets-cryptocurrency',
                                            componentSettings: {},
                                            componentName:
                                                'mwc-markets-cryptocurrency',
                                            type: 'component',
                                            componentState: {}
                                        }
                                    ],
                                    height: 50
                                }
                            ]
                        },
                        {
                            isClosable: true,
                            width: 50,
                            showCloseIcon: false,
                            type: 'column',
                            title: '',
                            content: [
                                {
                                    isClosable: true,
                                    activeItemIndex: 0,
                                    width: 50,
                                    showCloseIcon: false,
                                    type: 'stack',
                                    title: '',
                                    content: [
                                        {
                                            componentType:
                                                'mwc-markets-sector-heatmap',
                                            componentSettings: {},
                                            componentName:
                                                'mwc-markets-sector-heatmap',
                                            type: 'component',
                                            componentState: {}
                                        },
                                        {
                                            componentType: 'mwc-markets-gmb',
                                            componentSettings: {},
                                            componentName: 'mwc-markets-gmb',
                                            type: 'component',
                                            componentState: {}
                                        }
                                    ],
                                    height: 50
                                },
                                {
                                    isClosable: true,
                                    activeItemIndex: 0,
                                    showCloseIcon: false,
                                    type: 'stack',
                                    title: '',
                                    content: [
                                        {
                                            componentType:
                                                'mwc-markets-watchlist',
                                            componentSettings: {
                                                columnType: 'returns',
                                                dataPoints: '',
                                                enableGroup: true,
                                                groupKey: 'MSCategory'
                                            },
                                            watchlistType: 'static',
                                            componentName:
                                                'mwc-markets-watchlist-wt-passive-funds',
                                            type: 'component',
                                            componentState: {}
                                        },
                                        {
                                            componentType:
                                                'mwc-markets-watchlist',
                                            componentSettings: {
                                                columnType: 'returns',
                                                dataPoints: '',
                                                enableGroup: true,
                                                groupKey: 'MSCategory'
                                            },
                                            watchlistType: 'static',
                                            componentName:
                                                'mwc-markets-watchlist-wt-active-funds',
                                            type: 'component',
                                            componentState: {}
                                        },
                                        {
                                            componentType:
                                                'mwc-markets-watchlist',
                                            componentSettings: {
                                                columnType: 'returns',
                                                dataPoints: '',
                                                enableGroup: false
                                            },
                                            watchlistType: 'static',
                                            componentName:
                                                'mwc-markets-watchlist-wt-edis',
                                            type: 'component',
                                            componentState: {}
                                        },
                                        {
                                            componentType:
                                                'mwc-markets-watchlist',
                                            componentSettings: {
                                                columnType: 'returns',
                                                dataPoints: '',
                                                enableGroup: false
                                            },
                                            watchlistType: 'static',
                                            componentName:
                                                'mwc-markets-watchlist-wt-edis-mlps',
                                            type: 'component',
                                            componentState: {}
                                        },
                                        {
                                            componentType:
                                                'mwc-markets-watchlist',
                                            componentSettings: {
                                                columnType: 'returns',
                                                dataPoints: '',
                                                enableGroup: false
                                            },
                                            watchlistType: 'static',
                                            componentName:
                                                'mwc-markets-watchlist-wt-international',
                                            type: 'component',
                                            componentState: {}
                                        },
                                        {
                                            componentType:
                                                'mwc-markets-watchlist',
                                            componentSettings: {
                                                columnType: 'returns',
                                                dataPoints: '',
                                                enableGroup: false
                                            },
                                            watchlistType: 'static',
                                            componentName:
                                                'mwc-markets-watchlist-wt-discipline-core',
                                            type: 'component',
                                            componentState: {}
                                        },
                                        {
                                            componentType:
                                                'mwc-markets-watchlist',
                                            componentSettings: {
                                                columnType: 'returns',
                                                dataPoints: '',
                                                enableGroup: false
                                            },
                                            watchlistType: 'static',
                                            componentName:
                                                'mwc-markets-watchlist-wt-dividend-growth',
                                            type: 'component',
                                            componentState: {}
                                        },
                                        {
                                            componentType:
                                                'mwc-markets-watchlist',
                                            componentSettings: {
                                                columnType: 'returns',
                                                dataPoints: '',
                                                enableGroup: false
                                            },
                                            watchlistType: 'static',
                                            componentName:
                                                'mwc-markets-watchlist-wt-esg',
                                            type: 'component',
                                            componentState: {}
                                        },
                                        {
                                            componentType:
                                                'mwc-markets-watchlist',
                                            componentSettings: {
                                                columnType: 'returns',
                                                dataPoints: '',
                                                enableGroup: false
                                            },
                                            watchlistType: 'static',
                                            componentName:
                                                'mwc-markets-watchlist-wt-fire',
                                            type: 'component',
                                            componentState: {}
                                        },
                                        {
                                            componentType:
                                                'mwc-markets-watchlist',
                                            componentSettings: {
                                                columnType: 'returns',
                                                dataPoints: '',
                                                enableGroup: false
                                            },
                                            watchlistType: 'static',
                                            componentName:
                                                'mwc-markets-watchlist-wt-caps',
                                            type: 'component',
                                            componentState: {}
                                        }
                                    ],
                                    height: 50
                                }
                            ]
                        }
                    ]
                }
            ]
        }
    }
];
