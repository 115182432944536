<template>
    <section :class="namespaceClass('header')">
        <header class="mds-masthead">
            <div
                v-show="navigation.visible"
                :class="namespaceClass('navigation')"
                @mouseover="menuButtonEnter"
                @mouseout="menuButtonLeave"
            >
                <markets-ui-list
                    :skin="skin"
                    size="medium"
                    :dataModel="navigation.data"
                    @click="clickNavigation"
                ></markets-ui-list>
            </div>
            <div class="mds-masthead__inner">
                <div class="mds-masthead__left">
                    <button
                        :class="namespaceClass('header__menu')"
                        class="mds-button mds-button--icon-only mds-masthead--hide-wide mds-page-shell--show-off-canvas-navigation"
                        @mouseover="menuButtonEnter"
                        @mouseout="menuButtonLeave"
                        @touchstart="menuButtonEnter"
                    >
                        <svg
                            class="mds-icon mds-button__icon mds-button__icon--left"
                            aria-hidden="true"
                        >
                            <use :xlink:href="mdsIcon('menu-padless')"></use>
                        </svg>
                    </button>
                    <button
                        :class="namespaceClass('header__menu')"
                        ref="mainMenu"
                        class="mds-button mds-button--flat mds-button--flat-icon-m mds-masthead--show-wide mds-page-shell--show-off-canvas-navigation"
                        @mouseover="menuButtonEnter"
                        @mouseout="menuButtonLeave"
                        @touchstart="menuButtonEnter"
                    >
                        <svg
                            class="mds-icon mds-button__icon mds-button__icon--left"
                            aria-hidden="true"
                        >
                            <use :xlink:href="mdsIcon('menu-padless')"></use>
                        </svg>
                        <span class="mds-button__text"> Menu </span>
                    </button>
                </div>
                <div class="mds-masthead__logo-wrap">
                    <img
                        class="mds-masthead__logo--wide"
                        src="../assets/images/Mstar-logo-50px.svg"
                        alt="Morningstar markets monitoring"
                    />
                    <img
                        class="mds-masthead__logo"
                        src="../assets/images/Mstar-logo-50px.svg"
                        alt="Morningstar markets monitoring"
                    />
                </div>
                <div class="mds-masthead__right">
                    <div
                        :class="namespaceClass('workbook-header__autocomplete')"
                    >
                        <div class="mds-search-field" style="width: 200px;">
                            <input
                                type="text"
                                class="mds-search-field__input"
                                autocomplete="off"
                                id="globalAutocomplete"
                                aria-label="Search"
                                ref="globalAutocomplete"
                                @input="clearError"
                                @focus="clearError"
                            />
                            <svg
                                class="mds-icon mds-search-field__search-icon"
                                aria-hidden="true"
                            >
                                <use :xlink:href="mdsIcon('search--s')"></use>
                            </svg>
                            <span
                                class="mds-search-field__input-focus-outline"
                            ></span>
                        </div>
                    </div>
                    <button
                        class="mds-button mds-button--icon-only"
                        type="button"
                        title="Help"
                        ref="helpButton"
                        @click="helpClicked($event)"
                    >
                        <svg
                            class="mds-icon mds-button__icon mds-button__icon--left"
                            aria-hidden="true"
                        >
                            <use :xlink:href="mdsIcon('question-circle')">
                                <title>Help</title>
                            </use>
                        </svg>
                    </button>
                    <button
                        class="mds-button mds-button--icon-only"
                        type="button"
                        ref="bell"
                        title="Alerts"
                        @click="bellClicked($event)"
                    >
                        <span :class="countClass" v-text="alertCount"></span>
                        <svg
                            class="mds-icon mds-button__icon mds-button__icon--left"
                            aria-hidden="true"
                        >
                            <use :xlink:href="mdsIcon('bell-padless')">
                                <title>Alerts</title>
                            </use>
                        </svg>
                    </button>
                    <button
                        class="mds-button mds-button--icon-only"
                        v-if="showVideo"
                        type="button"
                        title="Video"
                        @click="videoClicked"
                    >
                        <svg
                            class="mds-icon mds-button__icon mds-button__icon--left"
                            aria-hidden="true"
                        >
                            <use :xlink:href="mdsIcon('play-circle')">
                                <title>Video</title>
                            </use>
                        </svg>
                    </button>
                    <button
                        :class="[
                            namespaceClass('button__save'),
                            'mds-button mds-button--icon-only'
                        ]"
                        type="button"
                        title="Save"
                        @click="saveButtonClicked"
                    >
                        <svg
                            class="mds-icon mds-button__icon mds-button__icon--left"
                            version="1.1"
                            x="0px"
                            y="0px"
                            viewBox="0 0 1000 1000"
                            enable-background="new 0 0 1000 1000"
                            xml:space="preserve"
                            title="Save"
                        >
                            <g>
                                <path
                                    d="M930.1,990.5H69.9c-33.1,0-59.9-26.9-59.9-59.9V69.5C10,36.4,36.9,9.5,69.9,9.5h700.7L990,221v709.5C990,
                                    963.6,963.1,990.5,930.1,990.5z M81.5,61c-11,0-20,9-20,20v837.9c0,11,9,20,20,20h837.1c11,0,20-8.9,20-20V237.6L751.4,61H81.5z"
                                ></path>
                                <path
                                    d="M732,473.8H165V9.5h567V473.8z M216.5,422.3h464V61h-464V422.3z"
                                ></path>
                                <path
                                    d="M627.9,344.7h-50.7V87.5h50.7V344.7z"
                                ></path>
                                <path
                                    d="M737.5,680.2H183.4c-11.1,0-20-13.8-20-24.8c0-11.1,8.9-26.4,20-26.4h554.1c11,0,20,15.4,20,26.4C757.5,666.4,748.5,680.2,737.5,680.2z"
                                ></path>
                                <path
                                    d="M737.5,783.3H183.4c-11.1,0-20-13.8-20-24.8c0-11,8.9-26.4,20-26.4h554.1c11,0,20,15.4,20,26.4C757.5,769.5,748.5,783.3,737.5,783.3z"
                                ></path>
                                <path
                                    d="M737.5,886.4H183.4c-11.1,0-20-13.8-20-24.8c0-11,8.9-26.4,20-26.4h554.1c11,0,20,15.4,20,26.4C757.5,872.6,748.5,886.4,737.5,886.4z"
                                ></path>
                            </g>
                        </svg>
                    </button>
                    <button
                        class="mds-button mds-button--icon-only"
                        type="button"
                        title="Account"
                        data-mds-popover="mds-popover--account"
                        @click="accountClicked"
                        ref="accountButton"
                    >
                        <svg
                            class="mds-icon mds-button__icon mds-button__icon--left"
                            aria-hidden="true"
                        >
                            <use :xlink:href="mdsIcon('person-padless')">
                                <title>Account</title>
                            </use>
                        </svg>
                    </button>
                    <markets-ui-settings
                        :triggered-by="settingsTriggeredId"
                        :skin="skin"
                        :visible="settingsVisible"
                        :title="settingsTitle"
                        @hide="hideSettingsPopover"
                    >
                        <markets-ui-list
                            :skin="skin"
                            size="medium"
                            :data-model="settingsData"
                            @click="settingsClicked"
                            slot="content"
                        ></markets-ui-list>
                    </markets-ui-settings>
                </div>
            </div>
            <markets-ui-dialog
                :class="namespaceClass('save-dialog')"
                @click="dialogResponsed"
                :cancel="saveDialog.cancel"
                :resolve="saveDialog.resolve"
                :visible="saveDialog.visible"
                :reject="saveDialog.reject"
                :message="saveDialog.message"
                :skin="skin"
                action-required
            >
            </markets-ui-dialog>
        </header>
        <WatchlistManager v-if="watchlistManagerClicked" />
        <AlertManager
            @unread-count-changed="unreadCountChanged"
            ref="alertManager"
        ></AlertManager>
        <ExcelRTD v-if="excelRTDClicked"></ExcelRTD>
        <ImportManager
            v-if="importVisible"
            @close="closeImportManager"
            @import-complete="importComplete"
        >
        </ImportManager>
        <ContactSupportForm v-if="contactSupportClicked"></ContactSupportForm>
    </section>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex';
import { getSaveDialog, download } from '../common';
import AlertManager from './workbook/AlertManager.vue';
import utils, {
    getOpenWindowObject,
    guid,
    getWorksheetSecurityId
} from '../util/';
export default {
    name: 'Header',
    data() {
        return {
            settingsVisible: false,
            settingsTitle: '',
            saveDialog: {},
            watchlistManagerClicked: false,
            importVisible: false,
            saveHoldingsId: '',
            settingsTriggeredId: null,
            alertManagerVisible: false,
            excelRTDClicked: false,
            contactSupportClicked: false,
            alertCount: 0,
            settingsData: [
                {
                    id: 'globalSettings',
                    name: 'Global Settings',
                    click: true
                },
                {
                    id: 'signOut',
                    name: 'Sign Out',
                    click: true
                }
            ]
        };
    },
    components: {
        AlertManager,
        WatchlistManager: () =>
            import(
                /* webpackChunkName: "watchlist-manager" */ './workbook/WatchlistManager.vue'
            ),
        ExcelRTD: () =>
            import(
                /* webpackChunkName: "excel-rtd" */ './workbook/ExcelRTDDownload.vue'
            ),
        ImportManager: () =>
            import(
                /* webpackChunkName: "import-manager" */ './workbook/ImportManager.vue'
            ),
        ContactSupportForm: () =>
            import(
                /* webpackChunkName: "contact-support" */ './workbook/ContactSupportForm.vue'
            )
    },
    computed: {
        ...mapState('application', ['paths', 'frequency']),
        ...mapGetters('account', ['token']),
        ...mapGetters('settings', ['skin']),
        ...mapState('settings', ['showVideo', 'userGuide']),
        ...mapState('workbook', ['isDirty']),
        ...mapGetters('workbook', ['navigation']),
        countClass() {
            const cls = [this.namespaceClass('count')];
            if (this.alertCount) {
                if (this.alertCount < 10) {
                    cls.push(this.namespaceClass('count__small'));
                } else if (this.alertCount > 100) {
                    cls.push(this.namespaceClass('count__big'));
                }
            } else {
                cls.push(this.namespaceClass('count__hidden'));
            }
            return cls;
        }
    },
    watch: {
        skin(value) {
            if (this.autocomplete) {
                const skin = value === 'dark' ? 'dark' : 'default';
                this.autocomplete.skin = skin;
                this.setAccountSkin(skin);
                if (this.openWindow) {
                    this.openWindow.changeVideoSkin(value);
                }
            }
        }
    },
    created() {
        this.searchCache = {};
        this.saveDialog = getSaveDialog();
        this.$eventBus.$on(this.$EVENTS.SHOW_EXCELRTD, () => {
            this.excelRTDClicked = true;
        });
        this.$eventBus.$on(this.$EVENTS.SHOW_IMPORTFILE, componentId => {
            this.importVisible = true;
            if (componentId) {
                this.saveHoldingsId = componentId;
            }
        });
        this.$eventBus.$on(this.$EVENTS.SHOW_WATCHLISTMANGER, () => {
            this.watchlistManagerClicked = true;
        });
        this.$eventBus.$on(this.$EVENTS.TOKEN_EXPIRED, () => {
            if (this.openWindow) {
                this.openWindow.close();
            }
        });
    },
    mounted() {
        const el = document.createElement('mwc-markets-autocomplete');
        this.tokens = {
            env: this.token.env,
            instid: this.token.instid,
            sessionId: this.token.sessionId,
            overridePaths: this.paths,
            frequency: this.frequency
        };
        const autocompleteSetting = {
            settings: utils.extend(
                true,
                {
                    skin: this.theme,
                    securities: [
                        'stock',
                        'ETF',
                        'CEF',
                        'index',
                        'mutualFund',
                        'option'
                    ]
                },
                this.tokens
            )
        };
        el.config = autocompleteSetting;
        el.setAttribute('mwc-id', guid());
        this.$el.appendChild(el);
        this.$nextTick(() => {
            this.autocomplete = document.querySelector(
                'mwc-markets-autocomplete'
            );
            this._registerAutcomplete();
        });
    },
    methods: {
        ...mapMutations('settings', ['setEditStatus', 'setSettings']),
        ...mapMutations('application', [
            'setSaveDialogVisibility',
            'setNotification'
        ]),
        ...mapMutations('workbook', ['setNavMenuStatus']),
        ...mapMutations('account', ['setAccountSkin']),
        ...mapMutations('worksheet', [
            'setActiveWorksheetId',
            'setWorksheetSecurity'
        ]),
        ...mapActions('account', ['signout']),
        ...mapActions('application', ['saveProfile']),
        _registerAutcomplete() {
            if (this.autocomplete.register) {
                this.autocomplete.register('globalAutocomplete', res => {
                    const id = getWorksheetSecurityId(res);
                    if (!id) {
                        const errorMessage = {
                            visible: true,
                            type: 'error',
                            message:
                                'The symbol is invalid. Please try again and select from the list.',
                            dismissible: true
                        };
                        this.invalidTickerError = true;
                        this.setNotification(errorMessage);
                    } else {
                        this.setWorksheetSecurity({
                            id,
                            mstarSymbol: res.symbol
                        });
                    }
                });
            } else {
                setTimeout(() => {
                    this._registerAutcomplete();
                }, 100);
            }
        },
        clearError() {
            if (this.invalidTickerError) {
                this.setNotification({
                    visible: false
                });
                this.invalidTickerError = false;
            }
        },
        accountClicked(e) {
            this.settingsVisible = true;
            this.settingsTriggeredId = utils.computeTriggerId(e.target);
            this.settingsTitle = 'Account';
            this.settingsData = [
                {
                    id: 'globalSettings',
                    name: 'Global Settings',
                    click: true
                },
                {
                    id: 'signOut',
                    name: 'Sign Out',
                    click: true
                }
            ];
        },
        helpClicked(e) {
            this.settingsVisible = true;
            this.settingsTriggeredId = utils.computeTriggerId(e.target);
            this.settingsTitle = 'Help';
            this.settingsData = [
                {
                    id: 'userGuide',
                    name: 'User Guide',
                    click: true
                },
                {
                    id: 'support',
                    name: 'Contact Support',
                    click: true
                }
            ];
        },
        hideSettingsPopover() {
            this.settingsVisible = false;
        },
        closeImportManager() {
            this.importVisible = false;
            if (!this.saveHoldingsId) {
                this.$eventBus.$emit(this.$EVENTS.SHOW_WATCHLISTMANGER);
            }
            this.saveHoldingsId = null;
        },
        settingsClicked(item) {
            if (item.id === 'signOut') {
                this.signoutClicked();
            } else if (item.id === 'globalSettings') {
                this.openGlobalSettingDialog();
            } else if (item.id === 'support') {
                this.contactSupportClicked = true;
                this.$eventBus.$emit(this.$EVENTS.SHOW_CONTACT_SUPPORT);
            } else if (item.id === 'userGuide') {
                download(this.userGuide);
            }
            this.settingsVisible = false;
        },
        signoutClicked() {
            if (this.isDirty) {
                this.saveDialog.visible = true;
            } else {
                this.saveDialog.visible = false;
                window.onbeforeunload = undefined;
                this.$nextTick(() => {
                    this.$eventBus.$emit(this.$EVENTS.TOKEN_EXPIRED);
                    this.signout();
                });
            }
        },
        dialogResponsed(data) {
            this.saveDialog.visible = false;
            if (data === 'resolve') {
                this.$requestManager.add(() => {
                    return this.saveProfile(true).then(() => {
                        this.$eventBus.$emit(this.$EVENTS.TOKEN_EXPIRED);
                        this.signout();
                    });
                }, 'saveProfile');
            } else if (data === 'reject') {
                window.onbeforeunload = undefined;
                this.$nextTick(() => {
                    this.$eventBus.$emit(this.$EVENTS.TOKEN_EXPIRED);
                    this.signout();
                });
            }
        },
        openGlobalSettingDialog() {
            this.settingsVisible = false;
            this.setEditStatus(true);
        },
        saveButtonClicked() {
            this.$requestManager.add(
                () => this.saveProfile(true),
                'saveProfile'
            );
        },
        menuButtonEnter() {
            this.setNavMenuStatus(true);
            this.$refs.mainMenu.click();
        },
        menuButtonLeave(e) {
            if (
                e.relatedTarget &&
                e.relatedTarget.classList &&
                e.relatedTarget.classList.contains(
                    this.namespaceClass('navigation')
                )
            ) {
                this.setNavMenuStatus(true);
            } else {
                this.setNavMenuStatus(false);
            }
        },
        clickNavigation(item) {
            if (typeof item.worksheetId !== 'undefined') {
                this.setActiveWorksheetId(item.worksheetId);
            } else if (item.id === 'watchlists') {
                this.$eventBus.$emit(this.$EVENTS.SHOW_WATCHLISTMANGER);
            } else if (item.id === 'alerts') {
                this.$eventBus.$emit(this.$EVENTS.SHOW_ALERTMANAGER);
            } else if (item.id === 'excelrtd') {
                this.$eventBus.$emit(this.$EVENTS.SHOW_EXCELRTD);
            } else if (item.id === 'video' && this.showVideo) {
                this.videoClicked();
            }
            this.setNavMenuStatus(false);
        },
        bellClicked(e) {
            this.settingsVisible = false;
            this.$refs.alertManager.toggleSliderAlerts();
        },
        videoClicked() {
            let left = (window.screen.width - 1150) / 2;
            let top = (window.screen.height - 720) / 2;
            if (left < 0 || top < 0) {
                left = 0;
                top = 0;
            }
            const options = {
                width: 1150,
                height: 720,
                innerWidth: 1150,
                innerHeight: 720,
                left: left,
                top: top
            };
            this.openWindow = getOpenWindowObject(
                'video.html',
                'Video',
                options
            );
        },
        unreadCountChanged(count) {
            this.alertCount = count;
        },
        importComplete(symbolStr) {
            this.$eventBus.$emit(
                this.$EVENTS.IMPORT_COMPLETE,
                symbolStr,
                this.saveHoldingsId
            );
            this.importVisible = false;
            this.saveHoldingsId = null;
        }
    }
};
</script>
