import Vue from 'vue';
import Router from 'vue-router';
import Signin from '../views/Signin';
import Home from '../views/Home';
Vue.use(Router);

export default new Router({
    mode: 'history',
    base: '/',
    routes: [
        {
            path: '/signin',
            component: Signin
        },
        {
            path: '/',
            component: Home
        }
    ]
});
