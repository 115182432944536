<template>
    <div :class="namespaceClass('component-library')">
        <div :class="namespaceClass('component-library-header')">
            <span class="mds-strong">Component Library</span>
        </div>
        <div :class="namespaceClass('component-library-bodyer')">
            <ul>
                <div :class="namespaceClass('component-library-text')">
                    Drag and drop components to add to worksheet. Use component
                    headers to rearrange and delete.
                </div>
                <div
                    :class="[
                        'mds-search-field',
                        'mds-search-field--small',
                        namespaceClass('component-library-search-field')
                    ]"
                    role="search"
                >
                    <input
                        type="text"
                        class="mds-search-field__input"
                        aria-label="Search Components"
                        placeholder="Search Components"
                        v-model="searchTerm"
                    />
                    <svg
                        class="mds-icon mds-search-field__search-icon"
                        aria-hidden="true"
                    >
                        <use
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            :xlink:href="mdsIcon('search--s')"
                        ></use>
                    </svg>
                    <span class="mds-search-field__input-focus-outline"></span>
                </div>
                <section
                    :class="namespaceClass('component-library-wrapper')"
                    ref="libraryWrapper"
                >
                    <div
                        :class="namespaceClass('component-library-group')"
                        v-for="item in autoGroupComponents"
                        :key="item.group"
                        ref="componentLibraryGroup"
                    >
                        <p
                            :class="
                                namespaceClass('component-library-group-title')
                            "
                        >
                            {{ item.group }}
                        </p>
                        <li
                            :class="[
                                'mds-button',
                                namespaceClass('component-library-component'),
                                component.disableComponentDrag
                                    ? namespaceClass('disable-component-drag')
                                    : ''
                            ]"
                            v-for="component in item.components"
                            :key="component.componentName"
                            :data-component-name="component.componentName"
                        >
                            <span
                                :class="[
                                    'mds-button',
                                    'mds-button--icon-only',
                                    namespaceClass(
                                        'component-library-component-icon-left'
                                    )
                                ]"
                            >
                                <svg
                                    class="mds-icon mds-icon--s"
                                    aria-hidden="true"
                                >
                                    <use
                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                        :xlink:href="mdsIcon('grab-handle--s')"
                                    ></use>
                                </svg>
                            </span>
                            <span
                                :class="[
                                    'mds-button__text',
                                    namespaceClass(
                                        'component-library-component-title'
                                    )
                                ]"
                                :title="component.title"
                                v-html="component.htmlTitle || component.title"
                            ></span>
                            <span
                                :class="[
                                    'mds-button',
                                    'mds-button--icon-only',
                                    namespaceClass(
                                        'component-library-component-icon-right'
                                    )
                                ]"
                                @click.stop="
                                    showComponentDescription($event, component)
                                "
                            >
                                <svg
                                    class="mds-icon mds-icon--s"
                                    aria-hidden="true"
                                >
                                    <use
                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                        :xlink:href="
                                            mdsIcon('question-circle--s')
                                        "
                                    ></use>
                                </svg>
                            </span>
                        </li>
                    </div>
                </section>
            </ul>
        </div>
        <markets-ui-popover
            ref="tips"
            :class="namespaceClass('component-library-tips')"
            :visible="componentTips.visible"
            :triggered-by="componentTips.triggeredId"
            :position="componentTips.position"
            :skin="skin"
            :width="componentTips.width"
            @hide="hideComponentTips"
        >
            <div :class="namespaceClass('popover-content')">
                <div
                    v-if="componentTips.showAlertIcon"
                    :class="namespaceClass('popover-content__warning')"
                >
                    <span aria-hidden="true" class="mds-icon mds-alert__icon">
                        <svg
                            class="mds-icon mds-button__icon mds-button__icon--left"
                            title="Warning"
                        >
                            <use
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                :xlink:href="mdsIcon('alert')"
                            >
                                <title>Warning</title>
                            </use>
                        </svg>
                    </span>
                </div>
                <div
                    ref="tipsMessage"
                    :class="namespaceClass('component-library-tips__message')"
                    v-html="componentTips.message"
                ></div>
            </div>
            <div
                v-if="componentTips.showReadMore"
                :class="namespaceClass('component-library-tips__link')"
                @click="showFullComponentDescription"
            >
                Read More
            </div>
        </markets-ui-popover>
        <MODAL
            :width="900"
            :visible="descModelData.visible"
            :class="namespaceClass('component-library-modal')"
            ref="componentDescModel"
            :title="descModelData.title"
            :skin="skin"
        >
            <button
                slot="header-actions"
                class="mds-button mds-button--icon-only"
                data-mds-modal-close
                @click="hideFullComponentDescription"
            >
                <svg class="mds-icon mds-button__icon mds-button__icon--left">
                    <use :xlink:href="mdsIcon('remove')">
                        <title>Close</title>
                    </use>
                </svg>
            </button>
            <div
                :class="namespaceClass('component-library-modal__content')"
                slot="content"
                v-html="descModelData.content"
            ></div>
        </MODAL>
    </div>
</template>

<script>
import $ from 'jquery';
import { mapGetters, mapState, mapActions } from 'vuex';
import utils, { clamp } from '../../util';
import MODAL from '../ui/modal';
import { MAX_WATCHLIST_MSG, ONE_RESEARCH_MSG } from '../../common';
import { Components } from '../../componentsManager/base-component';
export default {
    name: 'ComponentLibrary',
    components: {
        MODAL
    },
    computed: {
        ...mapGetters('worksheet', ['availableGroupComponents']),
        ...mapState('worksheet', ['availableComponents', 'goldenLayout']),
        ...mapGetters('settings', ['skin']),
        dragSourceComponents() {
            let components = [];
            this.autoGroupComponents.forEach(item => {
                components = [...components, ...item.components];
            });
            return components;
        },
        autoGroupComponents() {
            if (!this.searchTerm) {
                return this.availableGroupComponents;
            }
            const groupComponents = [];
            this.availableGroupComponents.forEach(item => {
                const components = [];
                item.components.forEach(com => {
                    const idx = com.title
                        .toUpperCase()
                        .indexOf(this.searchTerm.toUpperCase());
                    if (idx > -1) {
                        const _com = utils.extend(true, {}, com);
                        const hightLight = _com.title.substring(
                            idx,
                            idx + this.searchTerm.length
                        );
                        _com.htmlTitle = _com.title.replace(
                            hightLight,
                            `<span class="${this.namespaceClass(
                                'text-highlight'
                            )}">${hightLight}</span>`
                        );
                        components.push(_com);
                    }
                });
                if (components.length > 0) {
                    groupComponents.push({
                        group: item.group,
                        components
                    });
                }
            });
            return groupComponents;
        }
    },
    data() {
        return {
            searchTerm: '',
            componentTips: {
                showReadMore: false,
                visible: false,
                triggeredId: null,
                message: '',
                title: '',
                position: ['top-left'],
                width: 200
            },
            descModelData: {
                visible: false,
                title: '',
                content: ''
            }
        };
    },
    mounted() {
        this.$libraryWrapper = $(this.$refs.libraryWrapper);
        this.$libraryWrapper.on('scroll', () => {
            if (this.componentTips.visible) {
                this.hideComponentTips();
            }
        });
        this.$libraryWrapper.on('mousemove', e => {
            const $target = $(e.target);
            let target;
            if (
                $target.hasClass(this.namespaceClass('disable-component-drag'))
            ) {
                target = e.target;
            } else {
                target = $target.parent(
                    `.${this.namespaceClass('disable-component-drag')}`
                )[0];
            }
            if (target) {
                this.showDisableMessage(
                    { target },
                    $(target).data('component-name')
                );
            } else if (!this.showDescription) {
                this.hideComponentTips();
            }
        });
    },
    beforeDestroy() {
        this.$libraryWrapper.off('scroll');
    },
    watch: {
        autoGroupComponents(value) {
            if (value.length) {
                this.createDragSource();
            }
        },
        goldenLayout(value) {
            if (value) {
                this.createDragSource();
            }
        }
    },
    methods: {
        ...mapActions('worksheet', ['listAvailableComponents']),
        createDragSource() {
            this.$nextTick(() => {
                const self = this;
                $(this.$el)
                    .find(
                        `.${this.namespaceClass('component-library-component')}`
                    )
                    .each(function() {
                        const $target = $(this);
                        const componentName = $target.attr(
                            'data-component-name'
                        );
                        const component = utils.find(
                            self.getDragSourceComponents(),
                            com => com.componentName === componentName
                        );
                        $target.off('mousedown mouseup mousemove click');
                        if (
                            self.goldenLayout &&
                            component &&
                            !component.disableComponentDrag
                        ) {
                            self.goldenLayout.createDragSource(
                                $target,
                                component
                            );
                        }
                    });
            });
        },
        getDragSourceComponents() {
            let components = [];
            this.autoGroupComponents.forEach(item => {
                components = [...components, ...item.components];
            });
            return components;
        },
        showComponentDescription(e, component) {
            if (
                this.componentTips.visible &&
                component.title === this.componentTips.title
            ) {
                this.hideComponentTips();
            } else {
                this.componentTips.showReadMore = false;
                this.componentTips.message = component.desc || component.title;
                this.componentTips.title = component.title;
                this.componentTips.visible = true;
                this.componentTips.triggeredId = utils.computeTriggerId(
                    e.target
                );
                this.componentTips.position = ['top-left'];
                this.componentTips.showAlertIcon = false;
                this.showDescription = true;
                this.$nextTick(() => {
                    const { clamped } = clamp(this.$refs.tipsMessage, {
                        clamp: 7
                    });
                    if (clamped && this.componentTips.message !== clamped) {
                        this.componentTips.showReadMore = true;
                    }
                    this.$nextTick(() => {
                        if (this.$refs.tips) {
                            this.$refs.tips.updatePosition();
                        }
                    });
                });
            }
        },
        hideComponentTips() {
            this.componentTips.message = '';
            this.componentTips.title = '';
            this.componentTips.visible = false;
            this.componentTips.showReadMore = false;
            this.showDescription = false;
        },
        showDisableMessage(e, component) {
            if (component === Components.Watchlist) {
                this.componentTips.message = MAX_WATCHLIST_MSG;
            } else if (component === 'mwc-markets-qualitative-research') {
                this.componentTips.message = ONE_RESEARCH_MSG;
            } else {
                this.componentTips.message = '';
            }
            if (this.componentTips.message) {
                this.componentTips.title = 'Warning';
                this.componentTips.visible = true;
                this.componentTips.triggeredId = utils.computeTriggerId(
                    e.target
                );
                this.componentTips.position = ['bottom-center'];
                this.componentTips.width = 200;
                this.componentTips.showAlertIcon = true;
                this.componentTips.showReadMore = false;
            }
        },
        showFullComponentDescription() {
            this.descModelData = {
                visible: true,
                title: this.componentTips.title,
                content: this.componentTips.message
            };
            this.hideComponentTips();
        },
        hideFullComponentDescription() {
            this.descModelData = {
                visible: false,
                title: '',
                content: ''
            };
        }
    }
};
</script>
