import api from '../../data/api';
import utils, {
    extendAuthParams,
    removeEmptyContents,
    updateAliasComponentsName
} from '../../util';
import FUNCTIONS from '../../data/functions';
import { trackWorksheetComponentList } from '../../componentsManager/tracking';
import { updateComponentState } from '../../common';
const _state = {
    workbooks: [],
    isDirty: false,
    navVisible: false
};

const getters = {
    activeWorkbook(state) {
        return utils.find(state.workbooks, wb => wb.profile.isActive);
    },
    navigation(state, getter, rootState, rootGetters) {
        let wsList = [];
        if (state.workbooks.length > 0) {
            wsList = getter.activeWorkbook.worksheets.map(ws => {
                return {
                    id: `worksheet-${ws.id}`,
                    name: ws.name,
                    worksheetId: ws.id,
                    click: true
                };
            });
        }
        const data = [
            {
                id: 'home',
                name: 'Home',
                worksheetId: wsList.length ? wsList[0].worksheetId : undefined,
                click: true
            },
            {
                id: 'watchlists',
                name: 'Watchlists',
                click: true
            },
            {
                id: 'worksheets',
                name: 'View',
                click: false,
                children: wsList
            },
            {
                id: 'alerts',
                name: 'Alerts',
                click: true
            }
        ];
        if (rootState.settings.showVideo) {
            data.push({
                id: 'video',
                name: 'Video',
                click: true
            });
        }
        if (rootGetters['settings/isFeatureEnable'](FUNCTIONS.EXCEL_RTD)) {
            data.push({
                id: 'excelrtd',
                name: 'Excel RTD',
                click: true
            });
        }
        return {
            visible: state.navVisible,
            data
        };
    }
};

const actions = {
    async listWorkbooks({ commit, rootGetters, dispatch, rootState }) {
        const token = rootGetters['account/token'];
        const url = `${rootGetters['application/url'](
            '@web/ra/workbook/list'
        )}`;
        const workbookPromise = api.workbookList(
            url,
            extendAuthParams(
                {
                    withWorksheet: true
                },
                token
            )
        );
        const componentsPromise = dispatch(
            'worksheet/listAvailableComponents',
            null,
            {
                root: true
            }
        );
        Promise.all([
            workbookPromise,
            componentsPromise,
            rootState.watchlist.getWatchlistPromise
        ])
            .then(res => {
                const workbooks = res[0];
                const accountId = rootState.settings.accountId;
                const availableComponents =
                    rootState.worksheet.availableComponents;
                commit(
                    'setWorkbooks',
                    formatWorkbooks(workbooks, !!accountId, availableComponents)
                );
                const activeWorkbook = rootGetters['workbook/activeWorkbook'];
                if (
                    activeWorkbook &&
                    !!activeWorkbook.profile.isDjActive &&
                    accountId
                ) {
                    commit('worksheet/setDowJonesActive', undefined, {
                        root: true
                    });
                }
                trackWorksheetComponentList(workbooks);
            })
            .catch(e => {
                commit('application/setLoading', false, { root: true });
                commit(
                    'application/setNotification',
                    {
                        visible: true,
                        type: 'error',
                        message:
                            'There was a problem retrieving the data you saved, check your network and try again.',
                        dismissible: true
                    },
                    { root: true }
                );
                return Promise.reject(e);
            });
    },
    async saveWorkbooks({ state, rootGetters, dispatch, commit, rootState }) {
        if (!state.isDirty) {
            return true;
        }
        const token = rootGetters['account/token'];
        const url = `${rootGetters['application/url'](
            '@web/ra/workbook/save'
        )}`;
        let dirtyWorksheets = getDirtyWorksheets(
            rootGetters['workbook/activeWorkbook']
        );
        const activeWorkbook = rootGetters['workbook/activeWorkbook'];

        const toBeSavedWorkbook = utils.extend(true, {}, activeWorkbook);
        toBeSavedWorkbook.profile = toBeSavedWorkbook.profile || {};

        // saved when dowjones is enabled
        if (rootState.settings.accountId) {
            toBeSavedWorkbook.profile.isDjActive =
                rootState.worksheet.activeDowjones;
        } else {
            delete toBeSavedWorkbook.profile.isDjActive;
        }

        const activeWorksheetId = rootState.worksheet.activeWorksheetId;
        const lastActiveWorksheetId = rootState.worksheet.lastActiveWorksheetId;
        toBeSavedWorkbook.activeWorksheetId =
            activeWorksheetId === '-1'
                ? lastActiveWorksheetId
                : activeWorksheetId;
        toBeSavedWorkbook.worksheets = dirtyWorksheets;
        return api
            .saveWorkbooks(
                url,
                extendAuthParams(
                    {
                        workbooks: JSON.stringify([toBeSavedWorkbook])
                    },
                    token
                )
            )
            .then(data => {
                const savedData = resetIDs(
                    state.workbooks,
                    activeWorksheetId,
                    data
                );
                commit('setWorkbooks', savedData);
                dispatch(
                    'worksheet/listWorksheets',
                    rootGetters['workbook/activeWorkbook'].id,
                    {
                        root: true
                    }
                );
                const dirtyWorksheetIds = dirtyWorksheets.map(ws => {
                    return ws.id;
                });
                commit(
                    'worksheet/setWorksheetDirty',
                    {
                        worksheetId: dirtyWorksheetIds.join(','),
                        status: false
                    },
                    {
                        root: true
                    }
                );
                commit('setDirty', false);
                return data;
            })
            .catch(error => {
                return Promise.reject(error);
            });
    }
};

function getDirtyWorksheets(workbook) {
    const worksheets = utils.extend(true, [], workbook.worksheets);
    let dirtyWorksheets = worksheets.filter(
        ws => ws.isDirty || !/^[0-9]+$/.test(ws.id)
    );
    dirtyWorksheets = dirtyWorksheets.map(ws => {
        const tempProperties = ['isDirty', 'originalId', 'isActive'];
        tempProperties.forEach(key => {
            ws[key] = null;
            delete ws[key];
        });
        const layoutConfig = ws.profile.layoutConfig;
        updateComponentState('remove', layoutConfig);
        ws.profile.layoutConfig = layoutConfig;
        return ws;
    });
    return dirtyWorksheets;
}

function extractValidData(data) {
    const validData = {};
    const keys = [...Object.keys(data)];
    keys.forEach(key => {
        if (key && data[key] !== undefined) {
            if (key === 'profile' && typeof data[key] === 'string') {
                validData[key] = JSON.parse(data[key]);
            } else {
                validData[key] = data[key];
            }
        }
    });
    return validData;
}
/**
 * reset workbook id and worksheet id generated in DB to local store
 * @param {Array} workbooks - the store.state.workbooks before save
 * @param {String} activeWorksheetId - the active worksheetId before save
 * @param {Array} data - the data returned from backend when save profile
 */
function resetIDs(workbooks, activeWorksheetId, data = []) {
    const _workbooks = utils.extend(true, [], workbooks);
    _workbooks.forEach(wb => {
        const targetWB = utils.find(data || [], item => {
            return item.originalId === wb.id;
        });
        if (targetWB) {
            wb.id = targetWB.id;
            wb.worksheets.forEach(ws => {
                const targetWS = utils.find(targetWB.worksheets || [], item => {
                    return item.originalId === ws.id;
                });
                if (targetWS) {
                    if (activeWorksheetId === targetWS.originalId) {
                        activeWorksheetId = targetWS.id;
                    }
                    ws.originalId = targetWS.originalId;
                    ws.id = targetWS.id;
                    if (ws.components) {
                        ws.components.forEach(com => {
                            const targetCom = utils.find(
                                targetWS.components || [],
                                item => {
                                    return item.originalId === com.id;
                                }
                            );
                            if (targetCom) {
                                com.id = targetCom.id;
                            }
                        });
                    }
                }
            });
            wb.activeWorksheetId = activeWorksheetId;
        }
    });
    return _workbooks;
}

function formatWorkbooks(workbooks = [], enableDj, availableComponents = []) {
    return workbooks.map(wb => {
        const wbObj = extractValidData(wb);
        let activeWorksheetId = wb.activeWorksheetId;
        const worksheets = wbObj.worksheets || [];
        // Backward: if there is no activeWorksheetId, then use the worksheet.profile.isActive === true, final to use the first worksheet
        if (!activeWorksheetId) {
            const activeWorksheet = utils.find(worksheets, ws => {
                return ws.profile.isActive === true;
            });
            if (activeWorksheet) {
                activeWorksheetId = activeWorksheet.id;
            } else if (worksheets.length) {
                activeWorksheetId = worksheets[0].id;
            }
        } else if (
            !worksheets.some(ws => ws.id === activeWorksheetId) &&
            worksheets.length
        ) {
            activeWorksheetId = worksheets[0].id;
        }
        wbObj.worksheets = worksheets.map(ws => {
            const wsObj = extractValidData(ws);
            // for KeyBank add model portfolio component into kpb dashboard
            if (
                wsObj.name === 'KPB Market Dashboard' &&
                wsObj.profile &&
                wsObj.profile.layoutConfig &&
                wsObj.profile.layoutConfig[0].content &&
                wsObj.profile.layoutConfig[0].content[0].content[1] &&
                wsObj.profile.layoutConfig[0].content[0].content[1].content[0]
                    .componentName !== 'mwc-markets-model-portfolio'
            ) {
                // fix Model Portfolio table is blank when user first time to navigate to KPB Markets
                wsObj.profile.layoutConfig[0].content[0].content[1].activeItemIndex = 0;
                wsObj.profile.layoutConfig[0].content[0].content[1].content.unshift(
                    {
                        type: 'component',
                        componentName: 'mwc-markets-model-portfolio'
                    }
                );
            }
            wsObj.profile = reviseWorksheetProfile(
                wsObj.profile,
                availableComponents
            );
            const layoutConfig = wsObj.profile.layoutConfig;
            updateComponentState('add', layoutConfig);
            wsObj.profile.layoutConfig = layoutConfig;
            return wsObj;
        });
        wbObj.activeWorksheetId =
            wbObj.profile.isDjActive && enableDj ? '-1' : activeWorksheetId;
        return wbObj;
    });
}

function reviseWorksheetProfile(profile, availableComponents) {
    [
        'editable',
        'reorderEnabled',
        'showCloseIcon',
        'componentDragable'
    ].forEach(key => {
        profile[key] = profile[key] === false ? false : true;
    });
    const layoutConfig = profile.layoutConfig;
    removeInvalidComponent(layoutConfig, availableComponents);
    removeEmptyContents(layoutConfig, layoutConfig);
    return profile;
}

function removeInvalidComponent(content = [], availableComponents) {
    for (let index = 0; index < content.length; index++) {
        const contentItem = content[index];
        if (contentItem.type === 'component') {
            updateAliasComponentsName(contentItem);
            const target = utils.find(availableComponents, c => {
                return c.componentName === contentItem.componentName;
            });
            if (!target) {
                content.splice(index, 1);
                index--;
            }
        } else {
            removeInvalidComponent(contentItem.content, availableComponents);
        }
    }
}

const mutations = {
    deleteWorkbook(state, { id }) {
        const index = utils.findIndex(state.workbooks, w => {
            return w.id === id;
        });
        if (index >= 0) {
            state.workbooks.splice(index, 1);
        }
    },
    setDirty(state, status) {
        state.isDirty = !!status;
    },
    setWorkbooks(state, workbooks) {
        state.workbooks = workbooks;
    },
    setNavMenuStatus(state, status) {
        state.navVisible = status === true;
    },
    setWorkbookWorksheets(state, worksheets) {
        const idx = utils.findIndex(state.workbooks, wb => {
            return wb.profile.isActive;
        });
        state.workbooks[idx].worksheets = worksheets;
    }
};

export default {
    namespaced: true,
    state: _state,
    getters,
    actions,
    mutations
};
