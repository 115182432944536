var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mds-modal mds-modal--width-900px",class:[
        _vm.namespaceClass('global-settings'),
        _vm.isEditing ? 'mds-modal--open mds-modal--active' : ''
    ]},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isEditing),expression:"isEditing"}],staticClass:"mds-overlay"}),_c('div',{staticClass:"mds-modal__wrapper"},[_c('section',{staticClass:"mds-modal__container",attrs:{"role":"dialog","aria-labelledby":"global-setting-modal","aria-hidden":"true"}},[_c('div',{staticClass:"mds-modal__inner"},[_c('header',{staticClass:"mds-modal__header"},[_c('div',{staticClass:"mds-modal__actions"},[_c('button',{staticClass:"mds-button mds-button--secondary",attrs:{"type":"button","data-mds-modal-close":""},on:{"click":function($event){return _vm.setEditStatus(false)}}},[_vm._v(" Cancel ")]),_c('button',{staticClass:"mds-button mds-button--primary",attrs:{"type":"button"},on:{"click":_vm.changeSettings}},[_vm._v(" Save ")])]),_c('h1',{staticClass:"mds-modal__title",attrs:{"id":"global-setting-modal"}},[_vm._v(" Global Settings ")])]),_c('div',{staticClass:"mds-modal__content"},[_c('div',{staticClass:"mds-layout-grid"},[_c('div',{staticClass:"mds-layout-grid__row"},[_c('div',{staticClass:"mds-layout-grid__col mds-layout-grid__col--2",class:_vm.namespaceClass('global-settings__nav')},[_vm._m(0)]),_c('div',{staticClass:"mds-layout-grid__col mds-layout-grid__col--10"},[_c('div',{staticClass:"mds-layout-grid__row"},[_c('div',{staticClass:"mds-layout-grid__col mds-layout-grid__col--12"},[_c('form',{staticClass:"mds-form mds-form--medium-layout",attrs:{"onsubmit":"return false;"}},[_c('div',{staticClass:"mds-form__field-group mds-form__field-group--horizontal"},[(
                                                        _vm.isEnableMultiThemes
                                                    )?_c('div',{staticClass:"mds-form__field-group",class:_vm.namespaceClass(
                                                            'flex-grow-1'
                                                        )},[_c('markets-ui-form',[_c('markets-ui-radio-button-group',{attrs:{"title":"Choose theme","skin":_vm.skin,"size":"medium","isHorizontal":"","dataModel":_vm.themeList},on:{"change":_vm.changeTheme}})],1)],1):_vm._e(),_c('div',{staticClass:"mds-form__field-group",class:_vm.namespaceClass(
                                                            'flex-grow-1'
                                                        )},[_c('markets-ui-form',[_c('markets-ui-radio-button-group',{attrs:{"title":"Choose data type","skin":_vm.skin,"size":"medium","isHorizontal":"","dataModel":_vm.dataTypeList},on:{"change":_vm.changeDataType}})],1)],1)]),_c('div',{staticClass:"mds-form__field-group mds-form__field-group--horizontal"},[_c('div',{staticClass:"mds-form__field-group",class:_vm.namespaceClass(
                                                            'flex-grow-1'
                                                        )},[_c('label',{staticClass:"mds-form__checkbox",class:_vm.namespaceClass(
                                                                'auto-save-label'
                                                            )},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                                                _vm.settings.autoSave
                                                            ),expression:"\n                                                                settings.autoSave\n                                                            "}],staticClass:"mds-form__checkbox-input",attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(
                                                                _vm.settings.autoSave
                                                            )?_vm._i(
                                                                _vm.settings.autoSave
                                                            ,null)>-1:(
                                                                _vm.settings.autoSave
                                                            )},on:{"change":function($event){var $$a=
                                                                _vm.settings.autoSave
                                                            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.settings, "autoSave", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.settings, "autoSave", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.settings, "autoSave", $$c)}}}}),_c('span',{staticClass:"mds-form__checkbox-visible-wrap"},[_c('span',{staticClass:"mds-form__checkbox-visual"},[_c('svg',{staticClass:"mds-icon mds-form__checkbox-checked-icon",attrs:{"aria-hidden":"true"}},[_c('use',{attrs:{"xlink:href":_vm.mdsIcon(
                                                                                'check--s'
                                                                            )}})]),_c('svg',{staticClass:"mds-icon mds-form__checkbox-indeterminate-icon",attrs:{"aria-hidden":"true"}},[_c('use',{attrs:{"xlink:href":_vm.mdsIcon(
                                                                                'minus--s'
                                                                            )}})])]),_c('span',{staticClass:"mds-form__checkbox-text"},[_vm._v("Auto-save")])])]),(
                                                            _vm.settings.autoSave
                                                        )?_c('span',{class:_vm.namespaceClass(
                                                                'auto-save-details'
                                                            )},[_c('span',[_vm._v("Every ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                                                _vm.settings.autoSaveInterval
                                                            ),expression:"\n                                                                settings.autoSaveInterval\n                                                            "}],staticClass:"mds-form__input",class:_vm.namespaceClass(
                                                                    'auto-save-input'
                                                                ),attrs:{"min":"1","max":"999","type":"number"},domProps:{"value":(
                                                                _vm.settings.autoSaveInterval
                                                            )},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.settings, "autoSaveInterval", $event.target.value)}}}),_c('span',[_vm._v(" minutes")])]):_vm._e(),(_vm.errorMessage)?_c('span',{staticClass:"mds-form__field-error",attrs:{"role":"alert"}},[_c('span',{staticClass:"mds-form__field-error-text"},[_vm._v(_vm._s(_vm.errorMessage))])]):_vm._e()])])])])])])])])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"mds-list-group"},[_c('li',{staticClass:"mds-list-group__item"},[_c('a',{staticClass:"mds-list-group__link",attrs:{"href":"#"}},[_c('span',{staticClass:"mds-list-group__item-text"},[_vm._v(" General ")])])])])}]

export { render, staticRenderFns }