import mwcMarketsCore from 'mwc-markets-core';
export default mwcMarketsCore.utils;

export function guid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        const r = (Math.random() * 16) | 0;
        const v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}
export function extendAuthParams(params = {}, token) {
    if (token.sessionId) {
        params.qs_wsid = token.sessionId;
    }
    if (token.instid) {
        params.instid = token.instid;
    }
    return params;
}

export function every(array, fn) {
    for (let i = 0, l = array.length; i < l; i++) {
        if (!fn(array[i])) {
            return false;
        }
    }
    return true;
}

export function reduce(array, iteratee, accumulator) {
    return arrayReduce(array, iteratee, accumulator, arguments.length < 3);
}

function arrayReduce(array, iteratee, accumulator, initAccum) {
    let index = -1;
    const length = array === null ? 0 : array.length;

    if (initAccum && length) {
        accumulator = array[++index];
    }
    while (++index < length) {
        accumulator = iteratee(accumulator, array[index], index, array);
    }
    return accumulator;
}
/**
 * example：newArray.sort(sortBy('number',false))
 * @param attr Sort property: 'name','age'
 * @param rev Ascending: true，Descending: false;
 * */
export function sortBy(attr, rev) {
    if (rev === undefined) {
        rev = 1;
    } else {
        rev = rev ? 1 : -1;
    }

    return function(a, b) {
        a = a[attr];
        b = b[attr];
        if (a < b) {
            return rev * -1;
        }
        if (a > b) {
            return rev * 1;
        }
        return 0;
    };
}
/**
 * to fix Circular reference issue of JSON.stringify
 * @param {Object} _object the object you want to stringfy
 */
export function stringify(_object) {
    if (typeof _object !== 'object') {
        return _object;
    }
    const cache = [];
    const str = JSON.stringify(_object, function(key, value) {
        if (typeof value === 'object' && value !== null) {
            if (cache.indexOf(value) !== -1) {
                // Circular reference found, discard key
                return;
            }
            cache.push(value);
        }
        return value;
    });
    return str;
}
/**
 * Serialises a map of key:values to a window options string
 *
 * @param   {Object} windowOptions
 *
 * @returns {String} Serialises options string
 */
export function serializeWindowOptions(windowOptions) {
    let windowOptionsString = [],
        key;
    for (key in windowOptions) {
        windowOptionsString.push(key + '=' + windowOptions[key]);
    }
    return windowOptionsString;
}
/**
 * Serialises a map of key:values to a window options string
 *
 * @param   {Object} windowOptions
 * @param   {String} openHtml
 * @param   {String} Title
 *
 * @returns {Object} options string
 */
export function getOpenWindowObject(openHtml, Title, windowOptions) {
    const options = {
        width: 800,
        height: 600,
        innerWidth: 800,
        innerHeight: 600,
        menubar: 'no',
        toolbar: 'no',
        location: 'no',
        personalbar: 'no',
        resizable: 'no',
        scrollbars: 'no',
        status: 'no'
    };
    const mergeOptions = { ...options, ...windowOptions };
    const windowOptionsString = serializeWindowOptions(mergeOptions);
    return window.open(openHtml, Title, windowOptionsString);
}

/**
 * Clamps a text node.
 * @param {HTMLElement} element. Element containing the text node to clamp.
 * @param {Object} options. Options to pass to the clamper.
 */
export function clamp(element, options) {
    options = options || {};
    const opt = {
        clamp: options.clamp || 2,
        useNativeClamp: options.useNativeClamp || false,
        truncationChar: options.truncationChar || '...',
        splitOnChars: options.splitOnChars || ['.', '-', '–', '—', ' '] // Split on sentences (periods), hypens, en-dashes, em-dashes, and words (spaces).
    };
    const style = element.style;
    const originalText = element.innerHTML;
    const supportsNativeClamp = typeof style.webkitLineClamp !== 'undefined';
    let clampValue = opt.clamp;
    const isCSSValue =
        clampValue.indexOf &&
        (clampValue.indexOf('px') > -1 || clampValue.indexOf('em') > -1);

    // Utility functions
    /**
     * Return the current style for an element.
     * @param {HTMLElement} elem The element to compute.
     * @param {string} prop The style property.
     * @returns {number}
     */
    function computeStyle(elem, prop) {
        if (!window.getComputedStyle) {
            window.getComputedStyle = function(el, pseudo) {
                this.el = el;
                this.getPropertyValue = function(prop) {
                    const re = /(-([a-z]){1})/g;
                    if (prop === 'float') {
                        prop = 'styleFloat';
                    }
                    if (re.test(prop)) {
                        prop = prop.replace(re, function() {
                            return arguments[2].toUpperCase();
                        });
                    }
                    return el.currentStyle && el.currentStyle[prop]
                        ? el.currentStyle[prop]
                        : null;
                };
                return this;
            };
        }

        return window.getComputedStyle(elem, null).getPropertyValue(prop);
    }

    /**
     * Returns the maximum number of lines of text that should be rendered based
     * on the current height of the element and the line-height of the text.
     */
    function getMaxLines(height) {
        const availHeight = height || element.clientHeight,
            lineHeight = getLineHeight(element);

        return Math.max(Math.floor(availHeight / lineHeight), 0);
    }

    /**
     * Returns the maximum height a given element should have based on the line-
     * height of the text and the given clamp value.
     */
    function getMaxHeight(clamp) {
        const lineHeight = getLineHeight(element);
        return lineHeight * clamp;
    }

    /**
     * Returns the line-height of an element as an integer.
     */
    function getLineHeight(elem) {
        let lh = computeStyle(elem, 'line-height');
        if (lh === 'normal') {
            // Normal line heights vary from browser to browser. The spec recommends
            // a value between 1.0 and 1.2 of the font size. Using 1.1 to split the diff.
            lh = parseInt(computeStyle(elem, 'font-size')) * 1.2;
        }
        return parseInt(lh);
    }

    let splitOnChars = opt.splitOnChars.slice(0),
        splitChar = splitOnChars[0],
        chunks,
        lastChunk;

    /**
     * Gets an element's last child. That may be another node or a node's contents.
     */
    function getLastChild(elem) {
        //Current element has children, need to go deeper and get last child as a text node
        if (elem.lastChild.children && elem.lastChild.children.length > 0) {
            return getLastChild(
                Array.prototype.slice.call(elem.children).pop()
            );
        }
        //This is the absolute last child, a text node, but something's wrong with it. Remove it and keep trying
        else if (
            !elem.lastChild ||
            !elem.lastChild.nodeValue ||
            elem.lastChild.nodeValue === '' ||
            elem.lastChild.nodeValue === opt.truncationChar
        ) {
            elem.lastChild.parentNode.removeChild(elem.lastChild);
            return getLastChild(element);
        }
        //This is the last child we want, return it
        else {
            return elem.lastChild;
        }
    }

    /**
     * Removes one character at a time from the text until its width or
     * height is beneath the passed-in max param.
     */
    function truncate(target, maxHeight) {
        if (!maxHeight) {
            return;
        }

        /**
         * Resets global variables.
         */
        function reset() {
            splitOnChars = opt.splitOnChars.slice(0);
            splitChar = splitOnChars[0];
            chunks = null;
            lastChunk = null;
        }

        const nodeValue = target.nodeValue.replace(opt.truncationChar, '');

        //Grab the next chunks
        if (!chunks) {
            //If there are more characters to try, grab the next one
            if (splitOnChars.length > 0) {
                splitChar = splitOnChars.shift();
            }
            //No characters to chunk by. Go character-by-character
            else {
                splitChar = '';
            }

            chunks = nodeValue.split(splitChar);
        }

        //If there are chunks left to remove, remove the last one and see if
        // the nodeValue fits.
        if (chunks.length > 1) {
            lastChunk = chunks.pop();
            applyEllipsis(target, chunks.join(splitChar));
        }
        //No more chunks can be removed using this character
        else {
            chunks = null;
        }

        //Search produced valid chunks
        if (chunks) {
            //It fits
            if (element.clientHeight <= maxHeight) {
                //There's still more characters to try splitting on, not quite done yet
                if (splitOnChars.length >= 0 && splitChar !== '') {
                    applyEllipsis(
                        target,
                        chunks.join(splitChar) + splitChar + lastChunk
                    );
                    chunks = null;
                }
                //Finished!
                else {
                    return element.innerHTML;
                }
            }
        }
        //No valid chunks produced
        else {
            //No valid chunks even when splitting by letter, time to move
            //on to the next node
            if (splitChar === '') {
                applyEllipsis(target, '');
                target = getLastChild(element);

                reset();
            }
        }

        //If you get here it means still too big, let's keep truncating
        return truncate(target, maxHeight);
    }

    function applyEllipsis(elem, str) {
        elem.nodeValue = str + opt.truncationChar;
    }

    if (clampValue === 'auto') {
        clampValue = getMaxLines();
    } else if (isCSSValue) {
        clampValue = getMaxLines(parseInt(clampValue, 10));
    }
    let clampedText;
    if (supportsNativeClamp && opt.useNativeClamp) {
        style.overflow = 'hidden';
        style.textOverflow = 'ellipsis';
        style.webkitBoxOrient = 'vertical';
        style.display = '-webkit-box';
        style.webkitLineClamp = clampValue;
        if (isCSSValue) {
            style.height = opt.clamp + 'px';
        }
    } else {
        const height = getMaxHeight(clampValue);
        if (height <= element.clientHeight) {
            clampedText = truncate(getLastChild(element), height);
        }
    }
    return {
        original: originalText,
        clamped: clampedText
    };
}

export function trim(str) {
    if (typeof str === 'string') {
        return str.replace(/(^\s*)|(\s*$)/g, '');
    }
    return null;
}
export function upperCaseFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function removeEmptyContents(content = [], rootConfig = []) {
    for (let i = 0; i < content.length; i++) {
        const item = content[i];
        if (
            item.type !== 'component' &&
            (!item.content || item.content.length === 0)
        ) {
            content.splice(i, 1);
            i--;
            removeEmptyContents(rootConfig, rootConfig);
        }
        removeEmptyContents(item.content, rootConfig);
    }
}

export function updateAliasComponentsName(com) {
    if (/mornignstar-commodities-watchlist/.test(com.componentName)) {
        com.componentName = com.componentName.replace(
            /mornignstar-commodities-watchlist/,
            'mwc-markets-commodity'
        );
    } else if (/mornignstar/.test(com.componentName)) {
        com.componentName = com.componentName.replace(
            /mornignstar/,
            'morningstar'
        );
    }
    return com;
}

export function getWorksheetSecurityId(security) {
    return (
        security.securityId ||
        security.performanceId ||
        security.identifier ||
        security.instrument
    );
}
