const scrollbarSize = 14;

const componentsType = [
    {
        componentType: 'markets-components-svgchart',
        type: 'component',
        title: 'Advanced Chart',
        showSymbol: true,
        showAutocomplete: true,
        groupName: 'Markets',
        showSettingIcon: false,
        /* eslint-disable */
        desc:
            "Advanced Chart provides end users the full suite of capabilities in analyzing security's price movement (both intraday and full history), performance growth, candlesticks, fundamental ratios, technical indicators, in coupled with drawings and other data points.",
        /* eslint-enable */
        componentState: {
            preload: false,
            updateLastAccessTime: false,
            charts: {
                mainChart: {
                    selectionMode: 'off'
                }
            }
        }
    },
    {
        componentType: 'mwc-markets-forex',
        type: 'component',
        title: 'Forex',
        groupName: 'Markets',
        showSettingIcon: true,
        desc:
            'Forex component shows different view of intraday cross rates between popular currencies. It applies conditional formatting to indicate the direction of change in the exchange rate compare to previous close. Different currencies can be selected to populate the component.',
        componentState: {
            updateLastAccessTime: false,
            showSetting: false
        }
    },
    {
        componentType: 'mwc-markets-gla',
        type: 'component',
        title: 'Gainers, Losers & Actives',
        showSymbol: false,
        groupName: 'Markets',
        desc:
            'Top Gainers/Losers/Actives component displays the top 10 securities with the biggest price increase, reduction and trading volume respectively. The list is updated on an intraday basis, for the designated exchange, index or composites.',
        componentState: {
            updateLastAccessTime: false,
            scrollbarSize,
            showSetting: false
        }
    },
    /* eslint-disable */
    // {
    //     componentType: 'mwc-markets-regulatory',
    //     type: 'component',
    //     componentState: {
    //         showSetting: false,
    //         rowsPerPage: 50
    //     },
    //     title: 'Regulatory Filings',
    //     groupName: 'News',
    //     showSettingIcon: false,
    //     showAutocomplete: true,
    //     showSymbol: true,
    //     
    //     desc:
    //         'Regulatory Filings component provides comprehensive list of filings documents from prospectuses to annual reports related to investments. Morningstar Global Document Library Filings documents are automatically collected from sources worldwide, including investment firms and public domains (for example, EDGAR, SEDAR, and SEC). By combining more than 20 years of data collection experience with advanced technology, Morningstar delivers accurate and timely documents, enabling them to meet regulatory requirements as well as deliver excellent service to investors.'
    //     
    // },
    /* eslint-enable */
    {
        componentType: 'mwc-markets-research',
        type: 'component',
        title: 'Quantitative Research',
        showSymbol: true,
        groupName: 'Research',
        showAutocomplete: true,
        desc:
            'Quantitative Research component provides Morningstar quantitative research for specific securities at a glance. It covers from quantitative ratings, real time/delayed prices, to fair value comparison, moat, valuation, financial health and uncertainty analysis in a quick summary view.',
        componentState: {
            updateLastAccessTime: false,
            scrollbarSize,
            showSetting: false
        }
    },
    {
        componentType: 'mwc-markets-sector-heatmap',
        type: 'component',
        title: 'Morningstar Sector Heatmap',
        showSymbol: false,
        groupName: 'Markets',
        showSettingIcon: true,
        desc:
            'Morningstar Sector Heatmap component allows you to quickly view and analyze intraday and historical exchange data for Morningstar Sectors.  The heatmap applies conditional formatting to changes in performance for various time frames so you can quickly identify upward and downward movement for each sector.',
        componentState: {
            updateLastAccessTime: false,
            showSetting: false
        }
    },
    {
        componentType: 'mwc-markets-treasury',
        type: 'component',
        title: 'U.S. Treasury Yield Rates',
        groupName: 'Markets',
        showSettingIcon: true,
        desc:
            'U.S. Treasury Yield Rates component provides the most recent yield and trailing yield values for various Treasury Rate maturities. The chart view will only show the latest yield for each maturity plotted on the yield curve. A table view is available to see the historical yield for the respective maturity.',
        componentState: {
            showSetting: false
        }
    },
    {
        componentType: 'mwc-markets-gmb',
        type: 'component',
        title: 'Global Market Barometer',
        groupName: 'Markets',
        showSettingIcon: false,
        /* eslint-disable */
        desc:
            'Global Market Barometer component displays intraday and historical exchange market data for global markets. The component presents a stylized representation of world equity markets and is not intended to represent exact geographic or political boundaries.<br/><br/>The Morningstar Global Equity Index family powers the market performance when the component is displayed as a map. Performance data is shown by default, but you can select to see Valuation data for the indexes as well. Other options include changing the time period being depicted and switching between a map and a grid.',
        /* eslint-enable */
        componentState: {
            showSetting: false
        }
    },
    {
        componentType: 'mwc-markets-news',
        type: 'component',
        componentState: {
            showSetting: false
        }
    },
    {
        componentType: 'mwc-markets-options',
        type: 'component',
        title: 'Options',
        groupName: 'Markets',
        showSymbol: true,
        showAutocomplete: true,
        showSettingIcon: false,
        /* eslint-disable */
        desc:
            "Option Chain component features a list of quotes for an option organized by a security. It is simply a listing of Security's put and call option strike prices along with their premiums for a given maturity period. When observing option quote, user can simply find an options premium by following the corresponding maturity dates and strike prices.",
        /* eslint-enable */
        componentState: {
            updateLastAccessTime: false,
            scrollbarSize,
            showMenu: false,
            showSetting: false
        }
    },
    {
        componentType: 'mwc-markets-time-sales',
        type: 'component',
        title: 'Time & Sales',
        groupName: 'Markets',
        showAutocomplete: true,
        showExportIcon: true,
        showSettingIcon: false,
        showSymbol: true,
        desc:
            'Time and Sales component is a detailed view of a list of latest trading activity for a particular security which includes trade size, price and time of trade.',
        componentState: {
            updateLastAccessTime: false,
            showMenu: false,
            showSetting: false
        }
    },
    {
        componentType: 'mwc-markets-quote',
        type: 'component',
        title: 'Quote',
        groupName: 'Markets',
        showAutocomplete: true,
        showSymbol: true,
        desc:
            'Quote component allows you access and analyze extensive intraday exchange market data and fundamental data for investment in focus.',
        componentState: {
            updateLastAccessTime: false,
            showPrePost: false,
            showSetting: false
        }
    },
    {
        componentType: 'markets-components-minichart',
        type: 'component',
        title: 'Simple Chart',
        groupName: 'Markets',
        showSettingIcon: false,
        showAutocomplete: true,
        showSymbol: true,
        desc:
            'Simple Chart allows you to see a quick view of intraday or historical performance for a single investment in focus.',
        componentState: {
            updateLastAccessTime: false
        }
    },
    {
        componentType: 'mwc-markets-watchlist',
        type: 'component',
        componentState: {
            focusable: true,
            updateLastAccessTime: false,
            settingLayout: 'header',
            scrollbarSize,
            showSetting: false
        }
    },
    {
        componentType: 'mwc-markets-commodity',
        aliasComponentName: 'morningstar-commodities-watchlist',
        type: 'component',
        title: 'Commodities',
        showSettingIcon: false,
        showAutocomplete: false,
        showSymbol: false,
        groupName: 'Markets',
        desc:
            'Commodity component displays commodity trading prices for oil, natural gas, gold, silver, wheat, corn and more on the U.S. Commodities & futures market. It provides a quick view for investors to monitor the commodity market condition group by categories.',
        componentState: {
            focusable: true,
            updateLastAccessTime: false,
            settingLayout: 'header',
            scrollbarSize,
            showSetting: false,
            enableGroup: true
        },
        useDefaultSettings: true
    },
    {
        componentType: 'mwc-markets-model-portfolio',
        type: 'component',
        componentState: {
            focusable: true,
            updateLastAccessTime: false,
            settingLayout: 'header',
            scrollbarSize,
            showSetting: false
        }
    },
    {
        componentType: 'mwc-markets-report',
        type: 'component',
        componentState: {},
        showPrintIcon: true
    },
    {
        componentType: 'mwc-markets-barometer',
        type: 'component',
        title: 'U.S. Market Barometer',
        groupName: 'Markets',
        showSettingIcon: false,
        desc:
            'Morningstar US Market Barometer component provides a quick view to analyze intraday and historical exchange market data for the U.S. Markets based on security style and size. The heatmap applies conditional formatting to changes in performance for various time frames to quickly view upward and downward movement for each "style box".',
        componentState: {
            updateLastAccessTime: false,
            showSetting: false
        }
    },
    {
        componentType: 'mwc-markets-cryptocurrency',
        type: 'component',
        title: 'Cryptocurrency',
        groupName: 'Markets',
        showSettingIcon: true,
        desc:
            'Cryptocurrency component shows different views of intraday cross rates between popular cryptocurrencies and currencies. It applies conditional formatting to indicate the direction of change in the rates compared to the previous close.',
        componentState: {
            updateLastAccessTime: false,
            showSetting: false
        }
    },
    {
        componentType: 'mwc-markets-qualitative-research',
        type: 'component',
        title: 'Analyst Research',
        showSymbol: false,
        groupName: 'Research',
        showAutocomplete: false,
        showSettingIcon: false,
        disabledDrag: true,
        desc:
            'Analysts Research component provides exclusive qualitative research compiled by the in-house Analyst team. It focuses on the long-term outlook of the investment by evaluating the security as a business based on economic moats, or sustainable competitive advantages.',
        componentState: {
            scrollbarSize,
            showSetting: false
        }
    }
];

export default componentsType;
