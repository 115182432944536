<template>
    <section :class="namespaceClass('bodyer')">
        <Workbook />
    </section>
</template>

<script>
import Workbook from './workbook/Workbook.vue';
export default {
    name: 'Bodyer',
    components: {
        Workbook
    }
};
</script>
