<script>
import { guid } from '../util';
export default {
    methods: {
        namespaceClass(cls) {
            const namespace = 'mc-app';
            if (!cls) {
                return namespace;
            }
            let classes = [];
            const nsClasses = [];
            if (typeof cls === 'string') {
                classes.push(cls);
            } else if (cls.length > 0) {
                classes = classes.concat(cls);
            }
            classes.forEach(function(c) {
                nsClasses.push(`${namespace}-${c}`);
            });
            return nsClasses.join(' ');
        },
        mdsIcon(icon) {
            return `#${icon}`;
        },
        guid() {
            return guid();
        }
    }
};
</script>
