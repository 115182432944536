<template>
    <div :class="[namespaceClass('home'), 'mds-container--' + theme]">
        <section :class="classes">
            <markets-ui-alert
                :class="[namespaceClass('promt-alert'), '']"
                :message="notification.message"
                :visible="notification.visible"
                :dismissible="notification.dismissible"
                :type="notification.type"
                :skin="skin"
                @close="notificationClosed"
            ></markets-ui-alert>
            <Header />
            <Bodyer />
            <GlobalSettings />
            <markets-ui-dialog
                @click="clickDialog"
                :visible="dialog.visible"
                :reject="dialog.reject"
                :cancel="dialog.cancel"
                :resolve="dialog.resolve"
                :title="dialog.title"
                :checkbox="dialog.checkbox"
                :message="dialog.message"
                :skin="skin"
                action-required
            >
                <template v-if="isNoEntiDialog" slot="supplemental-content">
                    <div
                        :class="[
                            'mds-dialog__message',
                            namespaceClass('fail-holdings')
                        ]"
                        :title="dialog.failHoldings"
                    >
                        {{ dialog.failHoldings }}
                    </div>
                    <div class="mds-dialog__message">
                        Do you still want to proceed?
                    </div>
                </template>
                <template v-if="isWatchlistDialog" slot="supplemental-content">
                    <div class="mds-dialog__message">
                        <form
                            class="mds-form mds-form--medium-layout"
                            :class="skinStyle"
                        >
                            <markets-ui-fieldset
                                :class="
                                    namespaceClass('add-new-watchlist-fieldset')
                                "
                            >
                                <markets-ui-radio-button
                                    :skin="skin"
                                    size="medium"
                                    :class="
                                        existedWatchlists.length
                                            ? ''
                                            : namespaceClass('radio--invisual')
                                    "
                                    :label="radioButtons.new.label"
                                    :value="radioButtons.new.value"
                                    :name="radioButtons.new.name"
                                    :checked="radioButtons.new.checked"
                                    @change="
                                        changeWatchlistType(radioButtons.new)
                                    "
                                >
                                </markets-ui-radio-button>
                            </markets-ui-fieldset>
                            <div
                                class="mds-form__field-group"
                                :class="[
                                    namespaceClass('dialog-group'),
                                    existedWatchlists.length
                                        ? ''
                                        : namespaceClass(
                                              'dialog-group--less-padding'
                                          )
                                ]"
                            >
                                <label class="mds-form__label"
                                    >Watchlist Name</label
                                >
                                <input
                                    class="mds-form__input"
                                    type="text"
                                    v-model="dialog.watchlistName"
                                    @focus="onFocus($event, radioButtons.new)"
                                    ref="input"
                                />
                                <span
                                    class="mds-form__field-error"
                                    role="alert"
                                    v-if="!!dialog.errorMsg"
                                >
                                    <span
                                        class="mds-form__field-error-text"
                                        :class="namespaceClass('error-message')"
                                    >
                                        {{ dialog.errorMsg }}
                                    </span>
                                </span>
                            </div>
                            <markets-ui-fieldset>
                                <markets-ui-radio-button
                                    :skin="skin"
                                    size="medium"
                                    :label="radioButtons.existing.label"
                                    :value="radioButtons.existing.value"
                                    :name="radioButtons.existing.name"
                                    :checked="radioButtons.existing.checked"
                                    v-if="existedWatchlists.length"
                                    @change="
                                        changeWatchlistType(
                                            radioButtons.existing
                                        )
                                    "
                                />
                            </markets-ui-fieldset>
                            <div
                                class="mds-form__field-group"
                                v-if="existedWatchlists.length"
                                :class="namespaceClass('dialog-group')"
                            >
                                <div
                                    class="mds-form__select"
                                    :class="namespaceClass('dialog-select')"
                                >
                                    <select
                                        class="mds-form__select-input"
                                        ref="select"
                                        @focus="
                                            onFocus(
                                                $event,
                                                radioButtons.existing
                                            )
                                        "
                                        v-model="selectWatchlist"
                                    >
                                        <option
                                            class="mds-form__select-option"
                                            v-for="(item,
                                            key) in existedWatchlists"
                                            :key="key"
                                            :value="item.id"
                                        >
                                            {{ item.name }}
                                        </option>
                                    </select>
                                    <div
                                        class="mds-form__select-visual-wrap"
                                    ></div>
                                    <span
                                        class="mds-form__select-open-indicator"
                                    >
                                        <svg
                                            class="mds-icon mds-form__select-open-icon"
                                            aria-hidden="true"
                                        >
                                            <use
                                                :xlink:href="
                                                    mdsIcon('caret-down--s')
                                                "
                                            ></use>
                                        </svg>
                                    </span>
                                </div>
                            </div>
                        </form>
                    </div>
                </template>
            </markets-ui-dialog>
        </section>
        <markets-ui-loading
            :visible="loading"
            :skin="skin"
            showOverlay
        ></markets-ui-loading>
    </div>
</template>

<script>
import $ from 'jquery';
import Header from '../components/Header';
import Bodyer from '../components/Bodyer';
import GlobalSettings from '../components/GlobalSettings';
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import 'mwc-markets-autocomplete';
import utils, { trim } from '../util';
import {
    generateName,
    validateWatchlistName,
    validateWatchlistsLength
} from '../common/';
export default {
    name: 'Home',
    components: {
        Header,
        Bodyer,
        GlobalSettings
    },
    computed: {
        ...mapGetters('settings', ['skin', 'skinStyle']),
        ...mapState('settings', [
            'theme',
            'duplicateTickersWarning',
            'overflowMaxTickerCountWarning'
        ]),
        ...mapState('watchlist', ['watchlists']),
        ...mapState('application', [
            'notification',
            'loading',
            'globalConfigLoaded'
        ]),
        classes() {
            const cls = [this.namespaceClass('home-content')];
            if (!this.showContent) {
                cls.push(this.namespaceClass('home-hiden'));
            }
            return cls;
        },
        existedWatchlists() {
            return this.watchlists.filter(w => {
                return !/^readonly/.test(w.id);
            });
        },
        isWatchlistDialog() {
            return this.dialog.type === 'create_watchlist';
        },
        isNoEntiDialog() {
            return this.dialog.type === 'no_enti';
        },
        isDuplicateDialog() {
            return this.dialog.type === 'duplicate';
        },
        watchlistType() {
            let type = 'new';
            for (let key in this.radioButtons) {
                if (this.radioButtons[key].checked) {
                    type = key;
                    break;
                }
            }
            return type;
        }
    },
    data() {
        return {
            dialog: {
                message: '',
                errorMsg: '',
                visible: false,
                type: ''
            },
            radioButtons: {
                new: {
                    label: 'Create new watchlist',
                    name: 'new',
                    value: 'new',
                    checked: true
                },
                existing: {
                    label: 'Add to existing watchlist',
                    name: 'existing',
                    value: 'existing',
                    checked: false
                }
            },
            showContent: false,
            existingDropdownWidth: 290,
            selectWatchlist: ''
        };
    },

    watch: {
        'notification.visibleTime'() {
            clearTimeout(this.autoDismissTimer);
            if (this.notification.type === 'success') {
                this.autoDismissTimer = setTimeout(() => {
                    this.notificationClosed();
                }, 3000);
            }
        }
    },
    beforeDestroy() {
        this.dialog.visible = false;
        this.$body.removeClass('homepage');
    },
    created() {
        this.$body = $(document.body).addClass('homepage');
        Promise.all([this.getWatchlists(), this.getSettings()])
            .then(res => {
                const settings = res[1];
                this.setGlobalConfigLoaded(true);
                this.setSelectWatchlist();
                if (settings.holdings) {
                    if (settings.watchlistName) {
                        this.watchlistName = settings.watchlistName;
                    }
                    this.checkHoldings(settings.holdings).then(data => {
                        const failArray = data
                            .filter(item => !item.status)
                            .map(t => t.queryKey);
                        this.holdings = data
                            .filter(item => item.status)
                            .map(t => t.ticker);
                        if (failArray && failArray.length) {
                            this.dialog = {
                                visible: true,
                                failHoldings: failArray.join(','),
                                type: 'no_enti',
                                title: 'Warning!',
                                errorMsg: '',
                                message:
                                    'You are not entitled to access the following securities and they will not be added to your watchlist.',
                                resolve: {
                                    label: 'OK'
                                },
                                cancel: {
                                    label: 'Cancel'
                                }
                            };
                        } else {
                            this.createWatchlistDialog();
                        }
                    });
                } else {
                    this.showContent = true;
                }
            })
            .catch(error => {
                this.showContent = true;
            });
        this.setLoading(true);
        this.$eventBus.$on(
            this.$EVENTS.IMPORT_COMPLETE,
            (importSecurity, componentId) => {
                this.holdings = importSecurity;
                this.setSelectWatchlist();
                this.createWatchlistDialog();
                if (componentId) {
                    const target = this.watchlists.filter(w => {
                        return w.id === componentId;
                    });
                    if (target.length) {
                        this.$nextTick(() => {
                            this.$refs['select'].focus();
                            this.selectWatchlist = target[0].id;
                        });
                    }
                }
            }
        );
        this.isChartPrinting = false; // isChartPrinting is judge chart print or browser print.
        this.$eventBus.$on(this.$EVENTS.BEFORE_PRINT, status => {
            this.isChartPrinting = status.isPrinting;
        });
        this.addPrintListener();
    },
    methods: {
        ...mapMutations('application', [
            'setNotification',
            'setLoading',
            'setGlobalConfigLoaded'
        ]),
        ...mapMutations('worksheet', ['openWatchlists']),
        ...mapActions('settings', ['getSettings', 'saveSettings']),
        ...mapActions('workbook', ['saveWorkbooks']),
        ...mapActions('watchlist', [
            'getWatchlists',
            'checkHoldings',
            'watchlistList',
            'addWatchlist',
            'updateWatchlist'
        ]),
        ...mapMutations('settings', ['setDirty']),
        notificationClosed() {
            const note = this.notification;
            note.visible = false;
            this.setNotification(note);
        },
        createWatchlistDialog() {
            this.dialog = {
                visible: true,
                type: 'create_watchlist',
                message: '',
                errorMsg: '',
                watchlistName:
                    this.watchlistName ||
                    generateName('Watchlist', this.watchlists),
                cancel: {
                    label: 'Cancel'
                },
                resolve: {
                    label: 'OK'
                }
            };
        },
        onFocus(e, data) {
            if (data.name === 'new') {
                e.target.select();
            }
            this.changeWatchlistType(data);
        },
        addPrintListener() {
            const caculatePrintWidth = () => {
                this.containers.forEach(container => {
                    const resizeWidth =
                        (container.width / this.currentDocumentWidth) *
                            (this.printDocumentWidth || 1152) -
                        2;
                    container.$el.css({ width: resizeWidth });
                });
            };
            const self = this;
            window.matchMedia('print').addListener(function(mql) {
                this.printDocumentWidth = window.document.body.clientWidth;
                if (self.isChartPrinting) {
                    return;
                }
                if (mql.matches) {
                    caculatePrintWidth();
                }
            });
            //support IE & firefox for listen print function
            window.addEventListener('beforeprint', event => {
                this.containers = [];
                if (this.isChartPrinting) {
                    return;
                }
                const userArge = navigator.userAgent;
                $(
                    '.lm_item_container, .lm_stack, .lm_row, .lm_root, .lm_content, .lm_column, .mc-app-component-container'
                ).each((index, el) => {
                    const $el = $(el);
                    this.containers.push({
                        $el,
                        style: $el.attr('style'),
                        width: $el.width()
                    });
                });

                this.currentDocumentWidth = window.document.body.clientWidth;

                if (
                    /Firefox|Edge/gi.test(userArge) ||
                    /(msie\s|Trident.*rv:)([\w.]+)/g.test(userArge)
                ) {
                    caculatePrintWidth();
                }
            });
            window.addEventListener('afterprint', () => {
                this.containers.forEach((container, idx) => {
                    const style = container.style ? container.style : '';
                    container.$el.attr('style', style);
                });
                this.isChartPrinting = false;
            });
        },
        clickDialog(type, data) {
            if (type === 'cancel') {
                this.holdings = [];
                this.dialog.visible = false;
                this.showContent = true;
                this.setSelectWatchlist();
            } else if (type === 'resolve') {
                if (this.dialog.type === 'no_enti') {
                    this.createWatchlistDialog();
                } else if (this.dialog.type === 'duplicate') {
                    this._duplicateDialogHandler(data);
                } else if (this.dialog.type === 'overflowMaxTickerCount') {
                    this._overflowMaxTickerCountDialogHandler(data);
                } else if (this.dialog.type === 'create_watchlist') {
                    this._createWatchlist();
                }
            }
        },
        _duplicateDialogHandler(data) {
            this.dialog.visible = false;
            // pop overflow dialog after click OK
            if (this.dialog.overflowMaxTickerCountDialog) {
                this.dialog = this.dialog.overflowMaxTickerCountDialog;
            } else {
                this.updateWatchlistFn();
            }
            if (data.checked) {
                this.setDirty(true);
                this.$requestManager.add(
                    () =>
                        this.saveSettings({
                            duplicateTickersWarning: false
                        }),
                    'saveSettings'
                );
            }
        },
        _overflowMaxTickerCountDialogHandler(data) {
            this.dialog.visible = false;
            this.updateWatchlistFn();
            if (data.checked) {
                this.setDirty(true);
                this.$requestManager.add(
                    () =>
                        this.saveSettings({
                            overflowMaxTickerCountWarning: false
                        }),
                    'saveSettings'
                );
            }
        },
        changeWatchlistType(data) {
            for (let key in this.radioButtons) {
                this.radioButtons[key].checked = false;
            }
            data.checked = true;
        },
        _createWatchlist() {
            const tickers = this.holdings.join(',');
            const name = this.dialog.watchlistName;
            if (this.watchlistType === 'new') {
                this._createNewWatchlist(tickers, name);
            } else if (this.watchlistType === 'existing') {
                this._updateWatchlist(tickers, name);
            }
            this.setSelectWatchlist();
        },
        _createNewWatchlist(tickers, name) {
            //add a new watchlist
            this.dialog.errorMsg = validateWatchlistsLength(this.watchlists);
            name = trim(name);
            if (!this.dialog.errorMsg) {
                this.dialog.errorMsg = validateWatchlistName(
                    name,
                    this.watchlists
                );
            }
            if (this.dialog.errorMsg) {
                return;
            }
            this.addWatchlist({
                tickers,
                name
            })
                .then(data => {
                    this.dialog.visible = false;
                    this._openWatchlists([
                        {
                            name: data.name,
                            id: data.id
                        }
                    ]);
                    this.showContent = true;
                })
                .catch(e => {
                    this.dialog.errorMsg =
                        'There was an error when adding securities to the Watchlist, please try again later.';
                });
        },
        _updateWatchlist(tickers, name) {
            //updateWatchlist
            const targetWatchlist = utils.find(this.watchlists, w => {
                return w.id === this.selectWatchlist;
            });
            const targetWatchlistTicker = targetWatchlist.tickers
                ? targetWatchlist.tickers.split(',')
                : [];
            const duplicatedTickers = [];
            const overflowTickers = [];

            let remainingTickers = [];

            if (!targetWatchlistTicker.length) {
                remainingTickers = this.holdings;
            } else {
                const maxTickerCount = 500;
                this.holdings.forEach((item, index) => {
                    if (targetWatchlistTicker.indexOf(item) !== -1) {
                        duplicatedTickers.push(item);
                    } else {
                        const isOverflow =
                            maxTickerCount >
                            targetWatchlistTicker.length +
                                remainingTickers.length;

                        if (isOverflow) {
                            remainingTickers.push(item);
                        } else {
                            overflowTickers.push(item);
                        }
                    }
                });
            }
            this.updateWatchlistFn = this._getUpdateWatchlistFn(
                remainingTickers,
                targetWatchlist
            );
            this._popUpdateWatchlistDialog(duplicatedTickers, overflowTickers);
        },
        _popUpdateWatchlistDialog(duplicatedTickers, overflowTickers) {
            const popDuplicateDialog =
                duplicatedTickers.length > 0 && this.duplicateTickersWarning;
            const popOverflowMaxTickerCountDialog =
                overflowTickers.length > 0 &&
                this.overflowMaxTickerCountWarning;

            if (popDuplicateDialog && popOverflowMaxTickerCountDialog) {
                this.dialog = this._getDuplicateDialog();
                //save the overflow TickersDialog and popover after click duplicate dialog
                this.dialog.overflowMaxTickerCountDialog = this._getOverflowMaxTickerCountDialog();
            } else if (popDuplicateDialog) {
                this.dialog = this._getDuplicateDialog();
            } else if (popOverflowMaxTickerCountDialog) {
                this.dialog = this._getOverflowMaxTickerCountDialog();
            } else {
                this.updateWatchlistFn.apply(this);
                this.dialog.visible = false;
            }
        },
        _getUpdateWatchlistFn(remainingTickers, targetWatchlist) {
            if (
                utils.isArray(remainingTickers) &&
                remainingTickers.length > 0
            ) {
                // Tracking: number of holdings import to MarketMonitoring.
                this.$appTracker.trackEvent({
                    name: 'watchlist-import-holdings-count',
                    value: remainingTickers.length
                });
            }
            if (remainingTickers.length) {
                let tickers = `${
                    targetWatchlist.tickers
                },${remainingTickers.join(',')}`;
                tickers = tickers.replace(/^,|,$/g, '');
                return function() {
                    this.updateWatchlist({
                        tickers,
                        id: targetWatchlist.id,
                        name: targetWatchlist.name,
                        save: false
                    })
                        .then(data => {
                            this._openWatchlists([
                                {
                                    name: targetWatchlist.name,
                                    id: data.id
                                }
                            ]);
                            this.showContent = true;
                        })
                        .catch(e => {
                            this.showContent = true;
                        });
                };
            } else {
                return function() {
                    this._openWatchlists([
                        {
                            name: targetWatchlist.name,
                            id: targetWatchlist.id
                        }
                    ]);
                    this.showContent = true;
                };
            }
        },
        _openWatchlists(watchlistInfoArray) {
            this.$watchlistManager.trigger(
                'openWatchlists',
                watchlistInfoArray
            );
        },
        _getOverflowMaxTickerCountDialog() {
            return {
                visible: true,
                title: 'Warning!',
                type: 'overflowMaxTickerCount',
                message:
                    /* eslint-disable */
                    "Sorry, you've reached the maximum 500 securities limit, excessive securities will not be added to the Watchlist.",
                /* eslint-enable */

                resolve: {
                    label: 'OK'
                },
                checkbox: {
                    /* eslint-disable */
                    label: "Don't want to see this message again."
                    /* eslint-enable */
                }
            };
        },
        _getDuplicateDialog() {
            return {
                visible: true,
                title: 'Warning!',
                type: 'duplicate',
                message:
                    'Duplicate securities will not be added to the watchlist.',
                resolve: {
                    label: 'OK'
                },
                checkbox: {
                    /* eslint-disable */
                    label: "Don't want to see this message again."
                    /* eslint-enable */
                }
            };
        },
        setSelectWatchlist() {
            if (this.existedWatchlists.length) {
                this.selectWatchlist = this.existedWatchlists[0].id;
            }
        }
    }
};
</script>
