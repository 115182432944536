<template>
    <MODAL
        :width="1200"
        :visible="visible"
        :class="namespaceClass('alert-manager')"
        :title="initedLabels.alert"
        :skin="skin"
    >
        <button
            slot="header-actions"
            class="mds-button mds-button--icon-only mds-popover__trigger"
            data-mds-modal-close
            @click="showSettings"
            ref="settingsButton"
        >
            <svg class="mds-icon mds-button__icon mds-button__icon--left">
                <use :xlink:href="mdsIcon('gear')">
                    <title>Settings</title>
                </use>
            </svg>
        </button>
        <button
            slot="header-actions"
            class="mds-button mds-button--icon-only"
            @click="closeAlertManagement"
        >
            <svg class="mds-icon mds-button__icon mds-button__icon--left">
                <use :xlink:href="mdsIcon('remove')">
                    <title>Close</title>
                </use>
            </svg>
        </button>
        <div
            :class="namespaceClass('alert-container')"
            slot="content"
            ref="alertContainer"
        ></div>
    </MODAL>
</template>
<script>
import labels from '../../assets/metadata/label.json';
import { mapGetters, mapState, mapMutations } from 'vuex';
import utils, { guid } from '../../util';
import alertSoundUrl from '../../assets/media/alert.mp3';
import MODAL from '../ui/modal';
import 'mwc-markets-alert';
export default {
    name: 'AlertManager',
    components: { MODAL },
    data() {
        return {
            visible: false
        };
    },
    computed: {
        ...mapGetters('settings', ['skin']),
        ...mapGetters('account', ['token']),
        ...mapState('settings', [
            'alert_createClicked',
            'alertType',
            'dataType',
            'notificationSound',
            'notificationType'
        ]),
        ...mapState('application', ['globalConfigLoaded', 'paths']),
        initedLabels() {
            return labels.en;
        }
    },
    watch: {
        skin(value) {
            if (value && this.alertComponent) {
                this.alertComponent.skin = value;
            }
        },
        dataType(value) {
            if (value && this.alertComponent) {
                this.alertComponent.dataType = value;
            }
        },
        visible(value) {
            if (value && this.alertComponent) {
                this.alertComponent.refresh();
            }
        }
    },
    created() {
        this.globalConfigLoadedWatch = this.$watch('globalConfigLoaded', () => {
            if (this.globalConfigLoaded) {
                this.initAlert();
            }
            this.globalConfigLoadedWatch();
        });
        this.$eventBus.$on(this.$EVENTS.CREATE_ALERT, security => {
            if (this.alertComponent && security) {
                this.alertComponent.createAlert(security);
            }
        });
        this.$eventBus.$on(this.$EVENTS.SHOW_ALERTMANAGER, () => {
            this.visible = true;
        });
    },
    methods: {
        ...mapMutations('settings', ['setSettings', 'setDirty']),
        closeAlertManagement() {
            this.visible = false;
        },
        showSettings(e) {
            this.alertComponent.toggleSettings(e);
        },
        toggleSliderAlerts() {
            this.alertComponent.toggleSliderAlerts();
        },
        initAlert() {
            const config = utils.extend(
                true,
                {
                    skin: this.skin,
                    dataType: this.dataType,
                    sliderContainer: document.body,
                    soundUrl: alertSoundUrl,
                    autoHeight: false,
                    updateLastAccessTime: false,
                    notificationSound: this.notificationSound,
                    notificationType: this.notificationType,
                    overridePaths: this.paths
                },
                this.token
            );
            const customElement = document.createElement('mwc-markets-alert');
            const mwcId = `alert-${guid()}`;
            customElement.setAttribute('mwc-id', mwcId);
            customElement.config = {
                settings: config
            };
            this.$refs.alertContainer.appendChild(customElement);
            this.alertComponent = customElement;
            customElement.skin = this.skin;
            this.alertComponent.addEventListener(
                'unread-alerts-count-changed',
                this.unreadCountChanged
            );
            this.alertComponent.addEventListener('manage-clicked', this.show);
            this.alertComponent.addEventListener(
                'subscribe-succeeded',
                this.show
            );
            this.alertComponent.addEventListener(
                'settings-changed',
                this.settingsChanged
            );
        },
        unreadCountChanged(e) {
            const count = e.detail[0];
            this.$emit('unread-count-changed', count);
        },
        show() {
            this.visible = true;
        },

        settingsChanged(e) {
            const settings = e.detail[0];
            if (settings.alertType !== this.alertType) {
                const saveSettings = {
                    alertType: settings.alertType
                };
                this.setSettings(saveSettings);
                this.setDirty(true);
            }
        }
    }
};
</script>
