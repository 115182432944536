import api from '../../data/api';
import utils, { extendAuthParams } from '../../util';
import { getAppTrackerSingleInstance } from '../../componentsManager/tracking';

const state = {
    isEditing: false,
    theme: 'default',
    region: 'US',
    duplicateTickersWarning: true,
    overflowMaxTickerCountWarning: true,
    dataType: 'pull',
    autoSave: true,
    autoSaveInterval: 5,
    isDirty: false,
    alertType: 'active',
    functions: '',
    sessionExpiration: null,
    showVideo: true,
    notificationSound: 'off',
    notificationType: 'screenOnly',
    userGuide: '',
    accountId: null // it is used to control if enable dowjones news
};

const getters = {
    skin(state) {
        if (state.theme === 'black') {
            return 'dark';
        } else {
            return state.theme;
        }
    },
    skinStyle(_, getters) {
        return `mds-container--${utils.getSkinSuffix(getters.skin)}`;
    },
    isFeatureEnable: state => code => {
        return utils.inArray(state.functions.split(','), code) > -1;
    }
};
const actions = {
    async getSettings({ commit, rootGetters }) {
        const token = rootGetters['account/token'];
        const url = rootGetters['application/url']('@web/ra/config/list');
        return api
            .getGlobalSettings(url, extendAuthParams({}, token))
            .then(settings => {
                const tracker = getAppTrackerSingleInstance();
                if (settings.theme) {
                    // Tracking: number of user using white vs black theme.
                    tracker.trackEvent({
                        name: 'theme-select',
                        value: settings.theme
                    });
                }
                if (settings.autoSave === true) {
                    // Tracking: number of user set with Auto-save feature.
                    tracker.trackEvent({
                        name: 'auto-save-select'
                    });
                }
                commit('setSettings', settings);
                return settings;
            })
            .catch(e => {
                commit(
                    'application/setNotification',
                    {
                        visible: true,
                        type: 'error',
                        dismissible: true,
                        message: 'Sorry, get global settings failed.'
                    },
                    { root: true }
                );
            });
    },
    async saveSettings({ commit, rootGetters, state }, settings) {
        if (!state.isDirty) {
            return;
        }
        const token = rootGetters['account/token'];
        const url = rootGetters['application/url']('@web/ra/config/save');
        settings = utils.extend(true, {}, state, settings);
        const settingsToBeSaved = {
            profile: {
                theme: settings.theme,
                dataType: settings.dataType,
                autoSave: settings.autoSave,
                duplicateTickersWarning: settings.duplicateTickersWarning,
                overflowMaxTickerCountWarning:
                    settings.overflowMaxTickerCountWarning,
                autoSaveInterval: settings.autoSaveInterval
            }
        };
        commit('setSettings', settings);
        settingsToBeSaved.profile = JSON.stringify(settingsToBeSaved.profile);
        return api
            .saveGlobalSettings(url, extendAuthParams(settingsToBeSaved, token))
            .then(() => {
                commit('setDirty', false);
            })
            .catch(error => {
                commit(
                    'application/setNotification',
                    {
                        visible: true,
                        type: 'error',
                        message: 'There was an error while saving.',
                        dismissible: true
                    },
                    { root: true }
                );
                return Promise.reject(error);
            });
    }
};
const mutations = {
    setEditStatus(state, status) {
        state.isEditing = !!status;
    },
    setDirty(state, status) {
        state.isDirty = !!status;
    },
    setSettings(state, settings) {
        const keys = Object.keys(settings);
        for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            if (settings[key] !== undefined) {
                state[key] = settings[key];
            }
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
