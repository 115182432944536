import utils, { trim } from '../util/';
export const MAX_WATCHLIST_MSG =
    'Sorry, you have reached the maximum watchlist limit. You need to delete a saved watchlist before creating a new one.';
export const ONE_RESEARCH_MSG =
    'Sorry, only one Analyst Research is available within one worksheet.';

export const LOGIN_TIME_KEY = 'markets-components-app-login-time';
export const ACCOUNT_KEY = 'markets-components-app-account';

export function getSaveDialog() {
    return {
        visible: false,
        message:
            'Your worksheets contain unsaved data, do you want to save the changes?',
        reject: {
            /* eslint-disable */
            label: "Don't save"
            /* eslint-enable */
        },
        cancel: {
            label: 'Cancel'
        },
        resolve: {
            label: 'Save'
        }
    };
}

export function generateName(prefix, existedDataArray, isWorkSheet) {
    let id = '';
    const regString = `^${prefix}`;
    const reg = new RegExp(regString);
    const targetItem = existedDataArray
        .filter(item => {
            const nameInfo = item.name.match(reg);
            if (nameInfo) {
                return item;
            }
        })
        .sort((a, b) => {
            const m = a.name.match(/\d+$/gi) ? +a.name.match(/\d+$/gi)[0] : 0;
            const n = b.name.match(/\d+$/gi) ? +b.name.match(/\d+$/gi)[0] : 0;
            return m < n ? -1 : 1;
        });
    if (targetItem.length) {
        const regNum = /(\d+)$/g;
        const maxNum = regNum.exec(targetItem[targetItem.length - 1].name);
        if (isWorkSheet && !maxNum) {
            id = 1;
        } else {
            id = maxNum && maxNum[1] ? +maxNum[1] + 1 : 1;
        }
    } else if (!isWorkSheet || prefix === 'Untitled') {
        id = 1;
    }
    return `${prefix} ${id}`;
}

export function validateInput(text) {
    if (/^[A-Za-z0-9/\\()&.\-+_:\s]+$/.test(text)) {
        return true;
    }
    return false;
}

export function validateWatchlistName(name, watchLists) {
    let errorMsg = '';
    name = trim(name);
    if (name === '') {
        /* eslint-disable */
        errorMsg = "Your watchlist name can't be blank.";
        /* eslint-enable */
    } else if (name.length >= 30) {
        /* eslint-disable */
        errorMsg = "You've reached the 30-character limit.";
        /* eslint-enable */
    } else if (!validateInput(name)) {
        errorMsg =
            'Your watchlist names can only include letters, numbers, and the following special characters: / \\ ( ) & . - + _ :';
    } else if (watchLists.some(item => item.name === name)) {
        errorMsg =
            'You already have a watchlist with this name. Please enter a different name.';
    }
    return errorMsg;
}

export function validateWatchlistsLength(watchlists) {
    const len = watchlists.length;
    const readonlyLen = watchlists.filter(w => {
        return (w.id + '').indexOf('readonly') > -1;
    }).length;
    let errorMsg = '';
    if (len - readonlyLen > 14) {
        errorMsg = MAX_WATCHLIST_MSG;
    }
    return errorMsg;
}

// Below properties of component doesn't need to store
export const COMPONENT_PROPERTIES = [
    'title',
    'showTitle',
    'showSymbol',
    'isClosable',
    'showCloseIcon',
    'showSettingIcon',
    'showAutocomplete',
    'showExportIcon',
    'showPopoutIcon',
    'showImportIcon',
    'showPrintIcon',
    'desc',
    'disableComponentDrag',
    'useDefaultSettings'
];

export const WATCHLIST_TYPES = {
    STATIC: 'static',
    READ_ONLY: 'readonly',
    CUSTOM: 'custom'
};

export function updateComponentState(action, content = []) {
    content.forEach((item, index) => {
        if (item.type === 'component') {
            if (action === 'remove') {
                item.componentState = null;
                // backforwad, users used to store symbols and holdingsId at the same time
                if (
                    item.componentSettings &&
                    (item.watchlistType === WATCHLIST_TYPES.STATIC ||
                        item.componentSettings.holdingsId)
                ) {
                    delete item.componentSettings.symbol;
                }
                delete item.componentState;
            } else {
                item.componentState = item.componentState || {};
                // backforwad, users used to store settings in componentState, and saved linkid
                delete item.componentState.linkId;
                item.componentSettings = utils.extend(
                    true,
                    item.componentSettings,
                    item.componentState
                );
                item.componentState.linkId = `${
                    item.componentName
                }_${utils.guid()}`;
            }
            content[index] = item;
        } else {
            updateComponentState(action, item.content);
        }
    });
}

export function download(url, name) {
    let eleLink = document.createElement('a');
    eleLink.style.display = 'none';
    eleLink.href = url;
    eleLink.target = '_blank';
    if (name) {
        eleLink.download = name;
    }
    document.body.appendChild(eleLink);
    eleLink.click();
    document.body.removeChild(eleLink);
}

export function getEmptyWatchlistConfig() {
    return {
        type: 'component',
        watchlistType: 'custom',
        componentState: {},
        componentSettings: {},
        componentName: 'mwc-markets-watchlist'
    };
}
