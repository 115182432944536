import api from '../../data/api';
// import router from '../../router';
import { extendAuthParams } from '../../util';
import {
    getAppTrackerSingleInstance,
    trackActiveTime
} from '../../componentsManager/tracking';
import { ACCOUNT_KEY, LOGIN_TIME_KEY } from '../../common';
const state = {
    errorCode: '0',
    sessionId: null,
    instid: null,
    env: null,
    sessionTimeout: Number.MAX_SAFE_INTEGER
};

const getters = {
    token(state) {
        return {
            instid: state.instid,
            env: state.env,
            sessionId: state.sessionId
        };
    }
};

const actions = {
    signin({ rootGetters, rootState }) {
        const origin = window.location.origin;
        const targetUrl = encodeURIComponent(
            rootGetters['application/url'](
                `@web/service/signin?targetURL=${origin}&errorURL=${origin}/sign-in-error.html`
            )
        );
        const { host } = rootState['application'].uimSessionManager;
        window.location = `${host}sso/json/msusers/app-login?source=bus0061&targetUrl=${targetUrl}`;
    },
    async checkSignin({ commit, rootGetters, getters, rootState, dispatch }) {
        const url = rootGetters['application/url'](
            '@web/service/queryPermission'
        );
        const token = getters.token;
        const checkSigninFail = () => {
            window.sessionStorage.removeItem(LOGIN_TIME_KEY);
            return false;
        };
        return api
            .checkSignin(url, extendAuthParams({}, token))
            .then(d => {
                if (d.errorCode === '0') {
                    d.env = rootState['application'].environment;
                    d.overridePaths = rootState['application'].paths;
                    if (
                        window.sessionStorage.getItem(LOGIN_TIME_KEY) === null
                    ) {
                        // set login time
                        window.sessionStorage.setItem(
                            LOGIN_TIME_KEY,
                            new Date().getTime()
                        );
                        const tracker = getAppTrackerSingleInstance();
                        // Tracking: user access to MarketMonitoring sign in.
                        tracker.setIdentify({
                            userId: d.userId,
                            instId: d.instid
                        });
                        tracker.trackEvent({
                            name: 'sign-in'
                        });
                    }
                    commit('setAccount', d);
                    return true;
                } else {
                    return checkSigninFail();
                }
            })
            .catch(() => {
                return checkSigninFail();
            });
    },
    signout({ commit, rootGetters, getters, rootState }) {
        trackActiveTime();
        const url = rootGetters['application/url']('@web/service/signout');
        const token = getters.token;
        const { host, clientId } = rootState['application'].uimSessionManager;
        const signout = () => {
            window.isSigningOut = false;
            window.sessionStorage.removeItem(LOGIN_TIME_KEY);
            commit('setAccount', {
                errorCode: '0'
            });
            window.location = `${host}sso/json/logout-browser?returnTo=${encodeURIComponent(
                window.location.origin
            )}&client_id=${clientId}`;
        };
        if (window.isSigningOut !== true) {
            api.signout(url, extendAuthParams({}, token))
                .then(() => {
                    signout();
                })
                .catch(() => {
                    signout();
                });
        }
        window.isSigningOut = true;
    },
    async heartbeat(
        { commit, rootGetters, getters },
        { continueSession = false } = {}
    ) {
        const url = rootGetters['application/url']('@pull/ra/heartbeat');
        const token = getters.token;
        return api
            .heartbeat(
                url,
                extendAuthParams(
                    {
                        updateLastAccessTime: continueSession
                    },
                    token
                )
            )
            .then(res => {
                const timeout = res.attachment && res.attachment.sessionTimeout;
                if (timeout) {
                    commit('setSessionTimeout', timeout);
                }
                return true;
            })
            .catch(() => {
                return true;
            });
    }
};

const mutations = {
    setAccount(
        state,
        { errorCode, sessionId, userId, instid, env, overridePaths }
    ) {
        state.errorCode = errorCode;
        state.sessionId = sessionId;
        state.instid = instid;
        state.userId = userId;
        state.env = env;
        state.overridePaths = overridePaths;
        const tracker = getAppTrackerSingleInstance();
        if (env && sessionId && instid && userId) {
            tracker.setIdentify({
                userId,
                instId: instid
            });
            window.sessionStorage.setItem(
                ACCOUNT_KEY,
                JSON.stringify({
                    env,
                    instid,
                    userId,
                    sessionId,
                    overridePaths
                })
            );
        } else {
            window.sessionStorage.removeItem(ACCOUNT_KEY);
        }
    },
    setErrorCode(state, errorCode) {
        state.errorCode = errorCode;
    },
    setAccountSkin(state, value) {
        const accountInfo = {
            env: state.env,
            instid: state.instid,
            userId: state.userId,
            sessionId: state.sessionId,
            overridePaths: state.overridePaths
        };
        state.skin = value;
        accountInfo.skin = value;
        window.sessionStorage.setItem(ACCOUNT_KEY, JSON.stringify(accountInfo));
    },
    setSessionTimeout(state, value) {
        state.sessionTimeout = value;
    }
};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
