<template>
    <div :class="classes">
        <div class="mds-overlay" v-show="visible"></div>
        <div class="mds-modal__wrapper">
            <section
                role="dialog"
                aria-labelledby="global-setting-modal"
                :class="['mds-modal__container', namespace('__container')]"
                aria-hidden="true"
            >
                <div class="mds-modal__inner">
                    <header class="mds-modal__header">
                        <h1 class="mds-modal__title" id="global-setting-modal">
                            {{ title }}
                        </h1>
                        <div class="mds-modal__actions">
                            <div :class="namespace('__header-actions')">
                                <slot name="header-actions"></slot>
                            </div>
                        </div>
                    </header>
                    <div :class="namespace('__content-header')">
                        <slot name="content-header"></slot>
                    </div>
                    <div
                        :class="['mds-modal__content', namespace('__content')]"
                    >
                        <slot name="content"></slot>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>
<script>
import utils from '../../util';
export default {
    name: 'markets-ui-modal',
    props: {
        width: {
            type: Number,
            default: 900
        },
        visible: {
            type: Boolean,
            default: false
        },
        skin: {
            type: String,
            default: 'default'
        },
        title: {
            type: String,
            default: ''
        }
    },
    computed: {
        classes() {
            let cls = [
                'markets-ui-modal',
                'mds-modal',
                `mds-modal--width-${this.width}px`
            ];
            if (this.visible) {
                cls = [...cls, 'mds-modal--active', 'mds-modal--open'];
            }
            if (this.skin) {
                cls.push(
                    `${this.namespace()}__${utils.getSkinSuffix(this.skin)}`
                );
            }
            return cls;
        }
    },
    created() {
        this.namespace = utils.namespace('ui-modal', false);
    }
};
</script>
<style lang="scss">
$namespace: 'markets-ui-modal';
.#{$namespace} {
    &____header-actions {
        display: flex;
        width: 100%;
        text-align: left;
        margin-top: 5px;
        margin-bottom: 5px;
    }
    &__content-header {
        display: flex;
        width: 100%;
        text-align: left;
        margin-top: 5px;
        margin-bottom: 5px;
    }
    &__container {
        height: calc(100% - 160px);
    }
    &__content {
        height: calc(100% - 60px);
        overflow: hidden;
    }
    &__dark-gray &__content {
        color: #cccccc;
    }
}
</style>
