import Vue from 'vue';
import Vuex from 'vuex';
import application from './modules/application';
import account from './modules/account';
import settings from './modules/settings';
import workbook from './modules/workbook';
import worksheet from './modules/worksheet';
import watchlist from './modules/watchlist';
Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        application,
        account,
        settings,
        workbook,
        worksheet,
        watchlist
    }
});
