import { guid } from '../util/';
import $ from 'jquery';
const Events = {
    TokenExpired: 'token-expired',
    SettingsChanged: 'settings-changed',
    TooltipClick: 'hover-menu-click',
    Destroyed: 'destroyed',
    ExportClick: 'export-click',
    ExportRTDClick: 'export-rtd-click',
    TickerAccepted: 'ticker-accepted',
    BeforePrint: 'before-print'
};
const Types = {
    QUIKR: 'QUIKR',
    MWC: 'MWC',
    CUSTOM: 'CUSTOM'
};
export const Components = {
    AdvancedChart: 'markets-components-svgchart',
    MiniChart: 'markets-components-minichart',
    Watchlist: 'mwc-markets-watchlist',
    Quote: 'mwc-markets-quote',
    Options: 'mwc-markets-options',
    News: 'mwc-markets-news',
    GLA: 'mwc-markets-gla',
    GMB: 'mwc-markets-gmb',
    Report: 'mwc-markets-report',
    Autocomplete: 'mwc-markets-autocomplete',
    Forex: 'mwc-markets-forex',
    Timesales: 'mwc-markets-time-sales',
    Portfolio: 'mwc-markets-model-portfolio'
};

export default class BaseComponent {
    constructor(
        worksheetId,
        _component,
        container,
        theme,
        symbol,
        dataType,
        isActiveComponent,
        callbacks
    ) {
        this.worksheetId = worksheetId;
        this.componentName = _component.componentType;
        const config = _component.componentState;
        this.componentType = this._getComponentType(this.componentName);
        this.container = container;
        this.showSymbol = _component.showSymbol;
        this.id = config.linkId;
        this.dataType = dataType;
        this.isActiveComponent = isActiveComponent;
        this.$container = $(
            '<div class="mc-app-component-container"></div>'
        ).appendTo(container.getElement());
        this.config = this._adapterComponentConfig(config);
        delete this.config.id;
        this.symbol = this._adapterSymbol(symbol, this.config);
        this.theme = this._adapterTheme(theme);
        this.component = this.createComponent() || {};
        this.callbacks = callbacks;
        this._bindEvents();
        this._bindCallbacks(this.callbacks);
        this.autocomplete = document.querySelector(Components.Autocomplete);
    }

    static setToken(value) {
        BaseComponent.token = {};
        BaseComponent.token.env = value.env;
        BaseComponent.token.sessionKey = value.sessionId;
        BaseComponent.token.instid = value.instid;
    }

    static setPaths(overridePaths) {
        BaseComponent.overridePaths = overridePaths;
    }

    static setFrequency(frequency) {
        BaseComponent.frequency = frequency;
    }
    _bindEvents() {
        this.container.on('resize', () => {
            this.setProperty('size', {
                width: this.$container.width(),
                height: this.$container.height()
            });
        });
        if (this.isActiveComponent) {
            this.bindContainerEvent();
        }
        this.container.on('destroy', () => {
            this.destroy();
        });
    }

    bindContainerEvent() {
        ['.lm_gear', '.lm_export', '.lm_print'].forEach(eleType => {
            let $eleMent = this._getElementInStack(eleType);
            const self = this;
            if ($eleMent) {
                $eleMent.unbind('click').bind('click', function(e) {
                    if (self.$container && self.$container.is(':visible')) {
                        self._toggleTypeSettings(e, eleType);
                    }
                });
            }
        });
    }

    _toggleTypeSettings(e, eleType) {
        if (
            this.component &&
            this.component.toggleSettings &&
            eleType === '.lm_gear'
        ) {
            this.component.toggleSettings(e);
        } else if (
            this.component &&
            this.component.toggleExport &&
            eleType === '.lm_export'
        ) {
            this.component.toggleExport(e, this.container._config.title);
        } else if (this.component && eleType === '.lm_print') {
            this.component.quickPrint();
        }
    }

    _bindCallbacks(callbacks = {}) {
        for (let cb in callbacks) {
            this.on(cb, callbacks[cb]);
        }
    }
    _getElementInStack(selector) {
        let elem = this.$container;
        while (elem && elem.length) {
            if (elem.hasClass('lm_stack')) {
                return elem.find(selector);
            }
            elem = elem.parent();
        }
        return null;
    }
    _adapterTheme(theme) {
        if (
            this.componentType === Types.MWC ||
            this.componentType === Types.QUIKR
        ) {
            if (theme === 'black') {
                return 'dark';
            } else if (theme === 'white') {
                return 'default';
            }
        }
        return theme;
    }

    /**
     * Set component config, such as settings and labels.
     * @param config - com from components componentState.
     * @returns {{settings: {}, labels: {} | *}
     * @private
     */
    _adapterComponentConfig(config) {
        if (this.componentType === Types.MWC) {
            // Set labels to component
            const labels = config.labels;
            delete config.labels;
            return {
                settings: $.extend(true, {}, config),
                labels
            };
        } else {
            return $.extend(true, {}, config);
        }
    }
    _getComponentType(component) {
        if (/^mwc-.+/.test(component)) {
            return Types.MWC;
        } else if (/^markets-components.+/.test(component)) {
            return Types.QUIKR;
        } else {
            return Types.CUSTOM;
        }
    }
    _adapterSymbol(symbol, config) {
        if (this.componentType === Types.MWC && this.showSymbol) {
            config.settings = $.extend(config.settings || {}, {
                symbol: symbol
            });
        } else if (this.componentType === Types.QUIKR) {
            config.mainTicker = symbol;
        } else if (this.componentType === Types.CUSTOM) {
            config.symbol = symbol;
        } else if (this.componentName === Components.Watchlist) {
            symbol = config.settings.symbol;
        }
        return symbol;
    }
    createComponent() {
        if (this.componentType === Types.QUIKR) {
            if (window.morningstar.components[this.componentName]) {
                if (this.componentName === Components.AdvancedChart) {
                    this.config = {
                        savedConfig: this.config
                    };
                }
                this.config.height = this.$container.height();
                this.config.skin = this.theme;
                return new window.morningstar.components[
                    this.componentName
                ].createComponent({
                    element: this.$container,
                    initialConfiguration: {
                        instid: BaseComponent.token.instid,
                        env: BaseComponent.token.env,
                        sessionKey: BaseComponent.token.sessionKey,
                        accessToken: BaseComponent.token.accessToken,
                        overridePaths: BaseComponent.overridePaths,
                        component: {
                            configuration: this.config
                        }
                    }
                });
            } else {
                // eslint-disable-next-line no-console
                console.error(
                    `window.morningstar.components[${this.componentName}] is undefined`
                );
            }
        } else if (this.componentType === Types.MWC) {
            const { layout } = this.config.settings; // for report component
            this.config.settings = $.extend(true, {}, this.config.settings, {
                instid: BaseComponent.token.instid,
                env: BaseComponent.token.env,
                sessionId: BaseComponent.token.sessionKey,
                overridePaths: BaseComponent.overridePaths,
                frequency: BaseComponent.frequency,
                height: this.$container.height(),
                skin: this.theme,
                dataType: this.dataType,
                layout: layout || 'mbg',
                tableLayout: 'mbg', // for forex
                autoHeight: false
            });
            this.mwcId = this.componentName + '-' + guid();
            const customElement = document.createElement(this.componentName);
            customElement.setAttribute('mwc-id', this.mwcId);
            customElement.config = this.config;
            this.$container.append(customElement);
            return customElement;
        }
    }

    setProperty(key, value) {
        switch (key) {
            case 'size': {
                if (this.componentType === Types.MWC) {
                    this.component.size = value;
                } else if (
                    this.componentType === Types.CUSTOM &&
                    typeof this.component.resize === 'function'
                ) {
                    this.component.resize(value.width, value.height);
                }
                break;
            }
            case 'symbol': {
                this._setSymbol(value);
                break;
            }
            case 'theme': {
                this.theme = this._adapterTheme(value);
                this.autocomplete.skin = this.theme;
                if (this.componentType === Types.QUIKR) {
                    this.component.setProperty.call(
                        this.component,
                        'skin',
                        this.theme
                    );
                } else if (this.componentType === Types.MWC) {
                    this.component.skin = this.theme;
                }
                break;
            }
            default: {
                if (this.componentType === Types.QUIKR) {
                    this.component.setProperty.call(this.component, key, value);
                } else {
                    this.component[key] = value;
                }
            }
        }
    }
    _setSymbol({ symbol, action }) {
        if (
            this.symbol &&
            symbol
                .split(',')
                .sort()
                .join(',') ===
                this.symbol
                    .split(',')
                    .sort()
                    .join(',')
        ) {
            return;
        }
        if (this.componentType === Types.QUIKR) {
            this.component.setProperty.call(
                this.component,
                'investment',
                symbol
            );
        } else if (this.componentType === Types.MWC) {
            if (
                this.componentName === Components.Watchlist &&
                action === 'add'
            ) {
                this.component.appendSymbol(symbol);
                symbol = symbol + ',' + this.symbol;
            }
            if (this.componentName !== Components.GLA && action !== 'add') {
                this.component.setAttribute('symbol', symbol);
            }
        }
        this.symbol = symbol;
    }

    destroy() {
        if (
            this.componentType === Types.QUIKR ||
            this.componentType === Types.CUSTOM
        ) {
            this.component.destroy && this.component.destroy();
        } else if (this.componentType === Types.MWC) {
            if (
                window.mwc.configuration &&
                window.mwc.configuration.configuration &&
                window.mwc.configuration.configuration.components
            ) {
                delete window.mwc.configuration.configuration.components[
                    this.mwcId
                ];
            }
            if (typeof this.component.unsubscribe === 'function') {
                this.component.unsubscribe();
            }
        }
        this.trigger(Events.Destroyed, {
            worksheetId: this.worksheetId,
            componentId: this.id
        });
        $(document).remove(this.$container);
        /**
         * Destroy mwc-component-report will Infinite loop.
         */
        // for (var f in this) {
        //     if (!f || f === 'destroy') {
        //         continue;
        //     }
        //     this[f] = null;
        //     delete this[f];
        // }
        // delete this;
    }
    on(eventName, callback) {
        const self = this;
        const originalEventName = eventName;
        const newCallback = function() {
            let parameter = Array.prototype.slice.apply(arguments);
            if (self.componentType === Types.MWC) {
                parameter = parameter[0].detail;
            }
            if (originalEventName === Events.SettingsChanged) {
                if (self.componentType === Types.MWC) {
                    parameter = parameter.slice(0, 1);
                    if (
                        self.componentName === Components.Watchlist &&
                        self.config.settings.holdingsId
                    ) {
                        parameter[0].holdingsId =
                            self.config.settings.holdingsId;
                        self.symbol = parameter[0].symbol;
                    }
                } else if (self.componentType === Types.QUIKR) {
                    parameter = [parameter[0].saveConfig];
                }
            }
            if (originalEventName === Events.TooltipClick) {
                parameter = [
                    {
                        view: parameter[0],
                        security: parameter[1]
                    }
                ];
            }
            parameter.unshift(self.id);
            callback.apply(undefined, parameter);
        };
        eventName = this._getEventName(eventName);
        if (eventName) {
            if (this.componentType === Types.QUIKR) {
                this.component.on(eventName, newCallback);
            } else if (this.componentType === Types.MWC) {
                this.component.addEventListener(eventName, newCallback);
            }
        }
    }
    off(eventName) {
        if (this.componentType === Types.QUIKR) {
            this.component.off(eventName);
        } else if (this.componentType === Types.MWC) {
            this.component.removeEventListener(eventName);
        }
    }
    trigger(eventName, args) {
        if (!this.callbacks) {
            return;
        }
        if (eventName === 'marketdata-session-expired') {
            if (this.callbacks[Events.TokenExpired]) {
                this.callbacks[Events.TokenExpired](args);
            }
        } else if (typeof this.callbacks[eventName] === 'function') {
            this.callbacks[eventName](args);
        }
    }
    _getEventName(eventName) {
        if (eventName === Events.TokenExpired) {
            if (this.componentType === Types.QUIKR) {
                eventName = 'apiTokenExpiredCallback';
            }
        }
        if (eventName === Events.SettingsChanged) {
            if (this.componentType === Types.QUIKR) {
                eventName = 'configChange';
            }
        }
        if (eventName === Events.TooltipClick) {
            eventName = 'hover-menu-click';
        }
        if (eventName === Events.TickerAccepted) {
            if (this.componentType === Types.QUIKR) {
                eventName = 'tickerAccepted';
            }
        }
        if (eventName === Events.BeforePrint) {
            if (this.componentType === Types.QUIKR) {
                eventName = 'beforePrint';
            }
        }
        return eventName;
    }
}
