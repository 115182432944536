<template>
    <section :class="namespaceClass('signin')">
        <markets-ui-loading :visible="true" size="large" />
        <h2>Redirecting...</h2>
    </section>
</template>
<script>
import { mapActions } from 'vuex';
export default {
    name: 'SignIn',
    created() {
        this.signin();
    },
    methods: {
        ...mapActions('account', ['signin'])
    }
};
</script>
