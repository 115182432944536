import 'document-register-element';
import Vue from 'vue';
import router from './router';
import App from './App.vue';
import store from './store/index';
import mixins from './mixins/Mixins';
import watchlistManger from './componentsManager/watchlist-manager';
import requestManager from './componentsManager/request-manager';
import eventBus from './componentsManager/event-bus';
import MWCApplicationHelper from 'mwc-application-helper';
import utils from './util';
import { ACCOUNT_KEY } from './common';
import $ from 'jquery';
import axios from 'axios';
import {
    initAppTracker,
    getAppTrackingPlugin
} from './componentsManager/tracking';
import mbg from 'mbg';
window.mbg = mbg;
import marketsMarketData from 'markets-market-data';
import VueCustomElement from 'vue-custom-element';
import 'mwc-markets-core';
window.jQuery = window.$ = $;
window.axios = axios;
window.marketsMarketData = marketsMarketData;
window.VueCustomElement = VueCustomElement;
import 'jquery-migrate';
import 'select2/dist/js/select2.full.min.js';
MWCApplicationHelper.initializeMwc();

utils.loadSVGIcons('lib/morningstar-design-system/dist/mds.svg');

Vue.config.productionTip = false;
Vue.mixin(mixins);

Vue.use(watchlistManger);
Vue.use(requestManager);
Vue.use(eventBus);

store.dispatch('application/getConfig').then(config => {
    // Init app tracker and inject it into vue instance.
    const tracker = initAppTracker(config);
    Vue.use(getAppTrackingPlugin(tracker));

    let app;

    router.beforeEach((to, from, next) => {
        if (to.path !== '/signin') {
            let account = window.sessionStorage.getItem(
                'markets-components-app-account'
            );
            if (account) {
                tracker.setIdentify({
                    userId: account.userId,
                    instId: account.instid
                });
                store.commit('account/setAccount', JSON.parse(account));
                next();
            } else {
                store.dispatch('account/checkSignin').then(status => {
                    status ? next() : next('/signin');
                });
            }
        } else {
            store.commit('application/setLoading', false);
            if (
                from.path === '/' &&
                window.sessionStorage.getItem(ACCOUNT_KEY)
            ) {
                app && app.$eventBus.$emit(app.$EVENTS.TOKEN_EXPIRED);
                store.dispatch('account/signout');
            } else {
                next();
            }
        }
    });

    app = new Vue({
        router,
        store,
        render: h => h(App)
    }).$mount('#app');
});
