import api from '../../data/api';
import utils, {
    extendAuthParams,
    reduce,
    guid,
    trim,
    removeEmptyContents,
    updateAliasComponentsName
} from '../../util';
import {
    generateName,
    COMPONENT_PROPERTIES,
    validateWatchlistsLength,
    WATCHLIST_TYPES,
    getEmptyWatchlistConfig
} from '../../common/';
const _state = {
    worksheets: [],
    editMode: false,
    availableComponents: [],
    lastActiveWorksheetId: undefined,
    goldenLayout: null,
    worksheetSecurity: null,
    activeComponents: [],
    activeWorksheetId: null,
    fromToViewStatus: false, //judge switch worksheet: goToView: true, click tab: false
    dragEventComponentStatus: false, // judge drag component from library
    activeDowjones: false // judge if dowjones active
};

const _getters = {
    isEmptyWorksheet(state, getters) {
        if (state.worksheets.length) {
            return getters.activeLayoutConfig.length === 0;
        }
        return false;
    },
    //judge worksheet is already render qualitative-research component
    hasResearchComponent(state, getters) {
        const activeWorksheetComponents = getters.activeWorksheetComponents;
        if (activeWorksheetComponents && activeWorksheetComponents.length) {
            return (
                activeWorksheetComponents.filter(ac =>
                    /qualitative-research+/.test(ac.componentName)
                ).length > 0
            );
        }
        return false;
    },
    activeWorksheetComponents(state) {
        if (state.worksheets.length > 0) {
            const activeWorksheet = findActiveWorksheet(state);
            if (activeWorksheet) {
                return getComponentsFromLayout(
                    activeWorksheet.profile.layoutConfig
                );
            } else {
                return [];
            }
        }
    },
    activeWorksheet(state) {
        return findActiveWorksheet(state);
    },
    activeWorksheetSecurity(state) {
        const activeWorksheet = findActiveWorksheet(state);
        if (!activeWorksheet) {
            return null;
        }
        return activeWorksheet.worksheetSecurity;
    },
    inactiveWorksheets(state) {
        return state.worksheets.filter(w => w.id !== state.activeWorksheetId);
    },
    activeLayoutConfig(state, getters, rootState) {
        const worksheet = findActiveWorksheet(state);
        const watchlists = rootState.watchlist.watchlists;
        if (worksheet) {
            const basicLayout = utils.extend(
                true,
                [],
                worksheet.profile.layoutConfig
            );
            const invalidWatchlist = [];
            mergeComponentConfig(
                basicLayout,
                state.availableComponents,
                watchlists,
                invalidWatchlist,
                worksheet.profile.showCloseIcon
            );
            if (invalidWatchlist.length) {
                _deleteWatchlistFromWorksheet(basicLayout, invalidWatchlist);
                _deleteWatchlistFromWorksheet(
                    worksheet.profile.layoutConfig,
                    invalidWatchlist
                );
            }
            return basicLayout;
        }
        return [];
    },
    availableGroupComponents(state, getters, rootState) {
        // TODO: abstract a function to other component use
        if (!getters.activeWorksheet) {
            return [];
        }
        const hasResearchComponent = getters.hasResearchComponent;
        const watchlists = rootState.watchlist.watchlists;
        const valid = !validateWatchlistsLength(watchlists);
        let groups = reduce(
            state.availableComponents,
            (groupsArr, component) => {
                const group = component.groupName || 'Uncategorized';
                const found = utils.find(groupsArr, c => c.group === group);
                component.disableComponentDrag = false;
                if (hasResearchComponent && component.disabledDrag) {
                    component.disableComponentDrag = true;
                }
                if (
                    component.componentName === 'mwc-markets-watchlist' &&
                    !valid
                ) {
                    component.disableComponentDrag = true;
                }
                if (found) {
                    found.components.push(component);
                } else {
                    groupsArr.push({
                        group,
                        components: [component]
                    });
                }
                return groupsArr;
            },
            []
        );
        groups.sort((a, b) => {
            return a.group < b.group ? -1 : 1;
        });
        return groups;
    },
    activeComponents(state) {
        const worksheet = findActiveWorksheet(state);
        if (worksheet) {
            return getWorksheetComponents(worksheet);
        }
        return [];
    },
    findRelatedWatchlists() {
        return _findRelatedWatchlists;
    },
    componentDragable(state) {
        const worksheet = findActiveWorksheet(state);
        if (worksheet) {
            return worksheet.profile.componentDragable === false ? false : true;
        }
        return true;
    },
    activeEditMode(state, getters) {
        return state.editMode && getters.componentDragable;
    }
};

function getWorksheetComponents(worksheet) {
    if (
        worksheet &&
        worksheet.profile.layoutConfig &&
        worksheet.profile.layoutConfig.length
    ) {
        return getComponentsFromLayout(worksheet.profile.layoutConfig);
    }
    return [];
}

function mergeComponentConfig(
    content = [],
    components = [],
    watchlists = [],
    invalidWatchlist = [],
    showCloseIcon = true
) {
    content.forEach((item, index) => {
        if (item.type === 'component') {
            updateAliasComponentsName(item);
            const component = utils.find(
                components,
                c =>
                    c.componentName === item.componentName ||
                    c.aliasComponentName === item.componentName
            );
            // fix commodity save DB settings, use default settings
            if (component && component.useDefaultSettings) {
                item.componentSettings = {};
            }
            const componentState = utils.extend(
                true,
                {},
                item.componentState,
                item.componentSettings
            );
            item.componentState = componentState;
            if (component) {
                COMPONENT_PROPERTIES.forEach(key => {
                    if (component.hasOwnProperty(key)) {
                        item[key] = component[key];
                    } else {
                        item[key] = null;
                        delete item[key];
                    }
                });
                if (!showCloseIcon) {
                    item.isClosable = false;
                }
            }

            if (item.watchlistType === 'custom') {
                const valid = utils.find(watchlists, w => {
                    return (
                        w.id === item.componentState.holdingsId ||
                        w.originalId === item.componentState.holdingsId
                    );
                });
                if (!valid) {
                    invalidWatchlist.push(item.componentState.holdingsId);
                }
            }
            content[index] = item;
        } else if (item.type === 'stack') {
            if (item.activeItemIndex > item.content.length - 1) {
                item.activeItemIndex = item.content.length - 1;
            }
            content[index] = item;
        }
        mergeComponentConfig(
            item.content,
            components,
            watchlists,
            invalidWatchlist,
            showCloseIcon
        );
    });
}

const actions = {
    async listWorksheets({ commit, rootState }, id) {
        const workbooks = rootState.workbook.workbooks;
        const workbook = utils.find(workbooks, item => {
            if (item.id === id) {
                return item.worksheets;
            }
        });
        const worksheets = workbook.worksheets;
        let activeWorksheetId = workbook.activeWorksheetId;
        commit('setWorksheets', worksheets);
        commit('setActiveWorksheetId', activeWorksheetId);
    },
    async deleteWorksheet({ commit, state, rootGetters }, id) {
        const worksheets = state.worksheets;
        let index = utils.findIndex(worksheets, w => {
            return w.id === id || (w.originalId && w.originalId === id);
        });
        id = worksheets[index].id;
        const deleteFn = () => {
            const isActive = worksheets[index].id === state.activeWorksheetId;
            worksheets.splice(index, 1);
            if (isActive) {
                if (index >= worksheets.length) {
                    index = worksheets.length - 1;
                }
                const activeWorksheetId = worksheets[index]
                    ? worksheets[index].id
                    : null;
                commit('setActiveWorksheetId', activeWorksheetId);
            }
            commit('setWorksheets', worksheets);
        };
        if (index >= 0) {
            if (/^[0-9]+$/.test(id)) {
                const token = rootGetters['account/token'];
                const url = `${rootGetters['application/url'](
                    '@web/ra/worksheet/delete'
                )}`;
                return api
                    .deleteWorksheet(url, extendAuthParams({ ids: id }, token))
                    .then(() => {
                        deleteFn();
                    });
            } else {
                deleteFn();
            }
        }
    },
    async getTemplateWorksheets({ rootState }) {
        return api
            .getTemplateWorksheets(rootState.account.instid)
            .then(data => {
                return data;
            });
    },
    async listAvailableComponents({ commit, rootState }) {
        const instid = rootState.account.instid;
        const promiseList = [
            api.listAvailableComponents(instid),
            rootState.watchlist.getWatchlistPromise
        ];
        return Promise.all(promiseList).then(res => {
            const [components, initialList] = res;
            const availableComponents = components.map(com => {
                if (
                    com.watchlistType === 'static' ||
                    com.watchlistType === 'readonly'
                ) {
                    const targetItem = utils.find(initialList, item => {
                        return item.id === com.componentName;
                    });
                    if (targetItem) {
                        const symbolArray = targetItem.holdings.map(
                            item => item.id
                        );
                        const symbol =
                            symbolArray.length > 0 ? symbolArray.join(',') : '';
                        com.componentState.symbol = symbol;
                        com.componentState.customRows = targetItem.holdings.map(
                            item => {
                                const map = {};
                                Object.keys(item).forEach(key => {
                                    if (item[key]) {
                                        map[key] = item[key];
                                    }
                                });
                                return map;
                            }
                        );
                    }
                }

                return utils.extend(true, {}, com);
            });
            commit('setAvailableComponents', availableComponents);
        });
    },
    updateWorksheet({ commit, state }, worksheet) {
        const worksheets = state.worksheets;
        const index = utils.findIndex(worksheets, w => {
            return w.id === worksheet.id;
        });
        if (index >= 0) {
            utils.extend(true, worksheets[index], worksheet);
        } else {
            worksheets.push(worksheet);
        }
        commit('setWorksheets', worksheets);
        commit('setWorksheetDirty', {
            worksheetId: worksheet.id,
            status: true
        });
        commit('workbook/setDirty', true, { root: true });
    },
    storeComponentSettings(
        { commit, state, getters, rootState },
        { linkId, settings, worksheet }
    ) {
        worksheet = worksheet || getters.activeWorksheet;
        if (worksheet) {
            if (settings.holdingsId) {
                const watchlists = rootState.watchlist.watchlists;
                const target = utils.find(watchlists, w => {
                    return (
                        w.id === settings.holdingsId ||
                        w.originalId === settings.holdingsId
                    );
                });
                if (target && target.tickers !== settings.symbol) {
                    target.tickers = settings.symbol;
                    target.isDirty = true;
                    settings.holdingsId = target.id;
                    commit('watchlist/setWatchlists', watchlists, {
                        root: true
                    });
                }
            }
            const layoutConfig = utils.extend(
                true,
                [],
                worksheet.profile.layoutConfig
            );
            _storeComponentConfig(
                layoutConfig,
                linkId,
                settings,
                state.availableComponents
            );
            commit('setLayoutConfig', { layoutConfig });
        }
    }
};

function _storeComponentConfig(content, linkId, settings, components) {
    content.forEach(contentItem => {
        if (contentItem.type === 'component') {
            if (
                contentItem.componentState &&
                contentItem.componentState.linkId === linkId
            ) {
                contentItem.componentSettings = utils.extend(
                    true,
                    {},
                    settings
                );
                const com = utils.find(components, c => {
                    return c.componentName === contentItem.componentName;
                });
                // fix commodity not save DB settings
                if (com && com.useDefaultSettings) {
                    contentItem.componentSettings = {};
                }
                if (
                    contentItem.componentSettings.holdingsId ||
                    (com && com.watchlistType === WATCHLIST_TYPES.STATIC)
                ) {
                    // Don't need store symbol if there is holdingsId or a static watchlist
                    delete contentItem.componentSettings.symbol;
                }
            }
        } else {
            _storeComponentConfig(
                contentItem.content,
                linkId,
                settings,
                components
            );
        }
    });
}

const mutations = {
    setDowJonesActive() {
        this.commit('worksheet/setActiveWorksheetId', '-1');
    },
    setWorksheets(state, worksheets) {
        worksheets.forEach(ws => {
            ws.name = trim(ws.name);
        });
        this.commit('workbook/setWorkbookWorksheets', worksheets);
        state.worksheets = worksheets;
    },
    setActiveWorksheetSecurity(state, security) {
        const worksheets = state.worksheets;
        const worksheet = findActiveWorksheet(state);
        worksheet.worksheetSecurity = security;
        state.worksheets = [];
        state.worksheets = worksheets;
    },
    toggleEditMode(state) {
        // When we want to edit we must minimize item.
        if (state.goldenLayout._maximisedItem) {
            state.goldenLayout._maximisedItem.toggleMaximise();
        }
        state.editMode = !state.editMode;
    },
    setGoldenLayout(state, goldenLayout) {
        state.goldenLayout = goldenLayout;
    },
    setAvailableComponents(state, availableComponents) {
        availableComponents.sort((a, b) => {
            return a.title < b.title ? -1 : 1;
        });
        state.availableComponents = availableComponents;
    },
    setWorksheetDirty(state, { worksheetId, status }) {
        worksheetId = worksheetId.split(',');
        worksheetId.forEach(id => {
            const idx = utils.findIndex(state.worksheets, ws => {
                return ws.id === id;
            });
            if (idx > -1) {
                state.worksheets[idx].isDirty = !!status;
            }
        });
        this.commit('workbook/setDirty', true);
    },
    /**
     *
     * @param {*} state
     * @param {Object} param1.resetComponentSettings sometimes only need change layout(callback from golden layout, if also reset component settings in this case will cause issue)
     */
    setLayoutConfig(state, { layoutConfig, resetComponentSettings = true }) {
        if (state.worksheets.length) {
            const worksheet = findActiveWorksheet(state);
            if (worksheet) {
                const originalComponents = getComponentsFromLayout(
                    worksheet.profile.layoutConfig
                );
                _setLayoutConfig(undefined, {
                    content: layoutConfig,
                    originalComponents,
                    resetComponentSettings,
                    showCloseIcon: worksheet.profile.showCloseIcon
                });
                const components = getComponentsFromLayout(layoutConfig);
                state.activeComponents = components;
                worksheet.profile.layoutConfig = layoutConfig;
            }
        }
        this.commit('workbook/setDirty', status);
        this.commit('worksheet/setWorksheetDirty', {
            worksheetId: state.activeWorksheetId,
            status: true
        });
    },
    setActiveWorksheetId(state, worksheetId) {
        if (worksheetId === state.activeWorksheetId) {
            return;
        }
        if (state.activeWorksheetId) {
            this.commit('workbook/setDirty', true); // only setDirty when change the activeWorksheetId( Not include: set it when initialize
        }

        state.lastActiveWorksheetId = state.activeWorksheetId;
        state.activeWorksheetId = worksheetId;
        // -1 means to active dowjones
        state.activeDowjones = worksheetId === '-1';
    },
    setWorksheetSecurity(state, security) {
        state.worksheetSecurity = security;
    },
    setFromToViewStatus(state, status) {
        state.fromToViewStatus = status;
    },
    setDragEventComponentStatus(state, status) {
        state.dragEventComponentStatus = status;
    },
    setPredefinedWorksheets(state, worksheets) {
        state.predefinedWorksheets = worksheets;
    },
    changeWatchlistsIds(state, watchlists) {
        const worksheets = state.worksheets;
        const resetWatchlistIds = (worksheet, watchlist, content = []) => {
            content.forEach((item, index) => {
                if (item.type === 'component') {
                    if (
                        item.componentSettings &&
                        item.componentSettings.holdingsId ===
                            watchlist.originalId &&
                        item.componentSettings.holdingsId !== watchlist.id
                    ) {
                        worksheet.isDirty = true;
                        item.componentSettings.holdingsId = watchlist.id;
                        content[index] = item;
                    }
                } else {
                    resetWatchlistIds(worksheet, watchlist, item.content);
                }
            });
        };
        watchlists.forEach(w => {
            worksheets.forEach(ws => {
                resetWatchlistIds(ws, w, ws.profile.layoutConfig);
            });
        });
    },
    openWatchlists(state, watchlistInfoArray) {
        const worksheets = state.worksheets;
        const array = worksheets.filter(ws => {
            return ws.id !== state.activeWorksheetId;
        });
        const results = _findRelatedWatchlists(array, watchlistInfoArray);
        if (!utils.isEmptyObject(results)) {
            const resultObj = results.targetWatchlist
                ? results.targetWatchlist
                : results.firstWatchlist;
            const { needUpdates, worksheetId, linkId } = resultObj;
            const worksheet = utils.find(state.worksheets, ws => {
                return ws.id === worksheetId;
            });
            const layoutConfig = worksheet.profile.layoutConfig;
            const action = results.targetWatchlist ? 'update' : 'add';

            _changeLayoutConfig(
                { content: layoutConfig },
                { linkId, needUpdates, action }
            );
            worksheet.isDirty = true;
            this.commit('worksheet/setActiveWorksheetId', worksheet.id);
        } else {
            // if NO watchlists in all worksheets, need to create a new worksheet and open the watchlist in it
            const _worksheets = createNewWorksheetWithWatchlist(
                worksheets,
                watchlistInfoArray
            );
            this.commit('worksheet/setWorksheets', _worksheets);
            this.commit(
                'worksheet/setActiveWorksheetId',
                _worksheets[_worksheets.length - 1].id
            );
        }
    },

    deleteWatchlistsFromWorkbook(state, watchlistIds) {
        const _worksheets = utils.extend(true, [], state.worksheets);
        _worksheets.forEach(worksheet => {
            if (worksheet.id === state.activeWorksheetId) {
                return;
            }
            const layoutConfig = worksheet.profile.layoutConfig;
            _deleteWatchlistFromWorksheet(
                layoutConfig,
                watchlistIds,
                worksheet
            );
        });
        this.commit('worksheet/setWorksheets', _worksheets);
    },
    setWorksheetViewSecurity(state, security) {
        const activeWorksheet = findActiveWorksheet(state);
        const layoutConfig = activeWorksheet.profile.layoutConfig;
        const setSymbol = content => {
            content.forEach((item, index) => {
                if (item.type === 'component') {
                    const _com = utils.find(
                        state.availableComponents,
                        com => com.componentName === item.componentName
                    );
                    if (_com.showSymbol) {
                        if (/^markets-components.+/.test(_com.componentName)) {
                            item.componentSettings.mainTicker = security;
                        } else {
                            item.componentSettings.symbol = security;
                        }
                        content[index] = item;
                    }
                } else if (item.type !== 'component') {
                    setSymbol(item.content);
                }
            });
        };
        setSymbol(layoutConfig);
        this.commit('worksheet/setLayoutConfig', { layoutConfig });
    }
};

function _deleteWatchlistFromWorksheet(layoutConfig, watchlistIds, worksheet) {
    _deleteWatchlistFromLayout(layoutConfig, watchlistIds, worksheet);
    removeEmptyContents(layoutConfig, layoutConfig);
}

function _deleteWatchlistFromLayout(
    content = [],
    watchlistIds = '',
    worksheet = {},
    parent = {}
) {
    for (let i = 0; i < content.length; i++) {
        const item = content[i];
        if (item.type === 'component' && item.watchlistType === 'custom') {
            const ac = utils.find(
                _state.availableComponents,
                c => c.componentName === item.componentName
            );
            const cfg = utils.extend(
                true,
                {},
                item.componentState,
                item.componentSettings,
                ac.componentState
            );
            const _delete = utils.find(watchlistIds, id => {
                return cfg.holdingsId === id;
            });
            if (_delete) {
                worksheet.isDirty = true;
                content.splice(i, 1);
                if (
                    parent.activeItemIndex === i ||
                    parent.activeItemIndex >= content.length
                ) {
                    parent.activeItemIndex = parent.activeItemIndex - 1;
                    if (parent.activeItemIndex < 0) {
                        parent.activeItemIndex = 0;
                    }
                }
                i--;
            }
        } else {
            _deleteWatchlistFromLayout(
                item.content,
                watchlistIds,
                worksheet,
                item
            );
        }
    }
}

function createNewWorksheetWithWatchlist(worksheets, watchlistInfoArray) {
    const content = [];
    const _worksheets = utils.extend(true, [], worksheets);
    _addWatchlistItemIntoContent(content, watchlistInfoArray);
    _worksheets.push({
        id: 'default-' + utils.guid(),
        name: generateName('Untitled', worksheets),
        profile: {
            isVisible: true,
            editable: true,
            layoutConfig: [
                {
                    content: content,
                    type: 'stack'
                }
            ]
        }
    });
    return _worksheets;
}
export function getComponentsFromLayout(layout) {
    const components = [];
    _getComponentsFromLayout(layout, components);
    return components;
}

function _getComponentsFromLayout(layout, activeComponents) {
    layout.forEach(item => {
        if (item.content) {
            _getComponentsFromLayout(item.content, activeComponents);
        } else if (item.type === 'component') {
            activeComponents.push(item);
        }
    });
}

function findActiveWorksheet(state) {
    const worksheet = utils.find(
        state.worksheets,
        w => w.id === state.activeWorksheetId
    );
    return worksheet;
}

function _setLayoutConfig(
    activeIndex,
    {
        content = [],
        originalComponents = [],
        resetComponentSettings = true,
        showCloseIcon = true
    }
) {
    // Only store the related with golden layout
    content.forEach((item, index) => {
        if (activeIndex === index && showCloseIcon) {
            item.showCloseIcon = true;
        } else {
            item.showCloseIcon = false;
        }
        removeAttrs(item, [
            'header',
            'reorderEnabled',
            'groupName',
            'deps',
            'htmlTitle'
        ]);
        if (item.type === 'component') {
            COMPONENT_PROPERTIES.forEach(key => {
                item[key] = null;
                delete item[key];
            });
            const state = {
                linkId: item.componentState.linkId
            };
            if (item.componentState.holdingsId) {
                state.holdingsId = item.componentState.holdingsId;
            }
            item.componentState = state;
            if (!resetComponentSettings && state.linkId) {
                const oriCom = utils.find(
                    originalComponents,
                    c =>
                        c.componentState &&
                        c.componentState.linkId === state.linkId
                );
                if (oriCom) {
                    item.componentSettings = oriCom.componentSettings;
                }
            }
            content[index] = item;
        }
        _setLayoutConfig(item.activeItemIndex, {
            content: item.content,
            originalComponents,
            resetComponentSettings,
            showCloseIcon
        });
    });
}

function removeAttrs(obj, attrs) {
    attrs.forEach(attr => {
        obj[attr] = null;
        delete obj[attr];
    });
    return obj;
}
/**
 *  To find the related watchlists in all worksheets
 * @param {Array} worksheets all worksheets
 * @param {Array} watchlistInfoArray the watchlists to open
 * @returns {Object} Return an object with the first watchlist in worksheets and the first target watchlist(has same holdings)
 */
function _findRelatedWatchlists(worksheets, watchlistInfoArray) {
    const res = {};
    for (let w = 0; w < worksheets.length; w++) {
        let components = getWorksheetComponents(worksheets[w]);
        components = components
            .filter(c => {
                return /.+watchlist.*/.test(c.componentName);
            })
            .map(com => {
                return updateAliasComponentsName(com);
            });
        let componentsConfig = [];
        for (let i = 0; i < components.length; i++) {
            const c = components[i];
            const ac = utils.find(
                _state.availableComponents,
                com => com.componentName === c.componentName
            );
            const cfg = utils.extend(
                true,
                {},
                c.componentState,
                c.componentSettings,
                ac.componentState
            );
            componentsConfig.push(cfg);
            if (
                (ac.watchlistType === WATCHLIST_TYPES.CUSTOM ||
                    ac.watchlistType === WATCHLIST_TYPES.READ_ONLY) &&
                !res.firstWatchlist
            ) {
                res.firstWatchlist = {
                    linkId: cfg.linkId,
                    worksheetId: worksheets[w].id,
                    componentsConfig
                };
            }
            if (cfg.holdingsId === watchlistInfoArray[0].id) {
                res.targetWatchlist = {
                    linkId: cfg.linkId,
                    worksheetId: worksheets[w].id,
                    componentsConfig
                };
            }
        }
        if (res.targetWatchlist) {
            break;
        }
    }
    ['targetWatchlist', 'firstWatchlist'].forEach(key => {
        if (!res[key]) {
            return;
        }
        watchlistInfoArray.forEach((w, index) => {
            const idx = utils.findIndex(res[key].componentsConfig, c => {
                return c.holdingsId === w.id;
            });
            if (idx < 0 || index === 0) {
                if (!res[key].needUpdates) {
                    res[key].needUpdates = [];
                }
                res[key].needUpdates.unshift(w);
            }
        });
    });
    return res;
}
/**
 * change layoutConfig when watchlist update
 * @param {Object} parent - layoutConfig.content
 * @param {Object} watchlist - object with watchlist information
 * @param watchlist.linkId - linkId of target watchlist
 * @param watchlist.needUpdates - the watchlists need to open
 * @param watchlist.action - 'add' or 'update'
 */
function _changeLayoutConfig(parent, { linkId, needUpdates, action }) {
    const content = parent.content || [];
    for (let i = 0; i < content.length; i++) {
        const item = content[i];
        if (
            item.type === 'component' &&
            item.componentState &&
            item.componentState.linkId === linkId
        ) {
            if (action === 'add') {
                _addWatchlistItemIntoContent(content, needUpdates);
                parent.activeItemIndex = 0;
            } else if (action === 'update') {
                // Update need to make the first watchlist to be active, and then add the rest watchlists into same stack
                const updateItem = needUpdates.pop();
                if (needUpdates.length > 0) {
                    _addWatchlistItemIntoContent(content, needUpdates);
                }
                item.componentState.holdingsId = updateItem.id;
                item.title = updateItem.name;
                content.splice(i, 1);
                content.unshift(item);
                parent.activeItemIndex = 0;
            }
            break;
        }
        _changeLayoutConfig(item, {
            linkId,
            needUpdates,
            action
        });
    }
}
function _addWatchlistItemIntoContent(content = [], watchlistInfoArray = []) {
    watchlistInfoArray.forEach(item => {
        const config = utils.extend(true, {}, getEmptyWatchlistConfig(), {
            title: item.name,
            componentState: {
                linkId: 'watchlist-' + guid()
            },
            componentSettings: {
                holdingsId: item.id
            }
        });
        content.unshift(config);
    });
}

export default {
    namespaced: true,
    state: _state,
    getters: _getters,
    actions,
    mutations
};
